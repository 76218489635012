import axios from "axios";
import { logout } from "../Utils/localStorage";

const STAGING_API = "https://staging.api.waithere.com.br";
const PROD_API = "https://api.waithere.com.br";

const STAGING_ACTION_CABLE_URL = "wss://staging.api.waithere.com.br/cable";
const PRODUCTION_ACTION_CABLE_URL = "wss://api.waithere.com.br/cable";

const currentUrl = window.location.href.toLowerCase();

const api_url = () => {
  if (currentUrl.includes("localhost") || currentUrl.includes("onrender")) {
    return process.env.REACT_APP_API_URL;
  } else if (currentUrl.includes("staging")) {
    return STAGING_API;
  } else {
    return PROD_API;
  }
};

export const api = axios.create({
  baseURL: api_url(),
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalConfig.retry) {
        console.log("Going to try again...");
        originalConfig._retry = true;

        // window.location.href = "/log_in";
        // return logout();
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const action_cable_url = () => {
  if (currentUrl.includes("localhost") || currentUrl.includes("onrender")) {
    return process.env.REACT_APP_ACTION_CABLE_URL;
  } else if (currentUrl.includes("staging")) {
    return STAGING_ACTION_CABLE_URL;
  } else {
    return PRODUCTION_ACTION_CABLE_URL;
  }
};

export const actionCableUrl = action_cable_url();
