/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { PrinterContext } from "../Context/printerContext";
import { useEffect } from "react";

const usePrinter = ({ getRooms }) => {
  const context = useContext(PrinterContext);
  const { handleGetPrinterRooms } = context;

  useEffect(() => {
    getRooms && handleGetPrinterRooms();
  }, [getRooms]);

  return context;
};

export default usePrinter;
