import styles from "../../Assets/styles";
import * as S from "./styles";

export const Input = ({ placeHolder, value, type, onChange, errors }) => {
  return (
    <div>
      <S.Holder>
        <input
          value={value}
          type={type}
          placeholder={placeHolder}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </S.Holder>
      <S.Error>{errors}</S.Error>
    </div>
  );
};

export const BorderedInput = ({
  placeHolder,
  value,
  type,
  onChange,
  errors,
}) => {
  return (
    <>
      <S.BorderHolder>
        <input
          value={value}
          type={type}
          placeholder={placeHolder}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </S.BorderHolder>
      <S.Error>{errors}</S.Error>
    </>
  );
};

export const IconInput = ({
  placeHolder,
  value,
  type,
  onChange,
  errors,
  leftIcon,
}) => {
  return (
    <S.IconInputHolder>
      {leftIcon}
      <input
        value={value}
        type={type}
        placeholder={placeHolder}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </S.IconInputHolder>
  );
};

export const CheckBox = ({ value, checked, variant, label }) => {
  const variants = { primary: { color: styles.MidGrey } };
  return (
    <S.CheckBox customStyles={variants[variant]}>
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={() => null}
      />
      <label htmlFor="checkbox"></label>
    </S.CheckBox>
  );
};
