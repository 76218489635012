import styled from "styled-components";
import styles from "../../../Assets/styles";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 80vh;
  max-height: 80vh;
`;

export const AutoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form_holder {
    width: 100%;
  }
`;

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  margin-top: -35px;
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${styles.DarkGrey};
  max-width: 300px;
  text-align: center;
  font-weight: 500;
`;

export const StartSubText = styled(SubText)`
  text-align: start;
  margin-bottom: 5px;
  margin-top: 10px;
  max-width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${styles.DarkGrey};
  border-radius: 10px;
  padding: 0px 10px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 0px 15px;
  width: ${(props) => props.fill && "100%"};
  height: 40px;
  box-sizing: content-box;
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 650;
  text-align: center;
  color: ${styles.OffBlack};
`;

export const TableDetailsHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SessionsHolder = styled.div`
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  .session_header {
    padding: 0;
    margin: 0;
    font-weight: 700;
  }
`;

export const SessionCard = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  min-height: 70px;
  border-radius: 10px;
  border: 1px solid black;
  overflow: hidden;
  /* justify-content: space-between; */
  padding-right: 20px;
  .avatar {
    width: 70px;
    height: 70px;
  }

  .section_1 {
    max-width: 40%;
    overflow: hidden;

    padding: 10px;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
  }

  .section_2 {
    margin-left: auto;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
    }

    .reject_svg {
      width: 30px;
      height: 30px;
      color: ${styles.Error};
    }

    .accept_svg {
      width: 20px;
      height: 20px;
      color: ${styles.acceptGreen};
    }
  }
`;

export const SliderHolder = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 2px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const SliderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 30px;
  position: relative;

  .line {
    background-color: black;
    position: absolute;
    bottom: 0;
    height: 4px;
    transition: 0.3s;
    border-radius: 20px;
  }
`;

export const SliderOption = styled.div`
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`;
