import { useParams } from "react-router-dom";
import { TopNav } from "../../Components/TopNav";
import { HomePreview } from "./home";
import * as S from "./styles";
import "waitherepackages/dist/index.css";
import useBranding from "../../Hooks/useBranding";
import { NavBarPreview } from "./navbar";
import { FooterPreview } from "./footer";
import { LoadDots } from "../../Components/Loader";
import { MenuPreview } from "./menu";
import { QuickAccessPreview } from "./quickaccess";

export const MyPages = () => {
  const { name } = useParams();
  const { loading, branding, updateBranding, editBranding } = useBranding();

  const handlePreviews = () => {
    switch (name) {
      case "home":
        const { menuBdrColor, menuItemColor, menuTxtColor } = branding?.menu;
        const currentBranding = {
          ...(branding?.homepage || {}),
          menuBdrColor,
          menuItemColor,
          menuTxtColor,
        };
        return (
          <HomePreview
            currentBranding={currentBranding}
            editBranding={editBranding}
            updateBranding={updateBranding}
          />
        );
      case "navbar":
        return (
          <NavBarPreview
            currentBranding={branding?.navbar}
            updateBranding={updateBranding}
          />
        );
      case "footer":
        return (
          <FooterPreview
            currentBranding={branding?.footer}
            updateBranding={updateBranding}
          />
        );
      case "menu":
        return (
          <MenuPreview
            currentBranding={branding?.menu}
            updateBranding={updateBranding}
          />
        );
      case "quick_access":
        return (
          <QuickAccessPreview
            currentBranding={branding?.quickaccesser}
            updateBranding={updateBranding}
          />
        );

      default:
        break;
    }
  };
  return (
    <>
      <TopNav />
      {loading.branding && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!loading.branding && <S.Holder>{handlePreviews()}</S.Holder>}
    </>
  );
};
