import { useEffect } from "react";
import { useState } from "react";
import { CheckBox } from "../../Components/Inputs";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import { ALLDAYSOFWEEK } from "../../Utils/GlobalVariables";
import * as S from "./styles";

export const CreateEditMenuModal = ({
  showModal,
  setShowModal,
  updateMenu,
  createMenu,
  isLoading,
  selectedCategory,
  selectedMenu,
}) => {
  const [name, setName] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    if (selectedMenu.id) {
      setName(selectedMenu?.name ?? "");
      setSelectedDays(selectedMenu?.days_of_week ?? []);
    }
  }, [selectedMenu]);

  const handleSubmit = async () => {
    const params = {
      menu_id: selectedMenu.id,
      name: name,
      days_of_week: selectedDays,
    };
    if (showModal.editMenu) {
      await updateMenu(params);
    } else {
      await createMenu({ ...params, menu_category_id: selectedCategory.id });
    }

    setShowModal({ createMenu: false });
    setSelectedDays([]);
  };
  return (
    <>
      <Modals
        size="sm"
        curved
        show={showModal.createMenu || showModal.editMenu}
        onHide={() => {
          setShowModal({
            ...showModal,
            createMenu: false,
            editMenu: false,
          });
        }}
      >
        {isLoading && (
          <S.ModalLoadHolder>
            <LoadDots />
          </S.ModalLoadHolder>
        )}
        <>
          <S.ModalHeader>
            {showModal.editMenu
              ? "Edit Menu"
              : "Create New Menu Under Name Category"}
          </S.ModalHeader>
          <S.StartSubText>Name</S.StartSubText>
          <S.Input
            defaultValue={showModal.editMenu && selectedMenu?.name}
            placeholder="Eg: Entrée, Main course *"
            onChange={(e) => setName(e.target.value)}
          ></S.Input>
          <br />
          <br />
          <S.StartSubText>Days Available</S.StartSubText>
          <S.DaysHolder>
            {ALLDAYSOFWEEK.map((day) => (
              <div
                key={day.value}
                className="check-box"
                onClick={() =>
                  setSelectedDays(
                    selectedDays.includes(day.value)
                      ? selectedDays.filter(
                          (selectedDay) => selectedDay !== day.value
                        )
                      : [...selectedDays, day.value]
                  )
                }
              >
                <CheckBox checked={selectedDays.includes(day.value)} />
                <p>{day.label}</p>
              </div>
            ))}
          </S.DaysHolder>

          <br />
          <S.StartSubText>Time Available</S.StartSubText>

          <br />
          <S.BlackButton onClick={handleSubmit}>
            {showModal.editMenu ? "Save" : "Create"}
          </S.BlackButton>
          <br />
        </>
      </Modals>
    </>
  );
};
