import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import styles from "../../Assets/styles";
import { RiErrorWarningLine } from "react-icons/ri";

export const Holder = styled.div`
  margin-top: 78px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  @media (max-width: ${1000}px) {
    padding: 50px 10px;
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamOverView = styled.div`
  width: 700px;
  height: 210px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #151a1e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;

  @media (max-width: ${1000}px) {
    width: 98%;
    height: fit-content;
  }
`;

export const AllMembers = styled.div`
  margin-top: 100px;
  width: 1000px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #151a1e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  align-self: center;
  color: white;
  box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  @media (max-width: ${1000}px) {
    width: 98%;
    height: fit-content;
  }
`;

export const AllMembersHolder = styled.div``;

export const AllMembersHolderScroll = styled.div`
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const FlexHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Rows = styled(Row)`
  box-sizing: border-box;
  height: 70px;
  align-items: center;
  :nth-child(odd) {
    background: #1c2125;
  }
  @media (max-width: ${1000}px) {
    padding: 15px;
    row-gap: 10px;
    height: fit-content;
  }
`;

export const NameHolder = styled(Col)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 20px;
  * {
    color: ${styles.NeutralsWhite};

    margin: 0;
  }
`;

export const Role = styled(Col)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  font-weight: 600;
  text-transform: capitalize;
  color: ${styles.NeutralsWhite};
  svg {
    margin-left: 5px;
    width: 20px;
  }
`;

export const UserImage = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${styles.LightGrey};
`;

export const OptionHolder = styled(Col)`
  position: relative !important;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
`;
export const StatusIcon = styled.div`
  padding-right: 10px;
  svg {
    width: 20px;
    height: 20px;
    color: ${(props) => (props.success ? "green" : "red")};
  }
`;

export const OptionButton = styled.div`
  cursor: pointer;
  width: 130px;
  min-width: 130px;
  padding: 0 !important;
  background-color: ${styles.DarkGrey};
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${styles.NeutralsWhite};

  svg {
    margin-left: 5px;
    width: 22px;
    height: 22px;
  }
`;

export const Collapser = styled.div`
  position: absolute;
  background-color: #2a2e32;
  width: 200px;
  border-radius: 5px;
  top: 45px;
  z-index: 99;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);

  div {
    color: ${styles.NeutralsWhite};
    transition: 0.2s;
    padding: 0px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid ${styles.DarkGrey};
    text-transform: capitalize;
    :hover {
      background-color: ${styles.DarkGrey};
    }
    :last-child {
      border: none;
      :hover {
        background-color: ${(props) => props.hasDelete && styles.Error};
      }
    }
  }
`;

export const BoldWhiteText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: ${styles.NeutralsWhite};
`;

export const BoldBlackText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: ${styles.OffBlack};
`;
export const SubText = styled.p`
  margin: 0;
  font-size: 15px;
  color: ${styles.DarkGrey};
  max-width: 700px;
`;

export const StepsHolder = styled.div`
  min-height: 100%;
  min-width: 100%;
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "space-between")};
`;

export const Steps = styled.div`
  top: 0;
  right: 0;
`;

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.NeutralsWhite};
  margin-top: -35px;
`;

export const ModalLoadHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 40px);
  border-radius: 10px;
  margin-top: -40px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const InputHolder = styled.div`
  gap: 2rem;
  width: 350px;
  height: 38px;
  display: flex;
  color: white;
  flex-direction: column;
  @media (max-width: ${1000}px) {
    width: 99%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const TextContent = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${styles.NeutralsWhite};
  text-align: ${(props) => props.center && "center"};
  span {
    text-transform: uppercase;
  }
`;

export const CenteredTextContent = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${styles.NeutralsWhite};
`;

export const WarningIcon = styled(RiErrorWarningLine)`
  color: ${styles.Error};
  align-self: center;
  width: 50px;
  height: 50px;
`;
