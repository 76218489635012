/* eslint-disable react-hooks/exhaustive-deps */
import { notables, waithereLogo } from "../../../Assets/images/images";
import { LoadDots } from "../../../Components/Loader";
import Modals from "../../../Components/Modals";
import * as S from "./styles";
import { MdPayments } from "react-icons/md";
import { FaCartPlus, FaHistory } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { Fade } from "react-bootstrap";
import QRCode from "react-qr-code";

export const TableDetailsModal = ({
  show,
  onhide,
  handleShowMenu,
  table = {},
  loading = {},
  handleModals,
  handleHideTable,
  handleUpdateSession,
}) => {
  const { status, table_number } = table;
  const options = [
    { label: "Sessões", key: 1 },
    { label: "Informação", key: 2 },
  ];
  const [selected, setSelected] = useState(options[0]);

  return (
    <Modals size="md" backdrop="normal" curved show={show} onHide={onhide}>
      <S.ModalHeader>Mesa {table_number}</S.ModalHeader>

      <S.ModalContainer>
        {loading.getTable && <LoadDots />}
        {!loading.getTable && (
          <>
            <OptionSlider
              options={options}
              selected={selected}
              setSelected={setSelected}
            />

            {selected.key === 1 && (
              <Fade unmountOnExit in={selected.key === 1}>
                <>
                  {status === "AVAILABLE" && (
                    <EmptyTable
                      handleModals={handleModals}
                      handleHideTable={handleHideTable}
                    />
                  )}

                  {status !== "AVAILABLE" && (
                    <TableDetails
                      table={table}
                      handleShowMenu={handleShowMenu}
                      handleModals={handleModals}
                      handleHideTable={handleHideTable}
                      handleUpdateSession={handleUpdateSession}
                    />
                  )}
                </>
              </Fade>
            )}

            {selected.key === 2 && (
              <Fade unmountOnExit in={selected.key === 2}>
                <QRCode
                  value={`https://staging.waithere.com.br/obs?table=${table.table_number}`}
                />
              </Fade>
            )}
          </>
        )}
      </S.ModalContainer>
    </Modals>
  );
};

const EmptyTable = ({ handleModals, handleHideTable }) => {
  return (
    <>
      <S.BannerImage src={notables} />
      <br />
      <S.BoldText>Esta mesa não tem nenhuma sessão!</S.BoldText>
      <br />
      <S.BlackButton
        onClick={() => {
          handleHideTable();
          handleModals("startSession", true);
        }}
      >
        Inicie a sessão
      </S.BlackButton>
    </>
  );
};

const TableDetails = ({
  table = {},
  handleModals,
  handleHideTable,
  handleShowMenu,
  handleUpdateSession,
}) => {
  const { active_sessions = [], pending_sessions = [] } = table;

  return (
    <S.TableDetailsHolder>
      <S.SessionsHolder>
        {pending_sessions.length > 0 && (
          <>
            <p className="session_header">Sessões Pendentes</p>
            {pending_sessions.map((session) => (
              <S.SessionCard key={session.id}>
                <img className="avatar" src={waithereLogo} alt="waithere" />
                <div className="section_1">
                  <p>{session.created_for}</p>
                  <p>R$ {session.cart?.total ?? 0.0}</p>
                </div>

                <div className="section_2">
                  <BsCheckLg
                    className="accept_svg"
                    onClick={() =>
                      handleUpdateSession({
                        token: session.token,
                        status: "active",
                      })
                    }
                  />
                  <IoClose
                    className="reject_svg"
                    onClick={() =>
                      handleUpdateSession({
                        token: session.token,
                        status: "cancelled",
                      })
                    }
                  />
                </div>
              </S.SessionCard>
            ))}
            <br />
          </>
        )}

        {active_sessions.length > 0 && (
          <>
            <p className="session_header">Sessões Ativas</p>
            {active_sessions.map((session) => (
              <S.SessionCard key={session.id}>
                <img className="avatar" src={waithereLogo} alt="waithere" />
                <div className="section_1">
                  <p>{session.created_for}</p>
                  <p>R$ {session?.total ?? 0.0}</p>
                </div>

                <div className="section_2">
                  <FaCartPlus onClick={() => handleShowMenu(session)} />
                  <FaHistory />
                  <MdPayments />
                </div>
              </S.SessionCard>
            ))}
          </>
        )}
      </S.SessionsHolder>
      <S.BlackButton
        onClick={() => {
          handleHideTable();
          handleModals("startSession", true);
        }}
      >
        Inicie a novo sessão
      </S.BlackButton>
    </S.TableDetailsHolder>
  );
};

const OptionSlider = ({ options, selected, setSelected }) => {
  const lineRef = useRef(null);
  const sliderContainerRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelected(option);
  };

  const onOptionChange = () => {
    const selectedOption = sliderContainerRef.current?.querySelector(
      `[data-key="${selected.key}"]`
    );
    if (selectedOption) {
      const { offsetWidth, offsetLeft } = selectedOption;
      lineRef.current.style.width = `${offsetWidth}px`;
      lineRef.current.style.left = `${offsetLeft}px`;
    }
  };

  useEffect(() => {
    onOptionChange();
  }, [selected]);

  return (
    <S.SliderHolder>
      <S.SliderContainer ref={sliderContainerRef}>
        {options.map((option) => (
          <S.SliderOption
            data-key={option.key}
            onClick={() => handleOptionClick(option)}
            key={option.key}
            className={selected === option && "selected"}
          >
            {option.label}
          </S.SliderOption>
        ))}
        {selected.label && <div className="line" ref={lineRef} />}
      </S.SliderContainer>
    </S.SliderHolder>
  );
};
