/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { actionCableUrl } from "../Api";
import { createConsumer } from "@rails/actioncable";
import { getUser } from "../Utils/localStorage";

const consumer = createConsumer(
  `${actionCableUrl}/${getUser()?.token?.split(" ")?.[1]}`
);

const useActionCable = (params = {}, onReceived) => {
  useEffect(() => {
    if (params.slug) {
      consumer.subscriptions.create(params, {
        connected: () => console.log("connected"),
        disconnected: () => console.log("disconnected"),
        received: onReceived,
      });
    }
  }, [params, onReceived]);

  const sendMessageToSocket = (message) => {
    const payload = {
      command: "message",
      identifier: JSON.stringify(params),
      data: JSON.stringify(message),
    };

    consumer.send(payload);
  };

  return { sendMessageToSocket };
};

export default useActionCable;
