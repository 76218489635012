import { RoundButton } from "../Buttons";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { ProgressIndicator } from "../ProgressIndicator";
import { TbTrash } from "react-icons/tb";

export const Slider = ({ data, defaultIndex, showDelete, handleDelete }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    defaultIndex && setCurrentIndex(defaultIndex);
  }, [defaultIndex]);

  const handleNav = (param) => {
    setCurrentIndex(currentIndex + param);
  };
  return (
    <S.Holder image={data[currentIndex]?.image}>
      {showDelete && (
        <TbTrash
          className="delete_icon"
          onClick={() => {
            handleDelete(data[currentIndex]);
          }}
        />
      )}
      <S.NavsHolder>
        <RoundButton
          onClick={() => currentIndex !== 0 && handleNav(-1)}
          variant={"leftRound"}
          icon={<IoArrowBackOutline />}
        />
        <RoundButton
          onClick={() => currentIndex + 1 < data?.length && handleNav(1)}
          variant={"rightRound"}
          icon={<IoArrowForwardOutline />}
        />
      </S.NavsHolder>
      <S.IndicatorHolder>
        <ProgressIndicator quantity={data.length} current={currentIndex + 1} />
      </S.IndicatorHolder>
    </S.Holder>
  );
};
