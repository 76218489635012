import styled from "styled-components";
import styles from "../../../Assets/styles";

export const Holder = styled.div`
  height: 80vh;
  max-height: 80vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .menu_container {
    max-width: 100% !important;
    min-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;

    .menu_controller {
      padding: 0 !important;
    }

    .menu-holder {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .items_holder {
      justify-content: center;
    }
  }
  position: relative;
  .access_container {
    max-height: 0px;
    max-width: 70px !important;
    overflow: hidden;
    .quick_acess_holder {
      visibility: hidden;
      position: fixed;
      left: calc(50% - 92px);

      .modal_content {
        background-color: black !important;
      }
    }
  }
`;

export const CartButtonHolder = styled.div`
  cursor: pointer;
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .quantity {
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
    font-size: 11px;
    color: white;
    z-index: 1;
  }

  svg {
    position: absolute;
    width: 25px;
    height: 25px;
  }
`;

export const CartItemHolder = styled.div`
  gap: 20px;
  display: flex;
  height: calc(100% - 50px);
  overflow: scroll;
  flex-direction: column;
  .cart_item_card {
    height: 100px;
    border-radius: 10px;
    border: 1px solid black !important;
    padding: 0 !important;
    overflow: hidden;
    gap: 10px;
    .item_image {
      height: 100%;
      width: 100px;
      min-width: 100px;
      border-radius: 0;
    }

    .cart_details {
      padding: 5px 0px;
      width: 100%;
      max-width: 100%;
      padding-right: 5px;
    }
  }
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 0px 15px;
  width: ${(props) => props.fill && "100%"};
  height: 40px;
  box-sizing: content-box;
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
  p {
    margin: 0;
    padding: 0;
  }
`;
