import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  margin-top: 70px;
  box-sizing: border-box;
  padding: 0px 100px;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${styles.NeutralsWhite};

  @media (max-width: ${1000}px) {
    padding: 0px 30px;
    align-items: center;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PromosHolder = styled.div`
  gap: 15px;
  width: 100vw;
  display: flex;
  overflow-x: scroll;
  padding-right: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${1000}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CategoryName = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: ${styles.OffBlack};
  margin: 0;
  margin-top: 25px;
  align-self: flex-start;
  text-transform: capitalize;
`;

export const ImageHolder = styled.div`
  position: relative;
  margin-top: 10px;
  transition: 0.2s;
  width: 650px;
  min-width: 650px;
  min-height: 300px;
  background-color: ${styles.background};
  border-radius: 20px;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  p {
    font-weight: 700;
    font-size: 25px;
    position: absolute;
    top: 65px;
    left: 20px;
    color: ${styles.NeutralsWhite};
  }
  span {
    /* font-weight: 700; */
    font-size: 15px;
    position: absolute;
    top: 30px;
    left: 20px;
    color: ${styles.NeutralsWhite};
  }

  @media (max-width: ${1000}px) {
    width: 350px;
    min-width: 350px;
    min-height: 170px;
    max-width: 90vw;
  }
`;

export const ClaimVoucher = styled.div`
  cursor: pointer;
  width: 100px;
  min-height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${styles.NeutralsWhite};
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

export const SearchBarHolder = styled.div`
  height: 50px;
  width: 30vw;
  margin-top: 30px;
  border-radius: 50px;
  padding: 0px 5px;
  border: 1px solid ${styles.DarkGrey};
  background-color: ${styles.NeutralsWhite};
  @media (max-width: ${1000}px) {
    height: 50px;
    min-height: 50px;
    width: 100%;
  }
`;

export const OrgHolder = styled.div`
  margin-top: 30px;
  max-width: 100%;
  min-width: 100%;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
`;

export const Organizations = styled.div`
  height: 120px;
  min-height: 120px;
  width: 300px;
  min-width: 300px;
  border-radius: 12px;
  padding: 0px 20px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f8f8f8;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    transition: 0.3s;
    box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  }

  @media (max-width: ${1000}px) {
    box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  }

  img {
    width: 90px;
    height: 90px;
    border-radius: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 10px;
    gap: 15px;
  }
  p {
    margin: 0;
    font-size: 17px;
  }
  span {
    color: ${styles.DarkGrey};
  }
  @media (max-width: ${1000}px) {
    width: 100%;
    max-width: 350px;
  }
`;
