import { TopNav } from "../../Components/TopNav";
import { DashBoardHome } from "./Home";
import * as S from "./styles";

export const Home = () => {
  return (
    <>
      <TopNav />
      <S.Holder>{<DashBoardHome />}</S.Holder>
    </>
  );
};
