import { useState } from "react";
import { getUser } from "../Utils/localStorage";
import { useCallback } from "react";
import { updateUserAvatar } from "../Api/UserApi";

const useProfile = () => {
  // investigate if this is still needed

  const [avatar, setAvatar] = useState({
    current: getUser()?.avatar,
    new: null,
  });

  const updateAvatar = useCallback(async () => {
    await updateUserAvatar(avatar?.new);
    // setAvatar({ ...avatar, current: updatedAvatar });
  }, [avatar]);

  return { avatar, setAvatar, updateAvatar };
};

export default useProfile;
