import styled from "styled-components";
import styles from "../../Assets/styles";

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  /* text-align: center; */
  margin-top: -35px;
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
`;

export const RoomsContainer = styled.div`
  min-height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RoomsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  p {
    margin: 0;
    font-size: 16px;
    color: ${styles.DarkGrey};
    max-width: 300px;
    text-align: center;
    font-weight: 500;
  }
  svg {
    width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    color: ${styles.NeutralsWhite};
    background-color: ${styles.OffBlack};
  }
`;

export const CollapseHolder = styled.div`
  border: 2px solid ${styles.MidGrey};
  border-top: 0;
  padding: 10px 10px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 10px;
`;

export const FlexHolder = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  .category_name {
    width: 250px;
  }
`;

export const ButtonHolder = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: ${(props) =>
    props.center ? "center" : props.end ? "end" : "space-between"};
  align-items: center;
  margin-top: auto;
  .small-btn {
    padding: 2px 15px;
  }
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 10px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  width: ${(props) => props.fill && "100%"};
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const GreyButton = styled(BlackButton)`
  background-color: ${styles.LightGrey};
  color: ${styles.OffBlack};

  :hover {
    color: ${styles.MidGrey};
  }
`;

export const OptionsHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-height: 210px;
  overflow-x: scroll;
  margin-top: 10px;
`;

export const Room = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: ${({ small }) => (small ? "40px" : "50px")};
  border-radius: 10px;
  border: 1px solid ${styles.DarkGrey};
  .actions-holder {
    display: flex;
    gap: 25px;
    svg {
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    padding: 0;
    width: 40%;
    max-width: 40%;
    overflow: hidden;
  }

  .option_name {
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Input = styled.input`
  width: 99%;
  height: 40px;
  border-radius: 10px;
  border: ${`1px solid ${styles.IconGrey}`};
  padding-left: 10px;
  /* margin-bottom: 15px; */

  ::placeholder {
    color: ${styles.FocusedGrey};
  }
  :focus {
    outline: none;
  }
`;

export const ButtonsHolder = styled.div`
  margin-top: 20px;
  margin-left: -30px;
  margin-right: -30px;
  border-top: ${`1px solid  ${styles.IconGrey}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
`;

export const InputHolder = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  color: ${styles.FocusedGrey};
  span {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const Error = styled.div`
  font-weight: 500;
  color: ${styles.Error};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
