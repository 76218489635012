import { createContext, useState } from "react";
import { useMutation } from "react-query";
import { createTables, indexTables, showTable } from "../Api/TablesApi";
import { getUserOrg } from "../Utils/localStorage";
import useActionCable from "../Hooks/useActionCable";
import { useContext } from "react";
import { controllerContext } from "./controllerCtx";
import { startSession, updateSession } from "../Api/OrgSessions";
import { useMemo } from "react";

export const TableContext = createContext();

const TableStore = ({ children }) => {
  const [controllerState, setControllerState] = useContext(controllerContext);

  const [tables, setTables] = useState([]);
  const [table, setTable] = useState({});

  const [tableCart, setTableCart] = useState({});
  const [tableModals, setTableModals] = useState({});

  const [filters, setFilters] = useState({
    search: "",
    selected: [],
  });

  const [navigate, setNavigate] = useState();

  const filteredTables = useMemo(() => {
    var filteredScope = tables;

    filteredScope =
      filters.selected.length > 0
        ? tables.filter((table) => filters.selected.includes(table.status))
        : tables;

    filteredScope = filters.capacity
      ? filteredScope.filter((table) =>
          `${table.number_of_seats}`.includes(filters.capacity)
        )
      : filteredScope;

    filteredScope = filters.search
      ? filteredScope.filter((table) =>
          `${table.table_number}`.includes(filters.search)
        )
      : filteredScope;

    return filteredScope;
  }, [tables, filters]);

  const handleInputFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleFilters = (key) => {
    setFilters({
      ...filters,
      selected:
        key === "clear"
          ? []
          : filters.selected.includes(key)
          ? filters.selected.filter((str) => key !== str)
          : [...filters.selected, key],
    });
  };

  const slug = () => {
    const {
      organization: { slug },
    } = getUserOrg();

    return slug;
  };

  const handleModals = (key, value) => {
    setTableModals({ ...tableModals, [key]: value });
  };

  const handleGetTablesSuccess = (res) => {
    const { data } = res;
    setTables(data);
  };

  const getTablesMutation = useMutation({
    mutationFn: indexTables,
    onSuccess: handleGetTablesSuccess,
    onError: (error) => console.log(error),
  });

  const getAllTables = () => {
    getTablesMutation.mutate(slug());
  };

  const handleGetTableSuccess = (res) => {
    const { data } = res;
    setTable(data);
  };

  const getTableMutation = useMutation({
    mutationFn: showTable,
    onSuccess: handleGetTableSuccess,
    onError: (error) => console.log(error),
  });

  const getTable = (table_no) => {
    const params = { slug: slug(), table_no };
    getTableMutation.mutate(params);
  };

  const handleBulkCreateSuccess = (res) => {
    const { data } = res;
    setTables(data);
    handleModals("createTables", false);
  };

  const bulkCreateMutation = useMutation({
    mutationFn: createTables,
    onSuccess: handleBulkCreateSuccess,
    onError: (error) => console.log(error),
  });

  const handleBulkCreate = (values) => {
    const params = { ...values, organization: slug() };
    bulkCreateMutation.mutate(params);
  };

  const handleStartSessionSuccess = () => {
    handleShowTable(table);
    handleModals("startSession", false);
  };

  const startSessionMutation = useMutation({
    mutationFn: startSession,
    onSuccess: handleStartSessionSuccess,
    onError: (error) => console.log(error),
  });

  const handleStartSession = (values) => {
    const params = {
      ...values,
      organization: slug(),
      table_no: table.table_number,
      session_type: "waiter_created",
    };
    startSessionMutation.mutate(params);
  };

  const handleUpdateSessionSuccess = (res) => {
    const { data } = res;
    getTable(data.table_no);
  };

  const updateSessionMutation = useMutation({
    mutationFn: updateSession,
    onSuccess: handleUpdateSessionSuccess,
    onError: (error) => console.log(error),
  });

  const handleUpdateSession = (values) => {
    const params = {
      ...values,
      organization: slug(),
    };
    updateSessionMutation.mutate(params);
  };

  const handleShowTable = (table) => {
    const tableRoute = `?table_no=${table.table_number}`;
    navigate(tableRoute);
  };

  const handleHideTable = () => {
    navigate("/tables");
  };

  const handleShowMenu = (session) => {
    setControllerState({
      ...controllerState,
      modals: { showMenu: true },
    });

    setTableCart({
      cart: session.cart,
      sessionToken: session.token,
    });
  };

  const handleShowScanner = () => {
    handleModals("showScanner", true);
  };

  const loading = {
    startSession: startSessionMutation.isLoading,
    bulkCreate: bulkCreateMutation.isLoading,
    getTables: getTablesMutation.isLoading,
    getTable: getTableMutation.isLoading,
  };

  const handleReceivedUpdate = (data = {}) => {
    const { tables } = data;
    setTables(tables);
  };

  const actionCableParams = {
    channel: "TablesChannel",
    slug: slug(),
  };
  useActionCable(actionCableParams, handleReceivedUpdate);

  return (
    <TableContext.Provider
      value={{
        table,
        tables,
        filters,
        loading,
        tableCart,
        tableModals,
        filteredTables,
        getTable,
        setTable,
        setNavigate,
        getAllTables,
        setTableCart,
        handleModals,
        handleFilters,
        handleShowMenu,
        handleHideTable,
        handleShowTable,
        handleBulkCreate,
        handleShowScanner,
        handleInputFilters,
        handleStartSession,
        handleUpdateSession,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableStore;
