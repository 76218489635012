import { CheckBox } from "../../../Components/Inputs";
import { LoadDots } from "../../../Components/Loader";
import Modals from "../../../Components/Modals";
import useOrder from "../../../Hooks/useOrder";
import usePrinter from "../../../Hooks/usePrinter";

import * as S from "./styles";

export const PendingSettingModal = ({ show, onHide }) => {
  const {
    saveRules,
    statusRules,
    setStatusRules,
    loading: { getStatusRules, saveStatusRules },
  } = useOrder({ showStatusRules: true });

  const {
    printerRooms,
    loading: { getRooms },
  } = usePrinter({ getRooms: true });

  const isLoading = getStatusRules || getRooms || saveStatusRules;

  const updateRule = (status, key, val) => {
    setStatusRules((prevRules) => ({
      ...prevRules,
      [status]: {
        ...prevRules[status],
        [key]: prevRules[status][key].includes(val)
          ? prevRules[status][key].filter((c_val) => c_val !== val)
          : [...prevRules[status][key], val],
      },
    }));
  };

  console.log(statusRules);

  return (
    <Modals
      size="sm"
      backdrop="normal"
      curved
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <S.ModalHeader>Configurações de pedidos pendentes</S.ModalHeader>

      {isLoading && (
        <S.ModalContainer>
          <LoadDots />
        </S.ModalContainer>
      )}

      {!isLoading && (
        <S.AutoModalContainer>
          <div className="all_rules">
            <div className="rules_section">
              <p className="rule_header"> Imprimir automaticamente No(a)</p>

              {printerRooms.map((room) => (
                <div
                  key={room.key}
                  className="rule"
                  onClick={() =>
                    updateRule("pending", "automatically_print_in", room.key)
                  }
                >
                  <CheckBox
                    checked={statusRules?.pending?.automatically_print_in?.includes(
                      room.key
                    )}
                  />
                  <p>{room.label} </p>
                </div>
              ))}
            </div>
          </div>

          <S.FullBlackButton onClick={saveRules}>Salvar</S.FullBlackButton>
        </S.AutoModalContainer>
      )}
    </Modals>
  );
};
