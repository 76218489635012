/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  restaurant1,
  restaurant2,
  restaurant3,
  restaurant4,
} from "../../Assets/images/images";
import { TopNav } from "../../Components/TopNav";
import { ColoredButton } from "../../Components/Buttons";
import { controllerContext } from "../../Context/controllerCtx";
import useOrganization from "../../Hooks/useOrganization";
import { getUser, isLogin } from "../../Utils/localStorage";
import * as S from "./styles";
import { Slider } from "../../Components/ImageSlider";
import { Fade } from "react-bootstrap";
import { NotFound } from "../../Components/NotFoundPage";
import { LoadDots } from "../../Components/Loader";
import { validateInvitation } from "../../Utils/helpers";
import { invitationNotFoundText } from "../../Utils/GlobalVariables";
import { toast } from "react-hot-toast";

export const AcceptInvitation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [controllerState, setControllerState] = useContext(controllerContext);
  const [pageState, setPageState] = useState({ isLoading: true });

  const { fetchTeamInvitation, acceptInvitation } = useOrganization();

  useEffect(() => {
    if (id) {
      getInvitaion(id);
    }
  }, [id]);

  const getInvitaion = async (id) => {
    setPageState({ ...pageState, isLoading: true });
    const invitation = await fetchTeamInvitation(id);

    if (validateInvitation(invitation)) {
      setControllerState({
        ...controllerState,
        modals: { login: !isLogin() },
        user: invitation,
      });
      setPageState({ ...pageState, isLoading: false });
    } else {
      setPageState({
        ...pageState,
        notFound: true,
        isLoading: false,
      });
    }
  };

  const sliderData = [
    { image: restaurant1, text: "Testing " },
    { image: restaurant2, text: "Testing " },
    { image: restaurant3 },
    { image: restaurant4 },
  ];

  const rejectInvite = async () => {
    setPageState({ ...pageState, isLoading: true });
    await acceptInvitation({
      invitation_id: id,
      accept: false,
    });
    setPageState({ ...pageState, isLoading: false });
    toast.success(
      `Invitation was rejected, You'll be redirected in 3 seconds.`,
      {
        duration: 4000,
        position: "top-center",
      }
    );
    window.setTimeout(function () {
      navigate("/home");
    }, 5000);
  };

  const acceptInvite = async () => {
    setPageState({ ...pageState, isLoading: true });
    await acceptInvitation({
      invitation_id: id,
      accept: true,
    });
    setPageState({ ...pageState, isLoading: false });
    toast.success(
      `Invitation accepted succesfully, \n You'll be redirected in 3 seconds.`,
      {
        duration: 4000,
        position: "top-center",
      }
    );
    window.setTimeout(function () {
      navigate("/login");
    }, 5000);
  };

  // console.log(controllerState.user);

  return (
    <>
      <TopNav />
      {pageState.isLoading && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}

      {!pageState.isLoading && (
        <>
          {pageState.notFound ? (
            <NotFound
              routeText={"Go To HomePage"}
              error={invitationNotFoundText}
              route={"/home"}
            />
          ) : (
            <>
              <S.Holder>
                <Fade unmountOnExit in={isLogin()}>
                  <S.InvitationHolder>
                    <S.Header>Accept Invitation</S.Header>
                    <S.SubHeader>Hello {getUser()?.name} ,</S.SubHeader>
                    <S.SubHeader>
                      You have been invited to join the{" "}
                      {controllerState?.user?.organization_name} team as a{" "}
                      {controllerState?.user?.role_name} on Waithere.
                    </S.SubHeader>
                    <S.SubHeader>
                      You will be added to the team when you accept the
                      invitaion and have certain access depending on your
                      permissions.
                    </S.SubHeader>
                    <S.SubHeader>
                      You will be redirected to the login page to select the
                      organization you wish to access after accepting the
                      invitation.
                    </S.SubHeader>
                    <S.Note>
                      Note: If you do not have any accosiation with this
                      organization ({controllerState?.user?.organization_name})
                      please kindly decline this invitation.
                    </S.Note>
                    <br />
                    <S.ButtonHolder>
                      <ColoredButton
                        variant={"reject"}
                        text="Reject"
                        isLoading={pageState.isLoading}
                        onClick={async () => {
                          await rejectInvite();
                        }}
                      />

                      <ColoredButton
                        variant={"accept"}
                        text="Accept"
                        isLoading={pageState.isLoading}
                        onClick={async () => {
                          await acceptInvite();
                        }}
                      />
                    </S.ButtonHolder>
                  </S.InvitationHolder>
                </Fade>

                <S.ImageHolder>
                  <Slider data={sliderData} />
                </S.ImageHolder>
              </S.Holder>
            </>
          )}
        </>
      )}
    </>
  );
};
