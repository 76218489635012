import { useNavigate } from "react-router-dom";
import { Footers } from "waitherepackages";
import TimeInput from "material-ui-time-picker";
import Modals from "../../Components/Modals";
import { useState } from "react";
import * as S from "./styles";
import { CheckBox } from "../../Components/Inputs";
import { ColoredButton } from "../../Components/Buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Error } from "../../Components/Modals/styles";

const AboutContctSchema = Yup.object().shape({
  contact: Yup.object().shape({
    address: Yup.string().required("Address is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    facebook: Yup.string().matches(
      /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9_.]{1,30}\/?$/,
      "URL de Facebook invalido."
    ),
    instagram: Yup.string().matches(
      /^(http[s]?:\/\/)?(www\.)?(instagram)\.com\/[a-zA-Z0-9_]+\/?$/,
      "URL de Instagram invalido."
    ),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Invalid phone number")
      .required("Phone number is required"),
    snapchat: Yup.string().matches(
      /^(?:https?:\/\/)?(?:www\.)?snapchat\.com\/add\/([a-zA-Z0-9_]+)$/,
      "URL de Snapchat invalido."
    ),
    tiktok: Yup.string().matches(
      /^(http[s]?:\/\/)?(www\.)?(tiktok)\.com\/@[a-zA-Z0-9_]+$/,
      "URL de TikTok invalido."
    ),
    twitter: Yup.string().matches(
      /^(http[s]?:\/\/)?(www\.)?(twitter)\.com\/[a-zA-Z0-9_]+\/?$/,
      "URL de Twitter invalido."
    ),
    whatsapp: Yup.string().matches(
      /^(http[s]?:\/\/)?(www\.)?(whatsapp)\.com\/[a-zA-Z0-9_]+$/,
      "URL de WhatsApp invalido."
    ),
    youtube: Yup.string().matches(
      /^(http[s]?:\/\/)?(www\.)?(youtube)\.com\/@[a-zA-Z0-9_]+$/,
      "URL de YouTube invalido."
    ),
  }),
});

export const FooterPreview = ({ currentBranding = {}, updateBranding }) => {
  const [modals, setModals] = useState({ hours: false, aboutContact: false });
  const [updatedValues, setUpdatedValues] = useState({});

  const navigate = useNavigate();

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const handleUpdate = (values) => {
    const {
      bgColor,
      buttonColor,
      buttonTxtColor,
      font,
      textColor,
      headerTxtColor,
      hours,
      about,
      contact,
    } = values;

    const editables = {
      about,
      footer: JSON.stringify({
        bgColor,
        buttonColor,
        buttonTxtColor,
        font,
        textColor,
        headerTxtColor,
      }),
      hours: JSON.stringify(hours),
      contact: JSON.stringify(contact),
    };

    updateBranding("footer", editables);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
    handleEditHours: () => setModals({ hours: true }),
    handleEditContact: () => setModals({ aboutContact: true }),
    handlePhoneClick: (num) => {
      console.log(num);
    },
    // copy phone num to clipard and show success toast
  };

  return (
    <>
      <Footers
        isDefaultMoblie={false}
        showDashBoard={true}
        currentValues={{ ...currentBranding, ...updatedValues }}
        actions={actions}
        handleSave={handleUpdate}
      />
      <EditHoursModal
        show={modals.hours}
        hide={() => setModals({ hours: false })}
        hours={currentBranding.hours}
      />
      <EditAboutContactModal
        show={modals.aboutContact}
        about={currentBranding.about}
        contact={currentBranding.contact}
        hide={() => setModals({ aboutContact: false })}
        handleSubmit={(values) =>
          setUpdatedValues({ ...updatedValues, ...values })
        }
      />
    </>
  );
};

const EditHoursModal = ({ show, hide, hours }) => {
  const [allHours, setAllHours] = useState(hours);

  const updateTime = (id, value, type) => {
    const editingHour = allHours.find((hours) => hours.id === id);

    if (type === "closed") {
      editingHour[type] = value;
    } else {
      const newDate = new Date(value)?.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      editingHour[type] = newDate;
    }
    setAllHours(
      allHours.map((hour) => (hour.id === editingHour.id ? editingHour : hour))
    );
  };

  return (
    <Modals
      size="lg"
      curved
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <S.ModalHeader>Horário de funcionamento</S.ModalHeader>
      <S.TableHolder>
        <table>
          <thead>
            <tr>
              <th>Dia</th>
              <th>Abertura</th>
              <th>Fechamento</th>
              <th>Fechado</th>
            </tr>
          </thead>
          <tbody>
            {allHours?.map((item, index) => {
              const today = new Date();
              const openingTime = new Date(
                today.toDateString() + " " + item?.opening_time
              );

              const closingTime = new Date(
                today.toDateString() + " " + item?.closing_time
              );

              return (
                <tr key={index}>
                  <td>
                    <p>{item.day_of_week}</p>
                  </td>
                  <td>
                    <TimeInput
                      mode="24h"
                      defaultValue={openingTime}
                      onChange={(time) => {
                        updateTime(item.id, time, "opening_time");
                      }}
                    />
                  </td>
                  <td>
                    <TimeInput
                      mode="24h"
                      defaultValue={closingTime}
                      onChange={(time) => {
                        updateTime(item.id, time, "closing_time");
                      }}
                    />
                  </td>
                  <td>
                    <div
                      className="check-holder"
                      onClick={() =>
                        updateTime(item.id, !item.closed, "closed")
                      }
                    >
                      <CheckBox checked={item.closed} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </S.TableHolder>
      <hr />

      <S.ButtonHolder>
        <ColoredButton
          variant={"white"}
          text={"Cancelar"}
          onClick={() => {
            hide();
          }}
        />

        <ColoredButton
          variant={"continue"}
          text={"Finalize"}
          onClick={() => {
            hide();
          }}
        />
      </S.ButtonHolder>
    </Modals>
  );
};

const EditAboutContactModal = ({
  show,
  hide,
  about,
  contact,
  handleSubmit,
}) => {
  const formik = useFormik({
    initialValues: { about, contact },
    validationSchema: AboutContctSchema,
    onSubmit: (values) => {
      hide();
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const handleEdit = (value, key, subKey) => {
    if (subKey) {
      formik.setFieldValue(key, { ...formik.values[key], [subKey]: value });
    } else {
      formik.setFieldValue(key, value);
    }
  };

  return (
    <Modals
      size="lg"
      curved
      show={show}
      onHide={() => {
        hide();
        formik.resetForm();
      }}
    >
      <S.ModalHeader>Contato</S.ModalHeader>
      <S.FlexHolder>
        <div className="section">
          <S.Input
            placeholder="Twitter"
            value={formik.values?.contact.twitter}
            error={formik?.errors?.contact?.twitter}
            onChange={(e) => handleEdit(e.target.value, "contact", "twitter")}
          />
          <Error>{formik?.errors?.contact?.twitter}</Error>
          <S.Input
            placeholder="Address"
            value={formik.values?.contact.address}
            error={formik?.errors?.contact?.address}
            onChange={(e) => handleEdit(e.target.value, "contact", "address")}
          />
          <Error>{formik?.errors?.contact?.address}</Error>
          <S.Input
            placeholder="SnapChat"
            value={formik.values?.contact.snapchat}
            error={formik?.errors?.contact?.snapchat}
            onChange={(e) => handleEdit(e.target.value, "contact", "snapchat")}
          />
          <Error>{formik?.errors?.contact?.snapchat}</Error>
          <S.Input
            placeholder="Phone"
            value={formik.values?.contact.phone}
            error={formik?.errors?.contact?.phone}
            onChange={(e) => handleEdit(e.target.value, "contact", "phone")}
          />
          <Error>{formik?.errors?.contact?.phone}</Error>
          <S.Input
            placeholder="Email"
            value={formik.values?.contact.email}
            error={formik?.errors?.contact?.email}
            onChange={(e) => handleEdit(e.target.value, "contact", "email")}
          />
          <Error>{formik?.errors?.contact?.email}</Error>
        </div>
        <div className="section">
          {/* <S.StartSubText>Contante</S.StartSubText> */}

          <S.Input
            placeholder="Whatsapp"
            value={formik.values?.contact.whatsapp}
            error={formik?.errors?.contact?.whatsapp}
            onChange={(e) => handleEdit(e.target.value, "contact", "whatsapp")}
          />
          <Error>{formik?.errors?.contact?.whatsapp}</Error>
          <S.Input
            placeholder="Instagram"
            value={formik.values?.contact.instagram}
            error={formik?.errors?.contact?.instagram}
            onChange={(e) => handleEdit(e.target.value, "contact", "instagram")}
          />
          <Error>{formik?.errors?.contact?.instagram}</Error>
          <S.Input
            placeholder="Facebook"
            value={formik.values?.contact.facebook}
            error={formik?.errors?.contact?.facebook}
            onChange={(e) => handleEdit(e.target.value, "contact", "facebook")}
          />
          <Error>{formik?.errors?.contact?.facebook}</Error>
          <S.Input
            placeholder="TikTok"
            value={formik.values?.contact.tiktok}
            error={formik?.errors?.contact?.tiktok}
            onChange={(e) => handleEdit(e.target.value, "contact", "tiktok")}
          />
          <Error>{formik?.errors?.contact?.tiktok}</Error>
          <S.Input
            placeholder="Youtube"
            value={formik.values?.contact.youtube}
            error={formik?.errors?.contact?.youtube}
            onChange={(e) => handleEdit(e.target.value, "contact", "youtube")}
          />
          <Error>{formik?.errors?.contact?.youtube}</Error>
        </div>
      </S.FlexHolder>
      <hr />

      <S.ButtonHolder>
        <ColoredButton
          variant={"white"}
          text={"Cancelar"}
          onClick={() => {
            hide();
            formik.resetForm();
          }}
        />

        <ColoredButton
          variant={"continue"}
          text={"Finalizer"}
          onClick={() => {
            formik.handleSubmit();
          }}
        />
      </S.ButtonHolder>
    </Modals>
  );
};
