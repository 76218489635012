import { IoMdAdd } from "react-icons/io";
import usePrinter from "../../../Hooks/usePrinter";
import { LoadDots } from "../../Loader";
import Modals from "../../Modals";
import * as S from "../styles";
import { Collapse } from "react-bootstrap";
import { ImPencil } from "react-icons/im";
import { TbTrash } from "react-icons/tb";

export const PrinterRoomModal = ({
  controllerState = { modals: {} },
  setControllerState,
}) => {
  const {
    modals: { showPrinterRooms },
  } = controllerState;

  const {
    addRoom,
    saveRoom,
    deleteRoom,
    showOption,
    updateRooms,
    currentRoom,
    printerRooms,
    setShowOption,
    setCurrentRoom,
    loading: { getRooms },
  } = usePrinter({ getRooms: true });

  return (
    <Modals
      size="sm"
      curved
      show={showPrinterRooms}
      backdrop="static"
      onHide={() => {
        setControllerState({
          ...controllerState,
          modals: { ...controllerState.modals, showPrinterRooms: false },
        });
      }}
    >
      <S.ModalHeader>Printer Rooms </S.ModalHeader>
      <S.ModalBody>
        {getRooms && <LoadDots />}
        {!getRooms && (
          <>
            <S.RoomsContainer>
              <S.RoomsHeader>
                <p>Salas</p>
                <IoMdAdd onClick={() => setShowOption({ add: true })} />
              </S.RoomsHeader>

              <Collapse in={showOption.add || showOption.edit}>
                <S.CollapseHolder>
                  <S.FlexHolder>
                    <S.Input
                      placeholder="Nome de Sala "
                      value={currentRoom?.label ?? ""}
                      onChange={(e) =>
                        setCurrentRoom({
                          ...currentRoom,
                          label: e.target.value,
                        })
                      }
                    />
                  </S.FlexHolder>
                  <S.ButtonHolder>
                    <S.GreyButton
                      className="small-btn"
                      onClick={() => {
                        setCurrentRoom({});
                        setShowOption({});
                      }}
                    >
                      {"Cancel"}
                    </S.GreyButton>

                    <S.BlackButton
                      className="small-btn"
                      onClick={showOption.edit ? saveRoom : addRoom}
                    >
                      {showOption.edit ? "Save" : "Add"}
                    </S.BlackButton>
                  </S.ButtonHolder>
                </S.CollapseHolder>
              </Collapse>

              <S.OptionsHolder>
                {printerRooms?.map((option, i) => (
                  <S.Room key={i} small>
                    <p className="option_name">{option.label}</p>

                    <div className="actions-holder">
                      <ImPencil
                        onClick={() => {
                          setCurrentRoom(option);
                          setShowOption({ edit: true });
                        }}
                      />
                      <TbTrash
                        className="delete-icon"
                        onClick={() => deleteRoom(option)}
                      />
                    </div>
                  </S.Room>
                ))}
              </S.OptionsHolder>

              <S.ButtonHolder end>
                <S.BlackButton fill onClick={updateRooms}>
                  Salvar
                </S.BlackButton>
              </S.ButtonHolder>
            </S.RoomsContainer>
          </>
        )}
      </S.ModalBody>
    </Modals>
  );
};
