import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const startSession = async (params) => {
  const user = getUser();

  return await api.post(`api/v1/sessions/create_session`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateSession = async (params) => {
  const user = getUser();

  return await api.patch(`api/v1/sessions/update_session`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};
