import { api } from ".";
import { getUser, getUserOrg } from "../Utils/localStorage";

export const getTeamMembers = async (orgId) => {
  const user = getUser();

  return await api.get(`api/v1/team/${orgId}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateTeamMember = async (params) => {
  const user = getUser();

  return await api.patch(
    `api/v1/team/${params.teammate_id}`,
    {
      role_id: params.role_id,
      teammate_id: params.teammate_id,
      organization_id: getUserOrg()?.organization?.id,
    },
    {
      headers: {
        Authorization: user?.token,
      },
    }
  );
};

export const deleteTeamMember = async (params) => {
  const user = getUser();

  return await api.delete(`api/v1/team/${params.teammate_id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const sendTeamInvitations = async (params) => {
  const user = getUser();

  return await api.post(`/api/v1/pending_team_invitation/`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getTeamInvitation = async (id) => {
  return await api.get(`/api/v1/pending_team_invitation/${id}`);
};

export const acceptRejectTeamInvitation = async (param) => {
  const user = getUser();

  return await api.post(`/api/v1/user_to_organization/`, param, {
    headers: {
      Authorization: user?.token,
    },
  });
};
