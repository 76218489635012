import { ImPencil } from "react-icons/im";
import { TopNav } from "../../Components/TopNav";
import * as S from "./styles";
import { getUser } from "../../Utils/localStorage";
import { RiUser3Fill } from "react-icons/ri";
import useProfile from "../../Hooks/useProfile";

export const Profile = () => {
  const { avatar, setAvatar, updateAvatar } = useProfile();

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    setAvatar({ new: newFile, current: avatar.current });
  };

  return (
    <>
      <TopNav />
      <S.Holder>
        <S.imageHolder>
          {avatar.current || avatar.new ? (
            <S.ProfileImg
              src={`${
                avatar.new ? URL.createObjectURL(avatar.new) : avatar.current
              }`}
            />
          ) : (
            <RiUser3Fill className="empty-image" />
          )}
          <S.EditBtn>
            <input type="file" value="" onChange={onFileDrop} />
            <ImPencil />
          </S.EditBtn>
        </S.imageHolder>
        <br />
        <S.UserName>{getUser().name}</S.UserName>
        <S.SaveBtn onClick={async () => await updateAvatar()}>
          Save Changes
        </S.SaveBtn>
      </S.Holder>
    </>
  );
};
