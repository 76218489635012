import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .delete_icon {
    position: absolute;
    color: red;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const NavsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 30px;
  right: 30px;
  gap: 25px;
`;

export const IndicatorHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
`;
