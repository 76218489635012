import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuickAccesser } from "waitherepackages";

export const QuickAccessPreview = ({
  currentBranding = {},
  updateBranding,
}) => {
  const navigate = useNavigate();

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const handleUpdate = (values) => {
    const {
      bgColor,
      iconColor,
      buttonColor,
      toastBgColor,
      toastTxtColor,
      toastIconColor,
      modalBgColor,
      modalTxtColor,
      modalIconColor,
      modalBtnColor,
      modalBtnTxtColor,
    } = values;

    const editables = {
      quickaccesser: JSON.stringify({
        bgColor,
        iconColor,
        buttonColor,
        toastBgColor,
        toastTxtColor,
        toastIconColor,
        modalBgColor,
        modalTxtColor,
        modalIconColor,
        modalBtnColor,
        modalBtnTxtColor,
        font: "arial",
      }),
    };
    updateBranding("quickaccesser", editables);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
  };
  const [selected, setSelected] = useState({ cart: false });

  return (
    <div>
      <QuickAccesser
        handleSave={handleUpdate}
        isDefaultMoblie={false}
        showDashBoard={true}
        currentValues={currentBranding}
        actions={actions}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
