/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from "react";
import { TableContext } from "../Context/tableContext";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

const useTable = ({ navigate, indexTables }) => {
  const context = useContext(TableContext);
  const [searchParams] = useSearchParams();

  const { setTable, setNavigate, getAllTables, getTable, handleModals } =
    context;

  const table_no = useMemo(() => searchParams.get("table_no"), [searchParams]);

  useEffect(() => {
    const navigator = (route) => navigate(route);
    setNavigate(() => navigator);
  }, []);

  useEffect(() => {
    indexTables && getAllTables();
  }, [indexTables]);

  const handleTableIdChange = useCallback(() => {
    if (table_no) {
      setTable({ table_number: table_no });
      getTable(table_no);
    }
    handleModals("table", table_no);
  }, [table_no]);

  useEffect(() => {
    handleTableIdChange();
  }, [table_no]);

  return context;
};

export default useTable;
