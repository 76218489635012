import styled from "styled-components";
import styles from "../../Assets/styles";
// import styles from "../../Assets/styles";

export const Holder = styled.div`
  margin-top: 72px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home_container,
  .access_container {
    max-height: calc(100vh - 70px);
    overflow: hidden;
  }

  .home_holder {
    height: calc(100vh - 70px);
    overflow: scroll;

    & > div:first-child {
      margin-top: auto;
    }
  }

  .access_holder {
    min-height: calc(100vh - 70px) !important;

    .pop_modal {
      max-height: 72vh;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  margin-top: -35px;
`;

export const TableHolder = styled.div`
  table {
    width: calc(100% + 80px);
  }
  p {
    margin: 0;
    padding: 0;
    text-transform: capitalize;
  }
  tr {
    /* display: grid; */
    row-gap: 10px;
    height: 50px;
  }

  .check-holder {
    margin-left: 20px;
    width: 20px;
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.center ? "center" : props.end ? "end" : "space-between"};
  align-items: center;
  margin-bottom: 10px;
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MiniLoadHolder = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardHolder = styled.div`
  width: 355px;
  height: 120px;
  position: relative;
  .check {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const FlexHolder = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */

  .section {
    width: 50%;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
`;

export const TextInput = styled.textarea`
  width: 100%;
  height: 192px;
  max-height: 192px;
  resize: none;
  border: 1px solid ${styles.DarkGrey};
  border-radius: 10px;
  padding: 6px 10px;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => (props.error ? styles.Error : styles.DarkGrey)};
  border-radius: 10px;
  padding: 0px 10px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${styles.DarkGrey};
  max-width: 300px;
  text-align: center;
  font-weight: 500;
`;

export const StartSubText = styled(SubText)`
  text-align: start;
  max-width: 100%;
`;

export const AllMenuItemsHolder = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 20px;
  /* justify-content: space-evenly; */
  @media (max-width: ${500}px) {
    justify-content: center;
  }
`;
