/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadDots } from "../../Components/Loader";
import * as S from "./styles";

export const DashBoardHome = () => {
  const navigate = useNavigate();

  const [pageState, setPageState] = useState({});

  const apiCalls = async () => {
    setPageState({ ...pageState, isLoading: true });
    setPageState({ ...pageState, isLoading: false });
  };

  useEffect(() => {
    apiCalls();
  }, []);

  return (
    <>
      {pageState.isLoading && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!pageState.isLoading && (
        <S.HomeHolder>
          <S.CategoriesHolder>HOME</S.CategoriesHolder>
        </S.HomeHolder>
      )}
    </>
  );
};
