import styled from "styled-components";
import styles from "../../Assets/styles";
import { Collapse } from "react-bootstrap";

export const Holder = styled.div`
  width: 100%;
  min-height: 70px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${styles.LightGrey};
  border: 2px solid ${styles.LightGrey};
  border-left: 4px solid ${({ isActive }) => isActive && styles.SuccessGreen};
`;
export const TopSectionHolder = styled.div``;

export const TopSection = styled.div`
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .time-frame {
    max-width: 90%;
  }
  .drop-down-icon {
    cursor: pointer;
    transition: 0.5s;
    width: 30px;
    height: 30px;
    transform: ${({ expanded }) => expanded && " rotate(180deg)"};
  }
  .actions-holder {
    display: flex;
    gap: 25px;
    .delete-icon {
      color: ${styles.Error};
      width: 20px;
      height: 20px;
    }
    svg {
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

export const MenuItemsHolder = styled(Collapse)``;

export const MenuItemsCont = styled.div`
  min-height: 150px;
  border-top: 1px solid ${styles.OffBlack};
  margin-top: 10px;
  padding-top: 10px;
`;

export const MenuName = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

export const NoMenuItems = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddNewBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;

  transition: 0.2s !important;
  border-radius: 50%;
  min-width: max-content;
  cursor: pointer;
  background-color: ${styles.OffBlack};
  color: ${styles.NeutralsWhite};
  :hover {
    background-color: ${styles.LightGrey};
    color: ${styles.OffBlack};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const LoadHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AllMenuItemsHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 20px;
  /* justify-content: space-evenly; */
  @media (max-width: ${500}px) {
    justify-content: center;
  }
`;

export const MenuItemHolder = styled.div`
  width: 355px;
  height: 120px;
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  border: 0.5px solid ${styles.MidGrey};
  overflow: hidden;
  cursor: pointer;
  .menu-item-img {
    width: 120px;
    min-width: 120px;
    height: 120px;
    object-fit: cover;
    margin: -10px;
  }
  .details {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100% - 120px);
  }
  .menu-item-name {
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
  }
  .desc-holder {
    overflow: hidden;
    max-height: 50px;
  }
  .menu-item-desc {
    font-size: 15px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    /* white-space: nowrap; */
    max-width: 100%;
  }
  .menu-item-prc {
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
`;
