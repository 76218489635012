/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import {
  createOrganization,
  editOrganization,
  getOrg,
  getUserOrgs,
} from "../Api/OrganizationApi";
import { getRoles } from "../Api/Roles";
import {
  acceptRejectTeamInvitation,
  deleteTeamMember,
  getTeamInvitation,
  getTeamMembers,
  sendTeamInvitations,
  updateTeamMember,
} from "../Api/TeamApi";
import { getUser, getUserOrg, setUserOrg } from "../Utils/localStorage";
import { useNavigate } from "react-router-dom";

const useOrganization = () => {
  const user = getUser();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState(getUserOrg());
  const [organizationTeam, setOrganizationTeam] = useState([]);
  const [allUserOrganizations, setAllUserOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState({});

  const fetchOrganization = useCallback(async () => {
    try {
      setLoading({ organization: true });
      const { data } = await getOrg(getUserOrg().id);
      setOrganization(data);
      setLoading({ organization: false });
    } catch (err) {
      console.log(err);
    }
  }, [getUserOrg()?.id]);

  const toastHandler = (type, message) => {
    toast[type](`${message}`, {
      duration: 4000,
      position: "top-center",
      style: {
        marginTop: "60px",
      },
    });
  };

  const updateOrganization = useCallback((org) => {
    setOrganization(org);
    setUserOrg(org);
  }, []);

  const fetchAllUserOrgs = async () => {
    const { data } = await getUserOrgs(user.id);
    setAllUserOrganizations(data);
  };

  const acceptInvitation = async (params) => {
    const data = acceptRejectTeamInvitation(params);
    console.log(data);
  };

  const fetchAllRoles = async () => {
    const { data } = await getRoles(user.id);
    const formatedRoles = data.data.map((role) => ({
      label: role.user_type,
      value: role.id,
    }));
    setRoles(formatedRoles);
  };

  const fetchOragnizationTeam = async () => {
    try {
      const { data } = await getTeamMembers(getUserOrg()?.id);
      setOrganizationTeam(data.team_mates);
    } catch (err) {
      console.log(err);
    }
  };

  const updateOragnizationsTeam = async (params) => {
    try {
      const { data } = await updateTeamMember(params);
      setOrganizationTeam(data.team_mates);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMember = async (params) => {
    try {
      const { data } = await deleteTeamMember(params);
      toastHandler("success", `User has been deleted successfully !!`);
      setOrganizationTeam(data.team_mates);
    } catch (err) {
      const error = err?.response?.data?.error;
      toastHandler("error", `Failed to delete, ${error} !!`);
      console.log(err);
    }
  };

  const sendTeamOrganization = async (params) => {
    try {
      const { data } = await sendTeamInvitations(params);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTeamInvitation = async (id) => {
    try {
      const { data } = await getTeamInvitation(id);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditOrganization = async (values) => {
    setLoading({ organization: true });
    try {
      const { data, status } = await editOrganization(values);
      setLoading({ organization: false });
      if (status === 200) toastHandler("success", "Organization Updated !");
      return data;
    } catch (err) {
      toastHandler("error", "Update Failed");
      console.log(err);
    }
  };

  const handleCreateOrganization = async (values) => {
    try {
      setLoading({ organization: true });
      const { data, status } = await createOrganization(values);
      if (status === 200) navigate("/log-in");
      setLoading({ organization: false });
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    roles,
    loading,
    organization,
    organizationTeam,
    allUserOrganizations,
    setLoading,
    fetchAllRoles,
    fetchOrganization,
    setOrganization,
    acceptInvitation,
    updateOrganization,
    fetchAllUserOrgs,
    fetchOragnizationTeam,
    updateOragnizationsTeam,
    sendTeamOrganization,
    fetchTeamInvitation,
    deleteMember,
    handleEditOrganization,
    handleCreateOrganization,
  };
};

export default useOrganization;
