import { api } from ".";
import { getUser } from "../Utils/localStorage";

const user = getUser();

export const getBuilderState = async (organization_id) =>
  await api.get(`/api/v1/builder/${organization_id},`, {
    headers: {
      Authorization: user?.token,
    },
  });

export const saveBuilderState = async (id, data) =>
  await api.put(
    `/api/v1/builder/${id}`,
    {
      body: JSON.stringify(data),
    },
    {
      headers: {
        Authorization: user?.token,
      },
    }
  );
