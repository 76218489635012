export const invitationNotFoundText =
  "Ooops, Invitation doesn't exist or you don't have access to view it !";

// Team
export const INVITE_MEMBER = "inviteMember";
export const EDIT_MEMBER = "editMember";
export const DELETE_MEMBER = "deleteMember";

// Menu
export const CREATE_CATEGORY = "createCategory";
export const EDIT_CATEGORY = "editCategory";
export const DELETE_CATEGORY = "deleteCategory";

// Org
export const EDIT_ORG = "editOrganization";

// Roles
export const ADMIN = "admin";
export const WAITER = "waiter";
export const CHEF = "chef";

// Days

export const ALLDAYSOFWEEK = [
  { value: 0, label: "S", fullLabel: "Sun" },
  { value: 1, label: "M", fullLabel: "Mon" },
  { value: 2, label: "T", fullLabel: "Tue" },
  { value: 3, label: "W", fullLabel: "Wed" },
  { value: 4, label: "T", fullLabel: "Thur" },
  { value: 5, label: "F", fullLabel: "Fri" },
  { value: 6, label: "S", fullLabel: "Sat" },
];
