import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const createTables = async (params) => {
  const user = getUser();

  return await api.post(`api/v1/tables/bulk_create`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const indexTables = async (organization) => {
  const user = getUser();

  return await api.get(`api/v1/tables/${organization}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const showTable = async (params) => {
  const { slug, table_no } = params;
  const user = getUser();

  return await api.get(`api/v1/tables/${slug}/${table_no}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};
