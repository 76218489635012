/* eslint-disable array-callback-return */
import { Collapse } from "react-bootstrap";
import { useState } from "react";
import * as S from "./styles";

export const DropDownSelect = ({
  values,
  onChange,
  defaultOpen,
  placeholder,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  const [text, setText] = useState("");

  const handleChange = (value) => {
    setText(value);
    if (!value) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  };

  return (
    <S.Holder>
      <div onClick={() => setIsExpanded(!isExpanded)}>
        <input
          value={text}
          placeHolder={placeholder}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </div>

      <Collapse in={isExpanded}>
        <S.CollapseHolder>
          {values?.map((val, i) => {
            if (
              val?.label?.toLowerCase()?.includes(text?.toLowerCase()) ||
              text === ""
            )
              return (
                <S.Option
                  key={i}
                  onClick={() => {
                    setText(val.label);
                    setIsExpanded(false);
                    onChange && onChange(val);
                  }}
                >
                  <S.OrgImage src={val.image} /> <p>{val.label}</p>
                </S.Option>
              );
          })}
        </S.CollapseHolder>
      </Collapse>
    </S.Holder>
  );
};
