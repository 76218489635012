/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  createMenuItem,
  createMenus,
  createNewCategory,
  deleteMenu,
  deleteMenuCategory,
  fetchMenuUnderCategory,
  getMenuCategories,
  getMenuItems,
  getPublicMenuItems,
  updateCategories,
  updateMenuItem,
  updateMenus,
} from "../Api/MenuApi";
import { getUserOrg } from "../Utils/localStorage";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";

const useMenu = ({ isPrivate, isPublic }) => {
  const [menus, setMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({});

  const [menuItems, setMenuItems] = useState({});
  const [allCatMenuItems, setAllCatMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState({});

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  const [isLoading, setIsLoading] = useState({ categories: true });
  const [itemToDelete, setItemDelete] = useState({ type: null, values: {} });

  const fetchCategories = useCallback(async () => {
    setIsLoading({ categories: true });
    try {
      const {
        data: { categories },
      } = await getMenuCategories();
      setCategories(categories);
      categories?.length && setSelectedCategory(categories[0]);
      setIsLoading({ categories: false });
    } catch (error) {
      setIsLoading({ categories: false });
      console.log(error);
    }
  }, []);

  const fetchMenusUnderCategory = useCallback(
    async (id) => {
      setIsLoading({ menus: true });
      try {
        const {
          data: { menus },
        } = await fetchMenuUnderCategory(id);
        setMenu(menus);
      } catch (error) {
        console.log(error);
      }
      setIsLoading({ menus: false });
    },
    [selectedCategory]
  );

  const createCatergory = useCallback(async (category_name) => {
    const {
      organization: { id },
    } = getUserOrg();

    const params = {
      organization_id: id,
      name: category_name,
    };

    try {
      setIsLoading({ modal: true });
      const {
        data: { categories },
      } = await createNewCategory(params);
      setCategories(categories);
      setSelectedCategory(
        categories.find((category) => category.name === params.name)
      );
      setIsLoading({ modal: false });
      toastHandler(
        "success",
        ` ${category_name} category created successfully !`
      );
    } catch (error) {
      setIsLoading({ modal: false });
      toastHandler("error", "You cant perform this action");
      console.log(error);
    }
  }, []);

  const updateCategory = useCallback(
    async (params) => {
      setIsLoading({ modal: true });
      try {
        const {
          data: { categories },
        } = await updateCategories(params);
        setCategories(categories);
        setSelectedCategory(
          categories.find((category) => category.id === params.menu_id)
        );
        toastHandler("success", "Category updated successfully !");
      } catch (error) {
        toastHandler("error", "You cant perform this action");
        console.log(error);
      }
      setIsLoading({ modal: false });
    },
    [categories]
  );

  const handleDelete = useCallback(async () => {
    setIsLoading({ modal: true });
    switch (itemToDelete.type) {
      case "category":
        try {
          const {
            data: { categories },
          } = await deleteMenuCategory(itemToDelete.values.id);
          setCategories(categories);
          setSelectedCategory(categories[0]);
          toastHandler("success", "Category deleted successfully !");
        } catch (err) {
          toastHandler("error", "You cant perform this action");
          console.log(err);
        }
        break;
      case "menu":
        try {
          const {
            data: { menus },
          } = await deleteMenu(itemToDelete.values.id);
          setMenu(menus);
          setSelectedMenu({});
          toastHandler("success", "Menu deleted successfully !");
        } catch (err) {
          toastHandler("error", "You cant perform this action");
          console.log(err);
        }
        break;
      default:
        break;
    }
    setItemDelete({ type: "", values: {} });
    setIsLoading({ modal: false });
  }, [itemToDelete]);

  const createMenu = useCallback(async (params) => {
    setIsLoading({ modal: true });
    try {
      const {
        data: { menus },
      } = await createMenus(params);
      setMenu(menus);
      toastHandler("success", "Menu created successfully !");
    } catch (err) {
      toastHandler("error", "You cant perform this action");
      console.log(err);
    }
    setIsLoading({ modal: false });
  }, []);

  const updateMenu = useCallback(
    async (params) => {
      setIsLoading({ modal: true });
      try {
        const {
          data: { menus },
        } = await updateMenus(params);
        setMenu(menus);
        toastHandler("success", "Menu updated successfully !");
      } catch (error) {
        toastHandler("error", "You cant perform this action");
        console.log(error);
      }
      setIsLoading({ modal: false });
    },
    [categories]
  );

  const handleCreateMenuItem = async (params) => {
    setIsLoading({ modal: true });
    try {
      const { data } = await createMenuItem(params);

      setMenuItems({
        ...menuItems,
        [`${params.menu_id}`]: data,
      });

      toastHandler("success", "Menu Item Created successfully !");
    } catch (error) {
      toastHandler("error", "You cant perform this action");
      console.log(error);
    }
    setIsLoading({ modal: false });
  };

  const handleUpdateMenuItem = async (params) => {
    setIsLoading({ modal: true });
    try {
      const { data } = await updateMenuItem(params);

      setMenuItems({
        ...menuItems,
        [`${params.menu_id}`]: data,
      });

      toastHandler("success", "Menu updated successfully !");
    } catch (error) {
      toastHandler("error", "You cant perform this action");
      console.log(error);
    }
    setIsLoading({ modal: false });
  };

  const fetchMenuItems = async (id, name) => {
    setIsLoading({ menuItems: id });
    try {
      const { data } = await getMenuItems(id);
      setMenuItems({
        ...menuItems,
        [`${id}`]: data,
      });
    } catch (error) {
      toastHandler("error", "Something went wrong");
      console.log(error);
    }
    setIsLoading({ menuItems: false });
  };

  const updateMenuItemCount = (id) => {
    setMenu(
      menus.map((menu) => ({
        ...menu,
        items_count: menuItems[`${menu.id}`]?.length ?? menu.items_count ?? 0,
      }))
    );
  };

  const catMenuItemsMutation = useMutation({
    mutationFn: getPublicMenuItems,
    onSuccess: (res) => {
      const { data } = res;
      setAllCatMenuItems(data);
    },
    onError: (error) => console.log(error),
  });

  const handleCatMenuItems = () => {
    catMenuItemsMutation.mutate(selectedCategory.id);
  };

  const toastHandler = (type, message) => {
    toast[type](`${message}`, {
      duration: 4000,
      position: "top-center",
      style: {
        marginTop: "60px",
      },
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    updateMenuItemCount();
  }, [menuItems]);

  // to refetch menus when the categories is changed
  useEffect(() => {
    if (selectedCategory.id && isPrivate) {
      fetchMenusUnderCategory(selectedCategory.id);
    } else if (selectedCategory.id && isPublic) {
      handleCatMenuItems();
    }
  }, [selectedCategory]);

  return {
    menus,
    menuItems,
    categories,
    isLoading,
    selectedCategory,
    selectedMenu,
    itemToDelete,
    allCatMenuItems,
    selectedMenuItem,
    fetchMenuItems,
    setItemDelete,
    setSelectedMenu,
    createMenu,
    updateMenu,
    updateCategory,
    handleDelete,
    setSelectedCategory,
    createCatergory,
    handleCatMenuItems,
    handleCreateMenuItem,
    handleUpdateMenuItem,
    fetchMenusUnderCategory,
    setSelectedMenuItem,
  };
};

export default useMenu;
