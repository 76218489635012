import styled, { css } from "styled-components";
import styles from "../../Assets/styles";

export const Container = styled.div`
  margin-top: 150px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Holder = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1040px;
  max-width: 1040px;
  height: 100vh;
  @media (max-width: ${1000}px) {
    width: 100vw;
    padding: 0px 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const OrderTopSection = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* justify-content: flex-end; */
  .first_section {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    .search_holder {
      width: 400px;
      height: 40px;
      display: flex;
      border: 2px solid lightgrey;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      overflow: hidden;
      padding: 0px 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      svg {
        width: 20px;
        height: 20px;
      }

      .search_icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input {
        border: 0;
        width: calc(100% - 20px);
        font-weight: 600;
        background-color: transparent;
        padding: 0px 7px;
        :focus {
          border: 0;
          outline: 0;
        }
      }

      @media (max-width: ${1000}px) {
        width: 67vw;
      }
    }

    .filters {
      position: relative;
      margin-left: auto;

      .size_section {
        padding: 10px;
        font-weight: 600;

        .radios_holder {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .black-radio {
          display: flex;
          align-items: center;
        }

        .black-radio input {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 15px;
          height: 15px;
          border: 1px solid black;
          border-radius: 50%;
          outline: none;
          margin-right: 5px;
          cursor: pointer;
        }

        .black-radio input:checked {
          background-color: ${styles.IconGrey};
          border: 1px solid black;
        }

        p {
          padding: 0;
          margin: 0;
        }
      }
    }

    .filter_holder {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      gap: 7px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      border: 2px solid lightgrey;
      border-radius: 5px;

      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      @media (max-width: ${1000}px) {
        width: 30px;
        border: none;
        box-shadow: none;

        p {
          display: none;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .status_holder {
    display: flex;
    gap: 20px;
    padding: 10px;
    overflow-y: scroll;
    width: 100%;
    /* height: 50px; */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StatusCard = styled.div`
  gap: 10px;
  width: 150px;
  height: 45px;
  padding: 0px 10px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  background-color: ${styles.NeutralsWhite};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: 0.3s;

  @media (min-width: ${1000}px) {
    :hover {
      opacity: 0.6;
      background-color: ${styles.Black};
      color: ${styles.NeutralsWhite};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${styles.Black};
      color: ${styles.NeutralsWhite};
    `}

  .status_name {
    font-weight: 600;
  }

  .quantity {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => color};
    color: ${styles.Black};
    font-size: 12px;
    font-weight: 700;
  }
`;

export const FiltersHolder = styled.div`
  width: 250px;
  position: absolute;
  right: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${styles.NeutralsWhite};
  z-index: 10;
  border-radius: 5px;
  gap: 50px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`;

export const OrderList = styled.div`
  padding: 0px 20px;
  padding-bottom: 20px;
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StatusSection = styled.div`
  margin-top: 35px;

  .status_header_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    h3 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 10px;
      position: sticky;
      top: 0px;
      left: 10px;
      background-color: white;
      z-index: 9;
    }
  }

  .orders_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    background-color: ${({ color }) =>
      `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
        color.slice(3, 5),
        16
      )}, ${parseInt(color.slice(5, 7), 16)}, 0.3)`};
  }
`;

export const PaginatorHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .quantity {
    margin: 0;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination_button {
    transition: 0.3s;
    padding: 5px;
    gap: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    position: relative;
    p {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 12px;
      font-weight: 600;
    }
    :hover {
      opacity: 0.5;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
      `}
  }
`;

export const OrderCard = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 20px);
  max-width: 100%;
  height: 260px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${styles.NeutralsWhite};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  .card_top {
    gap: 20px;
    display: flex;

    .order_by {
      margin-right: auto;
      overflow: hidden;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: 700;
      }
      .location {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        color: ${styles.IconGrey};
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    .order_number {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: ${styles.IconGrey};
      font-weight: 600;
      text-decoration: underline;
      text-underline-offset: 2px;
      cursor: pointer;
    }
  }

  .total {
    margin-top: auto;
    position: relative;
    border-top: 1px solid ${styles.IconGrey};
    padding-top: 10px;
    width: 100%;
    p {
      font-weight: 700 !important;
    }
  }

  .items {
    gap: 10px;
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    /* background-color: red; */
    height: 35%;
    overflow: hidden;
  }

  .item {
    display: flex;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      border-radius: 50%;
      background-color: ${({ color }) => color};
      border: 1.5px dotted black;
      margin-left: 20px;
    }

    .eqaul {
      margin-left: 16px;
      margin-top: auto;
    }

    .item_name {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-left: 45px;
      margin-right: auto;
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item_price {
      margin-left: 20px;
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .options_holder {
    background-color: ${styles.LightGrey};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 2px;
    margin-right: -15px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }

  @media (max-width: ${1000}px) {
    width: 100%;
  }
`;

export const LoaderHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.3);
`;

export const OptionsHolder = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  width: 90%;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media (max-width: ${1000}px) {
    width: 100%;
  }

  .holder {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .opt_item {
    gap: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 50px;

    .opt_name {
      margin: 0;
      padding: 0;
      margin-left: 10px;
      font-weight: 600 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
    }

    svg {
      width: 22px;
      height: 22px;
      margin: 0;
      padding: 0;
      margin-top: 5px;
    }
  }

  .down_options_holder {
    position: absolute;
    left: 0px;
    top: 40px;
    width: 100%;
    z-index: 999;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .down_opt {
      gap: 10px;
      height: 30px;
      margin: 10px 0;
      padding: 0px 30px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      :hover {
        opacity: 0.4;
      }
    }

    .bordered {
      border-top: 1px solid lightgrey;
    }

    .down_opt_name {
      padding: 0;
      margin: 0;
      font-weight: 500 !important;
      display: flex;
      align-items: center;
      gap: 7px;
      svg {
        margin-bottom: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1.5px dotted black;
`;

export const StatusSetting = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .setting_svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
