import { useNavigate } from "react-router-dom";
import { Menus } from "waitherepackages";
import { useQuickMenu } from "../../Hooks/useQuickMenu";

export const MenuPreview = ({ currentBranding = {}, updateBranding }) => {
  const navigate = useNavigate();
  const menuProps = useQuickMenu();
  const { handleCategoryChange, setShowModals, setSelectedMenuItem } =
    menuProps;

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const handleUpdate = (values) => {
    const {
      bgColor,
      buttonColor,
      buttonTxtColor,
      font,
      textColor,
      menuItemColor,
      menuTxtColor,
      menuBdrColor,
      menuModalColor,
      menuTxtModalColor,
      menuIconModalColor,
      menuItemModalBtnColor,
      menuModalBtnBdrColor,
      menuTxtModalBtnColor,
    } = values;

    const editables = {
      menu: JSON.stringify({
        bgColor,
        buttonColor,
        buttonTxtColor,
        font,
        textColor,
        menuItemColor,
        menuTxtColor,
        menuBdrColor,
        menuModalColor,
        menuTxtModalColor,
        menuIconModalColor,
        menuItemModalBtnColor,
        menuModalBtnBdrColor,
        menuTxtModalBtnColor,
      }),
    };

    updateBranding("menu", editables);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
    selectCategory: handleCategoryChange,
    handleMenuItemClick: (menuItem) => {
      setSelectedMenuItem(menuItem);
      setShowModals({ menuItem: true });
    },
    hideMenuItem: () => {
      setShowModals({ menuItem: false });
    },
    handleShowSearch: () => {
      setShowModals({ searchMenu: true });
    },
    hideSearchMenuItem: () => setShowModals({ searchMenu: false }),
  };

  return (
    <>
      <Menus
        actions={actions}
        handleSave={handleUpdate}
        showDashBoard={true}
        isDefaultMoblie={false}
        currentValues={currentBranding}
        {...menuProps}
      />
    </>
  );
};
