/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getZips } from "../Api/ExternalLocationApi";

const useLocationApi = (handleZipChange) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm !== "") {
        searchZipCodes(searchTerm);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchZipCodes = async (searchTerm) => {
    setIsSearching(true);
    try {
      const response = await getZips(searchTerm);
      const { data } = response;
      setSearchResults(data);
      handleZipChange(data);
    } catch (error) {
      console.error(error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return {
    searchTerm,
    isSearching,
    searchResults,
    setSearchTerm,
    setSearchResults,
    handleSearchTermChange,
  };
};

export default useLocationApi;
