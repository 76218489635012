import { useState } from "react";
import { ColoredButton } from "../../Components/Buttons";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import * as S from "./styles";

export const DeleteModal = ({
  showModal,
  setShowModal,
  isLoading,
  itemToDelete,
  handleDelete,
}) => {
  const cancel = () => {
    setShowModal({});
  };
  const handleSubmitDelete = async () => {
    await handleDelete();
    setShowModal({});
  };
  return (
    <>
      <Modals
        size="sm"
        curved
        show={showModal.deleteCategory}
        onHide={() => {
          setShowModal({
            ...showModal,
            deleteCategory: false,
          });
        }}
      >
        {isLoading && (
          <S.ModalLoadHolder>
            <LoadDots />
          </S.ModalLoadHolder>
        )}
        <>
          <S.ModalHeader>
            Delete {itemToDelete?.values?.name} {itemToDelete?.type}
          </S.ModalHeader>

          <S.ButtonHolder center>
            <S.WarningIcon />
          </S.ButtonHolder>
          <S.CenteredTextContent>Are you sure ?</S.CenteredTextContent>
          <S.TextContent center>
            If you delete this {itemToDelete?.type} all its menus and items
            would be deleted also !
          </S.TextContent>

          <S.ButtonHolder>
            <ColoredButton
              variant={"white"}
              text={"Cancel"}
              onClick={() => {
                cancel();
              }}
            />

            <ColoredButton
              variant={"reject"}
              text={"Finish"}
              onClick={() => {
                handleSubmitDelete();
              }}
            />
          </S.ButtonHolder>
          <br />
        </>
      </Modals>
    </>
  );
};
