export const TensitTable = (props) => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="165.000000pt"
        height="165.000000pt"
        viewBox="0 0 165.000000 165.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,165.000000) scale(0.100000,-0.100000)"
          fill={props.fill}
          stroke="none"
        >
          <path
            d="M750 1618 c-35 -10 -64 -28 -57 -35 2 -2 20 4 40 12 45 19 134 19
167 0 32 -18 46 -19 31 -1 -13 15 -87 37 -123 35 -13 0 -39 -5 -58 -11z"
          />
          <path
            d="M754 1589 c-41 -12 -70 -46 -78 -91 -6 -33 -3 -42 30 -78 20 -22 45
-40 55 -40 23 0 25 18 4 27 -25 9 -47 61 -40 92 20 92 143 100 176 12 12 -31
-6 -88 -32 -101 -24 -13 -25 -30 -1 -30 10 0 34 17 54 38 34 34 36 41 31 79
-11 80 -100 121 -199 92z"
          />
          <path
            d="M772 1553 c-21 -8 -43 -61 -36 -87 20 -80 138 -80 158 0 7 28 -16 79
-39 88 -19 7 -64 7 -83 -1z"
          />
          <path
            d="M408 1506 c-21 -8 -49 -21 -62 -30 -33 -21 -79 -82 -74 -97 2 -7 14
4 26 24 28 44 106 97 144 97 15 0 28 5 28 10 0 13 -17 12 -62 -4z"
          />
          <path
            d="M1160 1510 c0 -5 13 -10 29 -10 40 0 121 -57 148 -105 16 -29 23 -34
23 -20 0 11 -14 38 -32 59 -44 53 -168 109 -168 76z"
          />
          <path
            d="M411 1477 c-44 -14 -107 -74 -116 -110 -17 -64 26 -120 100 -131 35
-6 50 -4 56 5 12 20 1 29 -32 29 -44 0 -79 39 -79 89 0 32 6 44 31 65 42 36
82 34 121 -5 28 -27 30 -34 24 -75 -4 -34 -2 -44 9 -44 19 0 22 6 30 66 6 48
4 54 -22 83 -32 35 -72 45 -122 28z"
          />
          <path
            d="M1131 1477 c-13 -6 -33 -24 -44 -39 -17 -23 -19 -34 -11 -80 6 -38
14 -54 26 -56 16 -3 17 2 12 40 -5 37 -2 49 17 71 36 41 87 46 127 12 64 -54
30 -155 -53 -155 -37 0 -44 -10 -20 -30 13 -11 43 -9 97 6 9 3 27 21 39 40 39
63 3 139 -85 180 -58 27 -72 29 -105 11z"
          />
          <path
            d="M375 1415 c-52 -51 -18 -135 54 -135 37 0 81 38 81 69 0 77 -84 118
-135 66z"
          />
          <path
            d="M1170 1433 c-25 -9 -50 -46 -50 -76 0 -69 97 -104 139 -51 53 68 -9
156 -89 127z"
          />
          <path
            d="M715 1340 c-204 -44 -355 -189 -406 -390 -80 -316 146 -622 476 -647
243 -18 482 162 540 409 73 311 -163 619 -484 633 -47 2 -104 0 -126 -5z m225
-18 c147 -39 268 -138 335 -275 156 -321 -54 -692 -416 -733 -195 -22 -411
101 -503 287 -191 388 167 830 584 721z"
          />
          <path
            d="M102 1157 c-50 -53 -78 -168 -51 -207 8 -11 9 -6 5 20 -8 47 17 130
50 170 31 36 27 51 -4 17z"
          />
          <path
            d="M1513 1153 c37 -40 59 -100 59 -162 0 -33 4 -48 9 -40 15 24 9 92
-12 139 -21 48 -56 90 -73 90 -5 0 2 -12 17 -27z"
          />
          <path
            d="M135 1148 c-35 -27 -64 -92 -65 -141 0 -90 70 -138 150 -102 46 21
65 39 55 55 -5 8 -14 6 -31 -4 -61 -41 -144 0 -144 69 0 48 45 95 90 95 35 0
90 -46 90 -75 0 -21 24 -14 28 9 2 9 -8 36 -22 60 -19 32 -33 45 -57 50 -50
10 -65 7 -94 -16z"
          />
          <path
            d="M1393 1163 c-25 -5 -76 -84 -71 -110 4 -22 28 -29 28 -8 0 29 55 75
90 75 40 0 90 -47 90 -85 0 -30 -16 -65 -39 -82 -22 -17 -77 -16 -105 3 -24
15 -44 5 -31 -16 9 -14 81 -50 101 -50 30 0 81 31 93 56 25 55 5 142 -44 191
-33 33 -53 37 -112 26z"
          />
          <path
            d="M135 1085 c-31 -30 -32 -73 -4 -109 43 -55 139 -18 139 54 0 41 -39
80 -80 80 -19 0 -40 -9 -55 -25z"
          />
          <path
            d="M1385 1085 c-50 -49 -15 -135 55 -135 19 0 40 9 55 25 16 15 25 36
25 55 0 19 -9 40 -25 55 -15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z"
          />
          <path
            d="M124 746 c-79 -35 -65 -194 22 -251 20 -13 37 -15 69 -10 38 5 47 12
70 51 14 25 25 52 23 61 -4 22 -28 29 -28 8 0 -9 -13 -30 -29 -46 -37 -37 -79
-38 -120 -3 -64 54 -28 154 56 154 21 0 48 -7 60 -15 14 -10 24 -12 28 -5 9
15 -2 28 -45 50 -46 23 -64 24 -106 6z"
          />
          <path
            d="M1398 742 c-38 -17 -53 -36 -42 -53 3 -5 15 -3 27 6 34 24 94 19 122
-10 54 -53 11 -155 -65 -155 -34 0 -90 46 -90 74 0 9 -5 16 -10 16 -43 0 4
-112 54 -130 49 -17 74 -12 109 24 71 71 74 196 6 232 -35 18 -64 17 -111 -4z"
          />
          <path
            d="M44 685 c-8 -33 2 -99 22 -139 19 -37 54 -76 68 -76 5 0 -2 12 -16
28 -43 45 -61 92 -61 155 0 63 -3 70 -13 32z"
          />
          <path
            d="M1572 653 c0 -65 -17 -110 -60 -155 -14 -16 -21 -28 -16 -28 17 0 52
42 73 90 21 47 27 115 12 139 -6 9 -9 -7 -9 -46z"
          />
          <path
            d="M131 674 c-12 -15 -21 -40 -21 -56 0 -34 39 -78 70 -78 29 0 76 26
84 45 32 85 -78 159 -133 89z"
          />
          <path
            d="M1385 675 c-30 -29 -32 -70 -6 -105 15 -19 29 -25 61 -25 72 0 105
71 59 129 -28 35 -80 36 -114 1z"
          />
          <path
            d="M367 413 c-39 -6 -82 -72 -73 -114 17 -85 138 -159 204 -125 49 25
66 61 56 120 -6 33 -14 52 -25 54 -12 2 -15 -7 -15 -47 1 -66 -25 -96 -85 -96
-52 0 -89 38 -89 91 0 39 36 84 67 84 12 0 9 -5 -12 -16 -36 -19 -45 -31 -45
-67 0 -40 14 -62 50 -77 92 -38 154 98 63 140 -33 15 -47 35 -16 23 13 -5 15
-2 10 11 -4 10 -7 20 -7 22 0 5 -41 3 -83 -3z"
          />
          <path
            d="M1177 406 c-8 -19 1 -26 35 -26 73 0 104 -95 49 -151 -40 -40 -82
-39 -123 2 -28 27 -30 34 -24 75 5 39 3 45 -11 42 -19 -3 -30 -35 -32 -85 -1
-24 8 -41 34 -67 30 -30 39 -34 72 -30 77 10 146 68 160 134 9 47 -34 105 -84
113 -56 10 -70 8 -76 -7z"
          />
          <path
            d="M1145 345 c-57 -56 0 -149 79 -129 34 8 56 40 56 81 0 68 -85 99
-135 48z"
          />
          <path
            d="M270 275 c0 -28 55 -92 98 -114 50 -25 89 -33 97 -21 3 6 -4 10 -17
10 -57 1 -128 51 -161 115 -13 24 -16 26 -17 10z"
          />
          <path
            d="M1338 256 c-22 -45 -90 -94 -140 -102 -21 -4 -38 -10 -38 -15 0 -22
85 7 135 45 36 27 69 79 63 97 -2 6 -11 -5 -20 -25z"
          />
          <path
            d="M709 238 c-36 -39 -39 -46 -33 -83 3 -22 15 -50 26 -62 29 -32 101
-47 159 -33 61 15 87 43 90 98 3 38 -1 47 -39 85 -34 33 -44 39 -54 29 -11
-11 -9 -16 9 -30 49 -37 51 -106 6 -142 -53 -41 -128 -17 -147 46 -8 28 9 75
36 95 20 16 18 39 -4 39 -6 0 -28 -19 -49 -42z"
          />
          <path
            d="M780 237 c-37 -19 -52 -57 -39 -96 25 -70 123 -71 148 -1 12 37 1 72
-31 94 -27 19 -45 20 -78 3z"
          />
          <path
            d="M699 56 c7 -8 34 -20 61 -26 56 -13 150 1 170 26 16 18 11 18 -33 -1
-20 -8 -57 -15 -82 -15 -25 0 -62 7 -82 15 -44 19 -49 19 -34 1z"
          />
        </g>
      </svg>
    </>
  );
};

export const ThreesitTable = (props) => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="115.000000pt"
        height="101.000000pt"
        viewBox="0 0 115.000000 101.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,101.000000) scale(0.100000,-0.100000)"
          fill={props.fill}
          stroke="none"
        >
          <path
            d="M530 921 c-35 -7 -80 -28 -80 -36 0 -3 16 2 36 10 46 19 124 19 171
0 31 -13 34 -13 23 0 -7 8 -28 17 -49 21 -20 3 -43 7 -51 9 -8 1 -31 -1 -50
-4z"
          />
          <path
            d="M531 889 c-72 -15 -101 -47 -101 -112 0 -41 72 -119 91 -100 9 9 5
20 -15 43 -43 52 -33 116 25 145 25 13 37 14 65 5 67 -22 85 -93 38 -150 -19
-21 -24 -34 -16 -42 7 -7 23 2 51 27 32 30 41 44 41 71 0 41 -16 76 -41 92
-29 19 -99 29 -138 21z"
          />
          <path
            d="M515 834 c-30 -32 -32 -73 -6 -105 27 -33 95 -33 122 0 64 78 -48
179 -116 105z"
          />
          <path
            d="M478 620 c-41 -22 -82 -71 -92 -110 -4 -15 1 -10 13 14 28 53 39 65
85 88 31 16 53 19 102 16 35 -2 58 0 51 4 -24 15 -121 8 -159 -12z"
          />
          <path
            d="M692 588 c65 -61 84 -140 53 -215 -26 -62 -69 -96 -140 -114 -24 -6
-26 -7 -7 -8 29 -1 90 30 119 61 30 32 53 90 53 133 0 64 -43 140 -95 168 -11
6 -3 -6 17 -25z"
          />
          <path d="M373 445 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
          <path
            d="M193 386 c-38 -32 -45 -66 -27 -127 18 -60 80 -119 125 -119 51 0 87
28 102 80 15 50 12 70 -9 70 -8 0 -14 -10 -14 -24 0 -13 -12 -35 -26 -50 -57
-57 -154 -18 -154 61 1 66 44 99 116 88 44 -6 56 3 31 23 -7 6 -35 14 -63 17
-44 6 -53 4 -81 -19z"
          />
          <path
            d="M830 397 c-33 -11 -52 -47 -20 -38 58 16 80 13 110 -17 61 -61 26
-154 -57 -150 l-38 1 35 4 c48 6 80 38 80 80 0 43 -38 83 -77 83 -33 0 -43 -7
-66 -40 -23 -33 -21 -62 4 -94 13 -17 15 -24 6 -20 -16 6 -37 46 -37 69 0 8
-7 15 -15 15 -18 0 -19 -28 -4 -79 12 -40 54 -71 96 -71 66 0 135 85 137 167
1 38 -4 49 -32 74 -35 31 -65 35 -122 16z"
          />
          <path
            d="M380 390 c0 -47 83 -126 141 -135 l34 -5 -35 11 c-52 16 -95 53 -119
101 -11 24 -21 36 -21 28z"
          />
          <path
            d="M244 348 c-23 -11 -44 -48 -44 -77 0 -10 9 -30 21 -45 28 -35 80 -36
114 -1 33 33 32 70 -4 106 -31 30 -52 35 -87 17z"
          />
          <path
            d="M127 323 c-11 -17 6 -74 37 -127 25 -43 63 -76 88 -76 7 0 -4 11 -24
25 -47 32 -78 83 -87 143 -4 28 -10 42 -14 35z"
          />
          <path
            d="M1000 300 c0 -47 -24 -93 -71 -139 -24 -22 -38 -41 -31 -41 26 0 92
83 107 133 18 59 19 77 5 77 -5 0 -10 -14 -10 -30z"
          />
        </g>
      </svg>
    </>
  );
};

export const TwoSquareTable = (props) => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="103.000000pt"
        height="45.000000pt"
        viewBox="0 0 103.000000 45.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,45.000000) scale(0.100000,-0.100000)"
          fill={props.fill}
          stroke="none"
        >
          <path
            d="M172 374 c-33 -23 -52 -67 -52 -124 0 -86 42 -140 108 -140 57 0 138
83 103 105 -5 3 -16 -3 -23 -13 -18 -25 -51 -42 -83 -42 -38 0 -85 50 -85 90
0 46 47 90 95 90 27 0 43 -7 64 -30 15 -17 30 -27 34 -24 16 17 4 43 -32 72
-45 36 -92 42 -129 16z"
          />
          <path
            d="M370 365 c-6 -15 -10 -73 -10 -130 0 -93 2 -105 19 -115 11 -5 84
-10 165 -10 172 0 158 -14 154 160 l-3 115 -158 3 -157 3 -10 -26z m320 -115
l0 -120 -155 0 -155 0 0 120 0 120 155 0 155 0 0 -120z"
          />
          <path
            d="M755 350 c-31 -32 -36 -42 -26 -53 10 -13 16 -10 41 14 69 70 180 5
150 -87 -24 -70 -97 -86 -150 -32 -26 25 -34 29 -41 17 -13 -21 -12 -24 31
-63 88 -79 190 -24 190 103 0 48 -14 89 -42 119 -16 17 -33 22 -68 22 -40 0
-51 -5 -85 -40z"
          />
          <path
            d="M930 377 c0 -3 7 -21 15 -40 17 -42 20 -139 5 -168 -7 -12 -7 -19 -1
-19 13 0 31 65 31 113 0 40 -21 100 -39 111 -6 4 -11 5 -11 3z"
          />
          <path
            d="M100 326 c-22 -59 -2 -196 30 -196 6 0 4 9 -5 23 -21 33 -29 106 -16
152 12 45 6 60 -9 21z"
          />
          <path
            d="M175 305 c-32 -31 -32 -74 0 -109 49 -53 135 -17 135 57 0 70 -85
103 -135 52z"
          />
          <path
            d="M787 318 c-43 -33 -40 -100 5 -132 63 -44 142 20 117 94 -6 17 -19
35 -29 40 -26 14 -73 12 -93 -2z"
          />
        </g>
      </svg>
    </>
  );
};

export const FourSitSquare = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="83.000000pt"
      height="95.000000pt"
      viewBox="0 0 83.000000 95.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,95.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M175 921 c-36 -9 -78 -31 -72 -37 2 -2 23 3 47 11 23 8 57 15 75 15
18 0 52 -7 75 -15 51 -18 64 -13 23 8 -36 19 -109 28 -148 18z"
        />
        <path
          d="M537 919 c-21 -5 -48 -16 -60 -25 -25 -18 -29 -19 60 8 42 12 104 5
158 -17 18 -8 18 -7 7 7 -7 9 -36 21 -63 27 -56 12 -50 12 -102 0z"
        />
        <path
          d="M160 884 c-57 -25 -70 -42 -70 -94 0 -38 6 -52 34 -83 18 -20 38 -37
44 -37 22 0 24 23 4 39 -34 26 -44 66 -28 106 16 38 42 55 85 55 79 0 118
-105 58 -156 -28 -24 -29 -44 -3 -44 8 0 30 20 51 45 33 41 36 50 30 84 -14
82 -115 124 -205 85z"
        />
        <path
          d="M525 885 c-53 -19 -75 -48 -75 -99 0 -36 6 -48 40 -81 31 -30 43 -36
52 -27 9 9 6 18 -15 40 -83 87 39 208 124 123 40 -40 39 -82 -2 -123 -23 -24
-27 -33 -17 -39 20 -13 23 -12 62 31 27 30 36 49 36 74 0 45 -40 92 -88 106
-48 13 -68 12 -117 -5z"
        />
        <path
          d="M176 839 c-56 -44 -20 -139 54 -139 19 0 37 10 54 31 29 34 32 50 14
82 -26 50 -77 61 -122 26z"
        />
        <path
          d="M535 835 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 49 -50 135
-15 135 55 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M17 633 c-4 -3 -7 -75 -7 -159 0 -144 1 -154 21 -164 11 -6 25 -9 32
-7 7 3 0 10 -16 16 l-28 11 3 147 3 148 382 3 383 2 0 -155 c0 -117 -3 -155
-12 -155 -7 0 -155 -1 -328 -1 -173 0 -324 -4 -335 -9 -12 -5 117 -9 320 -9
187 0 346 3 353 7 9 7 12 47 10 168 l-3 159 -386 3 c-212 1 -388 -1 -392 -5z"
        />
        <path
          d="M122 237 c-26 -27 -32 -41 -32 -76 0 -23 5 -51 11 -63 24 -44 135
-63 204 -34 37 16 50 34 60 88 6 32 3 40 -30 77 -21 23 -46 41 -56 41 -26 0
-24 -18 5 -41 48 -38 40 -116 -15 -144 -35 -19 -53 -19 -89 0 -54 28 -61 116
-10 147 11 7 20 18 20 25 0 24 -36 13 -68 -20z"
        />
        <path
          d="M481 231 c-43 -53 -43 -106 -1 -146 25 -24 41 -30 90 -33 99 -7 160
34 160 107 -1 56 -83 136 -105 102 -3 -5 3 -16 13 -23 69 -49 45 -151 -39
-164 -28 -5 -41 -1 -67 21 -44 37 -45 94 -2 135 16 15 26 30 23 34 -16 15 -44
3 -72 -33z"
        />
        <path
          d="M170 220 c-33 -33 -30 -85 6 -118 32 -28 66 -28 100 2 37 31 39 90 5
117 -31 25 -86 24 -111 -1z"
        />
        <path
          d="M532 217 c-42 -44 -20 -119 37 -132 51 -11 100 27 101 78 0 66 -92
103 -138 54z"
        />
        <path
          d="M100 61 c0 -15 79 -41 125 -41 46 0 125 26 125 41 0 4 -17 1 -37 -7
-48 -20 -128 -21 -175 -1 -21 9 -38 12 -38 8z"
        />
        <path
          d="M470 60 c0 -18 66 -40 118 -40 52 0 122 23 122 40 0 6 -14 3 -31 -6
-19 -9 -56 -16 -89 -16 -33 0 -70 7 -89 16 -19 10 -31 12 -31 6z"
        />
      </g>
    </svg>
  );
};

export const FourSquareOct = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="94.000000pt"
      height="88.000000pt"
      viewBox="0 0 94.000000 88.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M195 833 c-44 -23 -84 -63 -106 -105 -21 -42 -24 -58 -9 -58 6 0 10
  8 10 18 0 35 57 99 109 123 48 23 65 39 39 39 -7 -1 -26 -8 -43 -17z"
        />
        <path
          d="M710 843 c1 -5 23 -19 51 -32 52 -24 109 -88 109 -122 0 -10 5 -21
  10 -24 6 -3 10 1 10 9 0 29 -22 71 -53 104 -44 46 -128 89 -127 65z"
        />
        <path
          d="M188 791 c-46 -29 -88 -95 -88 -139 0 -12 14 -37 31 -57 30 -34 35
  -36 88 -33 45 2 56 7 59 22 3 16 0 18 -22 12 -51 -13 -116 37 -116 90 0 28 28
  70 55 82 40 18 62 15 97 -17 28 -24 33 -36 32 -71 -1 -33 2 -40 15 -38 11 2
  17 16 19 47 5 63 -3 83 -41 109 -44 29 -74 28 -129 -7z"
        />
        <path
          d="M635 789 c-31 -27 -35 -37 -35 -78 0 -43 12 -71 32 -71 4 0 8 19 8
  43 0 58 34 92 92 92 101 0 120 -143 23 -175 -19 -6 -39 -9 -45 -5 -15 9 -30
  -4 -23 -20 6 -18 93 -21 124 -5 25 14 49 62 49 99 0 37 -53 107 -100 132 -50
  27 -84 24 -125 -12z"
        />
        <path
          d="M180 740 c-26 -26 -27 -91 -2 -112 63 -56 162 7 133 85 -19 49 -94
  64 -131 27z"
        />
        <path
          d="M670 740 c-21 -21 -26 -69 -10 -99 14 -28 61 -44 93 -32 58 22 73
  102 24 136 -31 21 -83 19 -107 -5z"
        />
        <path
          d="M340 580 c-69 -72 -127 -135 -129 -141 -5 -15 249 -269 269 -269 20
  0 270 251 270 270 0 20 -252 270 -271 270 -8 -1 -70 -59 -139 -130z m274 -9
  c118 -118 128 -131 116 -148 -20 -26 -243 -243 -250 -243 -3 0 -64 59 -135
  130 l-130 130 130 130 c71 72 132 130 135 130 3 0 63 -58 134 -129z"
        />
        <path
          d="M129 281 c-16 -20 -29 -47 -29 -61 0 -67 91 -160 156 -160 23 0 48
  10 70 26 31 24 34 31 34 79 0 35 -5 57 -15 65 -18 15 -16 17 -19 -39 -2 -34
  -8 -49 -30 -67 -60 -52 -151 -14 -151 64 0 58 34 92 92 92 44 0 49 5 34 28 -5
  9 -26 12 -60 11 -47 -2 -56 -7 -82 -38z"
        />
        <path
          d="M692 308 c-19 -19 -14 -30 16 -28 48 4 59 0 86 -25 51 -47 22 -137
  -48 -151 -62 -11 -105 27 -106 94 0 40 -1 42 -20 32 -16 -8 -20 -21 -20 -59 0
  -43 4 -52 35 -80 55 -48 116 -39 176 26 59 63 65 114 20 166 -25 28 -37 34
  -78 36 -28 1 -53 -3 -61 -11z"
        />
        <path
          d="M182 250 c-41 -39 -22 -120 32 -134 59 -15 116 46 96 100 -6 15 -18
  33 -28 40 -26 20 -75 17 -100 -6z"
        />
        <path
          d="M672 247 c-43 -46 -19 -121 43 -133 61 -11 109 60 79 117 -23 46 -86
  54 -122 16z"
        />
        <path
          d="M70 201 c0 -59 112 -171 170 -171 24 0 7 18 -22 24 -40 8 -114 83
  -123 125 -6 28 -25 44 -25 22z"
        />
        <path
          d="M863 170 c-24 -54 -64 -93 -115 -115 -42 -17 -48 -25 -20 -25 55 0
  170 121 160 167 -3 13 -10 4 -25 -27z"
        />
      </g>
    </svg>
  );
};

export const PentFiveSit = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="113.000000pt"
      height="118.000000pt"
      viewBox="0 0 113.000000 118.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M535 1110 c-40 -6 -90 -28 -82 -36 2 -2 20 3 40 11 45 19 124 19 157
    0 34 -20 61 -20 33 0 -29 21 -99 32 -148 25z"
        />
        <path
          d="M494 1070 c-35 -14 -64 -59 -64 -99 0 -30 8 -44 39 -75 27 -26 45
    -37 56 -32 15 5 13 11 -14 41 -41 47 -41 86 -2 126 38 38 87 39 125 4 38 -35
    36 -89 -6 -132 -27 -28 -29 -33 -14 -39 12 -5 29 5 57 32 32 31 39 45 39 77 0
    51 -15 76 -55 93 -37 15 -128 18 -161 4z"
        />
        <path
          d="M515 1025 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 49 -50 135
    -15 135 55 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M152 905 c-40 -34 -82 -120 -81 -164 1 -34 2 -33 14 16 15 63 46 114
    88 148 18 14 27 25 20 25 -6 0 -25 -11 -41 -25z"
        />
        <path
          d="M977 895 c44 -38 70 -83 82 -148 7 -35 9 -37 10 -12 2 54 -20 105
    -63 150 -23 25 -49 45 -56 45 -8 0 5 -16 27 -35z"
        />
        <path
          d="M190 894 c-39 -25 -68 -67 -81 -116 -16 -59 -6 -93 36 -121 33 -22
    39 -23 88 -12 42 9 53 16 55 34 3 20 1 21 -17 12 -49 -27 -105 -7 -127 45 -12
    29 -12 39 0 68 17 40 42 56 86 56 40 0 90 -47 90 -84 0 -14 5 -26 10 -26 19 0
    23 30 10 76 -21 73 -92 105 -150 68z"
        />
        <path
          d="M844 891 c-25 -15 -34 -31 -44 -70 -18 -75 7 -103 29 -31 22 75 98
    95 153 40 26 -27 30 -36 25 -68 -8 -47 -47 -82 -92 -82 -19 0 -37 5 -40 10
    -11 18 -26 10 -23 -12 3 -24 30 -35 89 -37 54 -1 99 43 99 96 0 79 -75 173
    -139 173 -14 0 -40 -9 -57 -19z"
        />
        <path
          d="M170 825 c-32 -34 -30 -83 5 -112 32 -28 64 -29 98 -3 33 26 41 63
    23 99 -26 49 -88 57 -126 16z"
        />
        <path
          d="M860 826 c-59 -64 9 -160 90 -126 36 15 53 58 38 100 -19 55 -87 68
    -128 26z"
        />
        <path
          d="M397 803 c-52 -84 -127 -221 -127 -232 0 -7 33 -68 72 -137 l73 -124
    157 0 158 0 19 38 c10 20 44 79 74 130 l55 93 -44 77 c-24 42 -59 103 -77 134
    l-32 58 -152 0 -153 0 -23 -37z m393 -107 l71 -125 -69 -123 -69 -123 -149 -3
    -149 -2 -73 126 -72 126 71 124 72 124 148 0 148 0 71 -124z"
        />
        <path
          d="M136 477 c-28 -27 -36 -42 -36 -68 1 -71 64 -160 126 -175 14 -4 38
    1 61 13 30 16 41 29 52 65 14 45 11 78 -7 78 -5 0 -12 -13 -15 -30 -18 -86
    -133 -105 -172 -29 -22 42 -18 67 15 101 28 28 35 30 81 26 27 -3 52 -1 55 3
    7 13 -37 36 -82 44 -37 6 -45 3 -78 -28z"
        />
        <path
          d="M895 496 c-23 -7 -41 -19 -43 -29 -3 -15 3 -16 43 -10 53 7 87 -7
    104 -44 18 -40 13 -73 -18 -104 -52 -52 -132 -30 -151 41 -6 22 -15 40 -21 40
    -19 0 -21 -30 -5 -78 13 -39 24 -52 53 -66 50 -24 85 -13 132 40 37 43 51 78
    51 134 0 27 -26 58 -67 79 -26 13 -26 13 -78 -3z"
        />
        <path
          d="M170 430 c-19 -19 -25 -43 -21 -80 3 -23 49 -60 75 -60 31 0 72 34
    80 65 18 72 -82 127 -134 75z"
        />
        <path
          d="M860 425 c-50 -53 -6 -145 63 -131 46 9 67 33 67 78 0 74 -80 107
    -130 53z"
        />
        <path
          d="M71 399 c-1 -47 42 -131 84 -163 36 -28 45 -31 45 -17 0 5 -9 13 -20
    16 -29 9 -77 84 -94 146 -13 50 -14 52 -15 18z"
        />
        <path
          d="M1057 389 c-9 -56 -57 -135 -92 -151 -14 -6 -25 -15 -25 -20 0 -5 14
    -1 31 7 53 28 109 137 97 192 -2 10 -7 -3 -11 -28z"
        />
        <path
          d="M463 240 c-74 -85 -17 -180 107 -180 123 0 179 93 108 181 -29 36
    -55 48 -71 33 -3 -4 7 -19 23 -34 42 -39 42 -90 0 -132 -27 -26 -36 -30 -68
    -25 -79 13 -109 99 -52 155 16 16 26 32 23 36 -14 14 -40 2 -70 -34z"
        />
        <path
          d="M511 224 c-27 -34 -27 -74 -1 -102 28 -30 62 -36 98 -18 47 25 57 75
    21 120 -16 20 -29 26 -59 26 -30 0 -43 -6 -59 -26z"
        />
        <path
          d="M452 69 c11 -34 142 -50 202 -25 57 24 48 34 -12 13 -62 -21 -112
    -18 -164 9 -22 11 -29 12 -26 3z"
        />
      </g>
    </svg>
  );
};

export const BigOvalTable = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="141.000000pt"
      height="101.000000pt"
      viewBox="0 0 141.000000 101.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,101.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M415 921 c-22 -10 -49 -28 -59 -40 -25 -28 -15 -27 29 3 42 29 115
46 169 39 27 -4 37 -2 32 6 -12 18 -126 13 -171 -8z"
        />
        <path
          d="M834 928 c-5 -8 2 -9 25 -5 44 9 123 -10 167 -39 20 -14 39 -22 43
-19 8 8 -44 47 -83 62 -41 15 -143 16 -152 1z"
        />
        <path
          d="M423 891 c-57 -26 -77 -59 -69 -114 6 -37 13 -48 53 -76 42 -30 73
-35 73 -12 0 6 -13 17 -30 26 -35 18 -50 43 -50 85 0 35 38 80 67 80 14 0 12
-3 -7 -13 -59 -30 -64 -99 -9 -140 24 -17 31 -18 63 -7 39 13 56 35 56 74 0
39 -11 57 -44 72 -26 11 -27 13 -9 14 28 0 63 -45 63 -82 0 -17 -8 -43 -17
-59 -9 -15 -14 -31 -10 -35 13 -13 35 5 56 47 28 52 22 100 -16 135 -33 30
-109 32 -170 5z"
        />
        <path
          d="M833 898 c-12 -6 -30 -30 -39 -53 -15 -40 -15 -44 5 -81 31 -59 36
-65 53 -59 14 5 13 11 -5 44 -14 25 -18 45 -13 64 7 31 43 67 65 66 11 0 9 -3
-5 -9 -28 -11 -54 -48 -54 -76 0 -29 34 -70 65 -78 29 -7 79 15 89 39 18 49
-9 115 -48 115 -8 0 -18 5 -22 11 -13 21 40 -2 64 -27 41 -46 22 -123 -36
-145 -18 -7 -22 -13 -14 -21 16 -16 55 -2 91 31 57 53 37 137 -43 175 -38 18
-119 20 -153 4z"
        />
        <path
          d="M645 694 c-143 -19 -228 -50 -287 -105 -45 -43 -58 -96 -35 -144 64
-134 393 -196 609 -115 241 90 217 276 -44 344 -58 15 -195 26 -243 20z m230
-34 c262 -67 286 -230 47 -317 -51 -19 -94 -26 -177 -30 -171 -8 -300 25 -373
96 -43 42 -52 70 -40 118 31 118 323 189 543 133z"
        />
        <path
          d="M1157 602 c-53 -54 -51 -97 2 -49 l24 22 -16 -25 c-25 -36 -22 -86 8
-115 13 -14 21 -25 16 -25 -4 0 -18 11 -31 25 -28 30 -40 32 -40 6 0 -10 16
-33 35 -50 28 -24 45 -31 78 -31 54 0 83 21 97 68 31 114 -18 212 -107 212
-20 0 -40 -12 -66 -38z m60 -28 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m103 -75 c0 -51 -64 -111 -102 -96 -7 3 -3 6 9 6 12 1 34 12 49 25
32 27 38 82 14 113 -8 11 -5 8 8 -5 12 -13 22 -32 22 -43z"
        />
        <path
          d="M117 609 c-55 -42 -60 -160 -11 -219 22 -26 33 -30 73 -30 42 0 51 5
86 40 30 31 36 43 27 52 -9 9 -17 5 -36 -15 -24 -26 -71 -45 -83 -33 -4 3 4 6
16 6 30 0 81 52 81 82 0 30 -26 67 -54 78 -16 7 -17 9 -4 9 9 1 29 -11 42 -26
20 -22 28 -25 38 -15 9 9 4 21 -27 52 -33 34 -45 40 -81 40 -27 0 -51 -7 -67
-21z m37 -43 c-51 -23 -61 -90 -19 -131 30 -31 20 -33 -12 -3 -32 30 -32 90 2
123 13 14 32 25 42 25 11 -1 6 -6 -13 -14z"
        />
        <path
          d="M61 593 c-27 -60 -23 -164 8 -213 18 -26 22 -4 6 33 -19 46 -19 118
0 164 17 41 18 43 7 43 -5 0 -14 -12 -21 -27z"
        />
        <path
          d="M1320 613 c1 -5 7 -19 15 -33 19 -33 19 -137 0 -174 -20 -38 -12 -46
11 -11 26 39 30 126 9 180 -9 25 -21 45 -26 45 -5 0 -9 -3 -9 -7z"
        />
        <path
          d="M940 309 c-13 -9 -12 -12 4 -22 45 -28 66 -56 66 -90 0 -33 -31 -87
-50 -87 -5 0 2 11 15 25 52 51 20 135 -51 135 -42 0 -84 -37 -84 -73 0 -34 35
-75 73 -86 18 -5 24 -10 14 -10 -30 -2 -77 28 -87 55 -15 38 -12 63 10 94 15
22 17 29 7 33 -20 8 -32 -3 -56 -51 -19 -37 -20 -48 -11 -76 22 -66 81 -92
163 -71 66 17 107 58 107 106 0 53 -7 66 -56 99 -49 34 -44 33 -64 19z"
        />
        <path
          d="M398 283 c-31 -21 -38 -33 -44 -70 -5 -36 -3 -50 13 -71 44 -60 159
-85 215 -48 17 11 33 34 40 58 10 36 9 44 -12 84 -14 24 -30 46 -37 48 -20 8
-25 -12 -9 -36 9 -12 16 -37 16 -54 0 -32 -32 -84 -52 -84 -6 0 1 11 16 25 55
51 22 135 -54 135 -74 0 -104 -78 -52 -133 24 -26 24 -28 5 -18 -28 14 -43 41
-43 76 0 37 16 62 50 80 17 9 30 20 30 25 0 18 -45 9 -82 -17z m105 -180 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
        />
        <path
          d="M346 116 c3 -7 28 -26 55 -41 57 -30 159 -39 182 -15 10 10 3 11 -35
6 -57 -9 -122 7 -165 40 -36 27 -45 30 -37 10z"
        />
        <path
          d="M1021 104 c-46 -33 -103 -46 -158 -38 -30 4 -44 3 -40 -3 12 -19 77
-22 133 -7 53 13 114 49 114 66 0 13 -12 8 -49 -18z"
        />
      </g>
    </svg>
  );
};

export const BigOval = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="169.000000pt"
      height="109.000000pt"
      viewBox="0 0 169.000000 109.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,109.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M808 1059 c-46 -7 -93 -31 -82 -41 3 -3 20 1 37 10 42 21 133 21 174
  0 35 -18 57 -6 23 12 -33 18 -102 26 -152 19z"
        />
        <path
          d="M774 1021 c-35 -15 -64 -60 -64 -100 0 -30 8 -44 39 -75 27 -26 45
  -37 56 -32 14 5 12 11 -14 35 -24 22 -31 38 -31 66 0 48 44 95 90 95 22 0 41
  -9 61 -29 40 -41 40 -89 -2 -130 -26 -26 -28 -32 -14 -37 11 -5 29 6 56 32 50
  49 54 97 10 146 -26 30 -34 32 -97 35 -38 2 -79 -1 -90 -6z"
        />
        <path
          d="M395 980 c-48 -23 -85 -54 -85 -71 0 -7 12 -1 28 14 39 37 93 57 157
  57 35 0 54 4 50 10 -11 17 -107 11 -150 -10z"
        />
        <path
          d="M795 975 c-52 -51 -15 -135 59 -135 42 0 76 37 76 82 0 39 -40 78
  -80 78 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M1160 991 c-11 -8 0 -11 43 -11 66 0 108 -15 155 -55 18 -15 35 -25
  38 -23 7 7 -47 58 -80 75 -36 19 -136 28 -156 14z"
        />
        <path
          d="M404 951 c-72 -33 -99 -89 -74 -155 17 -46 130 -85 130 -46 0 5 -8
  10 -19 10 -28 0 -71 48 -71 81 0 90 89 131 151 70 32 -33 38 -75 14 -107 -20
  -26 -19 -34 5 -34 15 0 26 13 41 47 24 56 19 84 -21 124 -35 35 -93 39 -156
  10z"
        />
        <path
          d="M1147 950 c-42 -33 -51 -78 -28 -134 15 -33 26 -46 41 -46 24 0 25 8
  5 34 -39 52 9 136 78 136 101 0 120 -151 22 -176 -14 -3 -25 -10 -25 -15 0
  -21 35 -21 76 0 46 22 64 50 64 100 0 87 -162 157 -233 101z"
        />
        <path
          d="M405 905 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 49 -50 135
  -15 135 55 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M1185 904 c-32 -35 -32 -78 0 -109 49 -50 135 -17 135 51 0 75 -86
  111 -135 58z"
        />
        <path
          d="M735 780 c-259 -27 -431 -134 -407 -255 13 -68 138 -150 278 -181 38
  -8 132 -17 209 -20 117 -5 157 -2 244 15 285 56 396 196 255 320 -105 92 -354
  144 -579 121z m325 -29 c125 -28 194 -57 245 -104 109 -99 47 -208 -155 -272
  -354 -111 -842 9 -806 198 14 75 145 151 314 181 115 21 300 19 402 -3z"
        />
        <path
          d="M130 670 c-28 -28 -40 -63 -40 -118 0 -125 100 -184 183 -107 36 34
  46 58 29 69 -5 3 -19 -6 -30 -19 -42 -49 -110 -45 -142 8 -27 43 -25 69 6 106
  36 43 94 44 134 1 15 -16 30 -26 34 -23 15 16 3 44 -31 72 -47 38 -111 43
  -143 11z"
        />
        <path
          d="M1427 659 c-34 -28 -46 -56 -31 -72 4 -3 19 7 34 23 41 43 98 42 135
  -2 22 -26 26 -39 21 -67 -13 -86 -112 -108 -168 -37 -16 20 -17 20 -24 2 -11
  -29 64 -96 108 -96 66 0 108 55 108 139 0 93 -35 140 -105 141 -31 0 -49 -7
  -78 -31z"
        />
        <path
          d="M75 635 c-21 -55 -17 -150 9 -190 24 -36 32 -25 11 15 -21 41 -21
  139 -1 179 18 35 19 41 7 41 -5 0 -17 -20 -26 -45z"
        />
        <path
          d="M1590 677 c0 -2 8 -19 17 -37 21 -44 21 -135 -1 -177 -10 -20 -12
  -33 -6 -33 18 0 40 66 40 119 0 52 -25 131 -41 131 -5 0 -9 -2 -9 -3z"
        />
        <path
          d="M151 613 c-11 -10 -24 -32 -27 -50 -5 -27 -1 -37 24 -63 36 -35 69
  -38 106 -9 55 44 21 139 -51 139 -17 0 -40 -8 -52 -17z"
        />
        <path
          d="M1442 607 c-30 -32 -29 -81 3 -112 56 -57 149 0 129 79 -14 56 -92
  76 -132 33z"
        />
        <path
          d="M388 360 c-48 -26 -68 -56 -68 -104 0 -54 49 -100 126 -117 51 -12
  60 -11 92 7 25 13 41 31 51 57 13 35 12 41 -9 87 -22 46 -37 59 -53 44 -3 -4
  0 -16 8 -28 17 -24 19 -69 5 -97 -13 -23 -61 -49 -90 -49 -36 0 -80 53 -80 95
  0 38 35 85 64 85 21 0 31 21 15 31 -18 11 -18 11 -61 -11z"
        />
        <path
          d="M1250 365 c-10 -12 -6 -17 23 -29 40 -16 57 -41 57 -85 0 -79 -105
  -118 -156 -58 -28 32 -30 66 -9 107 9 18 11 31 5 35 -16 10 -31 -5 -52 -52
  -16 -37 -18 -49 -8 -79 13 -39 60 -74 99 -74 82 0 171 63 171 121 0 49 -20 80
  -65 106 -48 27 -49 27 -65 8z"
        />
        <path
          d="M1223 332 c-26 -4 -63 -47 -63 -73 0 -60 57 -101 110 -79 90 37 49
  168 -47 152z"
        />
        <path
          d="M402 307 c-59 -63 9 -160 88 -127 53 22 66 77 29 124 -27 35 -86 36
  -117 3z"
        />
        <path
          d="M744 253 c-77 -83 -19 -183 106 -183 123 0 179 93 109 181 -28 36
  -56 48 -72 33 -3 -4 7 -19 23 -34 77 -71 -7 -193 -99 -146 -59 31 -70 99 -22
  146 16 16 26 32 23 35 -13 13 -37 2 -68 -32z"
        />
        <path
          d="M792 237 c-55 -58 10 -155 85 -126 85 32 65 149 -26 149 -26 0 -44
  -7 -59 -23z"
        />
        <path
          d="M310 192 c0 -13 41 -48 76 -66 41 -20 106 -30 140 -22 39 10 20 20
  -32 17 -49 -3 -150 38 -161 65 -6 15 -23 19 -23 6z"
        />
        <path
          d="M1370 185 c-26 -31 -117 -67 -164 -64 -56 4 -71 -7 -23 -16 42 -8 89
  1 137 25 37 19 90 70 73 70 -6 0 -16 -7 -23 -15z"
        />
        <path
          d="M726 79 c12 -20 73 -39 124 -39 53 0 120 22 120 39 0 6 -15 4 -35 -5
  -45 -18 -126 -18 -172 1 -42 18 -46 18 -37 4z"
        />
      </g>
    </svg>
  );
};

export const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="63.000000pt"
      height="75.000000pt"
      viewBox="0 0 63.000000 75.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,75.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      ></g>
    </svg>
  );
};

export const Waiter = () => {
  return (
    <svg
      id="Слой_2"
      data-name="Слой 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 101 101"
    >
      <title>Artboard 2 copy</title>
      <path
        d="M87.56,64,67.66,75.79,60.08,71h9.53a5.5,5.5,0,0,0,0-11H53.24a15.19,15.19,0,0,0-5.51,1L35,66.78V65.5A1.5,1.5,0,0,0,33.5,64h-11A1.5,1.5,0,0,0,21,65.5v25A1.5,1.5,0,0,0,22.5,92h11A1.5,1.5,0,0,0,35,90.5V89H66.09a9.32,9.32,0,0,0,5.48-1.77L92.83,72A4.83,4.83,0,0,0,87.56,64ZM32,89H24V67h8ZM91.09,69.61,69.82,84.8A6.35,6.35,0,0,1,66.09,86H35V70.07l13.88-6.27A12.13,12.13,0,0,1,53.24,63H69.61a2.5,2.5,0,0,1,0,5H54.9a1.5,1.5,0,0,0-.8,2.77l12.73,8a1.5,1.5,0,0,0,1.57,0l20.7-12.29a1.83,1.83,0,0,1,2,3.06Z"
        id="id_107"
        fill=" rgb(255, 255, 255)"
      ></path>
      <path
        d="M94.5,52H91A38.55,38.55,0,0,0,57.35,14.82a6.5,6.5,0,1,0-9.69,0A38.54,38.54,0,0,0,14,52H9.5a1.5,1.5,0,0,0,0,3h85a1.5,1.5,0,0,0,0-3ZM52.5,7A3.5,3.5,0,1,1,49,10.5,3.5,3.5,0,0,1,52.5,7Zm0,10.5A35.53,35.53,0,0,1,88,52H17A35.53,35.53,0,0,1,52.5,17.5Z"
        id="id_108"
        fill=" rgb(255, 255, 255)"
      ></path>
      <path
        d="M44.44,24.61a29.46,29.46,0,0,0-18.8,16.17A1.5,1.5,0,1,0,28.37,42,26.47,26.47,0,0,1,45.26,27.5a1.5,1.5,0,1,0-.82-2.89Z"
        id="id_109"
        fill=" rgb(255, 255, 255)"
      ></path>
    </svg>
  );
};
