import styled from "styled-components";
import styles from "../../Assets/styles";

export const Dots = styled.div`
  display: flex;
  * {
    transition: 0.5s;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 5px;
  }
  .dot {
    background-color: ${styles.LightGrey};
  }

  .current {
    background-color: ${styles.OffBlack};
    width: 12px;
    height: 12px;
  }

  .after {
    background-color: ${styles.IconGrey};
  }
`;
