import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  margin-top: 70px;
  box-sizing: border-box;
  padding: 100px 100px;

  padding-top: 200px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  background-color: ${styles.NeutralsWhite};

  @media (max-width: ${1000}px) {
    padding: 0px 30px;
    align-items: center;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InvitationHolder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 300px;
  min-height: 400px;
  border-radius: 10px;
  background-color: ${styles.OffBlack};
  box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: ${1000}px) {
    /* padding: 0px 30px; */
    width: 100%;
    align-items: center;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${styles.NeutralsWhite};
`;

export const SubHeader = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${styles.NeutralsWhite};
`;

export const Note = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${styles.DarkGrey};
  max-width: 700px;
`;

export const ImageHolder = styled.div`
  width: 800px;
  min-height: 80vh;
  height: 80vh;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: ${1000}px) {
    display: none;
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
