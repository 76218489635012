import useIsMobile from "../../../Hooks/isMobile";
import Modals from "../../Modals";
import * as S from "../styles";

import * as MS from "./styles";
import { Menus } from "waitherepackages";
import { useQuickMenu } from "../../../Hooks/useQuickMenu";

import { BsCartFill } from "react-icons/bs";

export const MenuModal = ({
  controllerState = { modals: {} },
  setControllerState,
}) => {
  const {
    modals: { showMenu },
  } = controllerState;

  const isMobile = useIsMobile();

  const navigate = null;
  const menuProps = useQuickMenu();
  const {
    currentCart: { cart },
    handleAddToCart,
    handleCategoryChange,
    setShowModals,
    setSelectedMenuItem,
    handleShowSearch,
    hideSearchMenuItem,
    handleSearchChange,
  } = menuProps;

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const actions = {
    navigate: handleNavigate,
    handleOrder: handleAddToCart,
    selectCategory: handleCategoryChange,
    handleShowSearch: handleShowSearch,
    hideSearchMenuItem: hideSearchMenuItem,
    handleSearchMenuItems: handleSearchChange,
    handleMenuItemClick: (menuItem) => {
      setSelectedMenuItem(menuItem);
      setShowModals({ menuItem: true });
    },
    hideMenuItem: () => {
      setShowModals({ menuItem: false });
    },
  };

  return (
    <Modals
      size="xl"
      curved
      show={showMenu}
      backdrop="static"
      onHide={() => {
        setControllerState({ ...controllerState, modals: { showMenu: false } });
      }}
    >
      <S.ModalHeader>
        <MS.CartButtonHolder
          onClick={() =>
            setControllerState({
              ...controllerState,
              modals: { ...controllerState.modals, showCart: true },
            })
          }
        >
          <BsCartFill />
          <p className="quantity">{cart?.cart_items?.length ?? 0}</p>
        </MS.CartButtonHolder>
      </S.ModalHeader>

      <MS.Holder>
        <Menus
          actions={actions}
          isDefaultMoblie={isMobile}
          currentValues={{}}
          {...menuProps}
        />
      </MS.Holder>
    </Modals>
  );
};
