/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import * as S from "./styles";
import { Slider } from "../../Components/ImageSlider";
import { IoMdAdd } from "react-icons/io";
import { ProgressIndicator } from "../../Components/ProgressIndicator";
import { Collapse, Fade } from "react-bootstrap";
import { ImPencil } from "react-icons/im";
import { TbTrash } from "react-icons/tb";

export const CreateEditMenuItemModal = ({
  showModal,
  setShowModal,
  isLoading,
  selectedMenuItem,
  handleCreateMenuItem,
  handleUpdateMenuItem,
  selectedMenu,
  selectedCategory,
}) => {
  const [values, setValues] = useState({ name: "", description: "" });
  const [newImages, setNewImages] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [addOns, setAddOns] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [deletedImages, setDeletedImages] = useState([]);

  const onChooseFile = (e) => {
    const newFile = e.target.files[0];
    setNewImages([
      ...newImages,
      { file: newFile, image: URL.createObjectURL(newFile) },
    ]);
    setDefaultIndex(allImages.length);
  };

  const validate = () => {
    let valid = values.name && values.description && values.price;
    return valid;
  };

  const handleSubmit = async () => {
    const params = {
      ...values,
      images: newImages,
      menu_id: values.menu_id ?? selectedMenu.id,
      menu_category_id: selectedCategory.id,
      addOns: JSON.stringify(addOns),
    };

    if (showModal.createMenuItem && validate()) {
      await handleCreateMenuItem(params);
      reset();
    }

    if (showModal.editMenuItem) {
      await handleUpdateMenuItem({
        ...params,
        newImages: newImages,
        removedImages: deletedImages,
      });
      reset();
    }
  };

  useEffect(() => {
    if (showModal.editMenuItem) {
      setValues({ ...values, ...selectedMenuItem });
    }
  }, [showModal.editMenuItem]);

  const updateValues = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  const reset = () => {
    setNewImages([]);
    setValues({});
    setCurrentStep(1);
    setShowModal({});
    setAddOns([]);
    setDefaultIndex(0);
    setDeletedImages([]);
  };

  const handleNext = (val) => {
    if (currentStep === 2 && val > 0) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + val);
    }
  };

  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    setAllImages([
      ...(selectedMenuItem?.images?.map((image) => ({
        image: image.url,
        id: image.id,
      })) || []),
      ...newImages,
    ]);
  }, [newImages, selectedMenuItem]);

  useEffect(() => {
    setAllImages(allImages.filter((image) => !deletedImages.includes(image)));
  }, [deletedImages]);

  useEffect(() => {
    setAddOns([
      ...(selectedMenuItem.addOns ? JSON.parse(selectedMenuItem.addOns) : []),
    ]);
  }, [selectedMenuItem]);

  return (
    <Modals
      size="md"
      curved
      show={showModal.createMenuItem || showModal.editMenuItem}
      onHide={() => {
        setShowModal({
          ...showModal,
          createMenuItem: false,
          editMenuItem: false,
        });
        setNewImages([]);
        reset();
      }}
    >
      {isLoading && (
        <S.ModalLoadHolder>
          <LoadDots />
        </S.ModalLoadHolder>
      )}
      <>
        <S.ModalHeader>
          {showModal.editMenuItem ? "Edit Menu" : "Create New Menu Item"}
        </S.ModalHeader>

        <br />
        <S.Fader>
          <S.FadeTest>
            <StepOne
              allImages={allImages}
              onChooseFile={onChooseFile}
              showModal={showModal}
              selectedMenuItem={selectedMenuItem}
              updateValues={updateValues}
              show={currentStep === 1}
              defaultIndex={defaultIndex}
              setDefaultIndex={setDefaultIndex}
              deletedImages={deletedImages}
              setDeletedImages={setDeletedImages}
            />
          </S.FadeTest>

          <S.FadeTest>
            <StepTwo
              addOns={addOns}
              setAddOns={setAddOns}
              showModal={showModal}
              selectedMenuItem={selectedMenuItem}
              updateValues={updateValues}
              show={currentStep === 2}
            />
          </S.FadeTest>
        </S.Fader>

        <br />
        <S.ButtonHolder>
          <S.GreyButton
            onClick={() => currentStep !== 1 && handleNext(-1)}
            hide={currentStep !== 2}
          >
            {"Back"}
          </S.GreyButton>
          <ProgressIndicator quantity={2} current={currentStep} />
          <S.BlackButton onClick={() => handleNext(+1)}>
            {currentStep === 2 ? "Save" : "Next"}
          </S.BlackButton>
        </S.ButtonHolder>

        <br />
      </>
    </Modals>
  );
};

const StepOne = ({
  allImages,
  onChooseFile,
  showModal,
  selectedMenuItem,
  updateValues,
  defaultIndex,
  deletedImages,
  setDeletedImages,
  show,
}) => {
  return (
    <Fade in={show} timeout={5000} unmountOnExit>
      <div>
        <S.ImagesHolder>
          {!allImages.length ? (
            <>
              <h3>
                Click here to add <br />
                new images
              </h3>
              <input type="file" onChange={onChooseFile} />
            </>
          ) : (
            <>
              <Slider
                showDelete
                data={allImages}
                defaultIndex={defaultIndex}
                handleDelete={(img) => {
                  // setDefaultIndex(0);
                  !deletedImages.includes(img) &&
                    setDeletedImages([...deletedImages, img]);
                }}
              />
            </>
          )}
        </S.ImagesHolder>
        {allImages.length > 0 && (
          <S.FlexHolder>
            <S.ImagesText>You've added {allImages.length} images </S.ImagesText>
            <S.AddNewBtn>
              <IoMdAdd />
              <input className="uploader" type="file" onChange={onChooseFile} />
            </S.AddNewBtn>
          </S.FlexHolder>
        )}
        <br />
        <S.StartSubText>Name</S.StartSubText>
        <S.Input
          defaultValue={showModal.editMenuItem && selectedMenuItem?.name}
          placeholder="Eg: X Burger, Pizza De Frango *"
          onChange={(e) => updateValues("name", e.target.value)}
        ></S.Input>
        <br />
        <br />
        <S.StartSubText>Description</S.StartSubText>
        <S.TextInput
          defaultValue={showModal.editMenuItem && selectedMenuItem?.description}
          placeholder="Eg: Freshly caught Atlantic salmon fillet marinated with zesty lemon juice, grilled to perfection..."
          onChange={(e) => updateValues("description", e.target.value)}
        ></S.TextInput>
        <br />
      </div>
    </Fade>
  );
};

const StepTwo = ({
  addOns,
  setAddOns,
  showModal,
  selectedMenuItem,
  updateValues,
  show,
}) => {
  const [showAddOn, setShowAddOn] = useState({ create: false, edit: false });

  const deleteAddOn = (name) => {
    setAddOns(addOns.filter((addOn) => addOn.name !== name));
  };

  return (
    <Fade in={show} timeout={5000} unmountOnExit>
      <div>
        <S.StartSubText>Base Price</S.StartSubText>
        <S.Input
          type="number"
          defaultValue={showModal.editMenuItem && selectedMenuItem?.price}
          placeholder="The base price of the item **"
          onChange={(e) => updateValues("price", e.target.value)}
        ></S.Input>
        <br />
        <br />
        <S.FlexHolder fill>
          <S.StartSubText>Add ons</S.StartSubText>
          <S.AddNewBtnSmll onClick={() => setShowAddOn({ create: true })}>
            <IoMdAdd />
          </S.AddNewBtnSmll>
        </S.FlexHolder>
        <br />
        <S.AddOnHolder>
          {addOns.map((addOn, i) => (
            <S.AddOn key={i}>
              <p>{addOn.name}</p>
              <p> {addOn?.options?.length} Options</p>

              <div className="actions-holder">
                <ImPencil
                  onClick={() => setShowAddOn({ edit: { ...addOn, idx: i } })}
                />
                <TbTrash
                  className="delete-icon"
                  onClick={() => deleteAddOn(addOn.name)}
                />
              </div>
            </S.AddOn>
          ))}

          {!addOns.length && (
            <>
              <p>This menu item does not have any add ons yet </p>
              <p>Add new ones to see it here </p>
            </>
          )}
        </S.AddOnHolder>

        <br />

        <AddOnsModal
          showAddOn={showAddOn}
          setShowAddOn={setShowAddOn}
          addOns={addOns}
          setAddOns={setAddOns}
        />
      </div>
    </Fade>
  );
};

const AddOnsModal = ({ showAddOn, setShowAddOn, addOns, setAddOns }) => {
  const [newAddOn, setNewAddOn] = useState({ options: [] });
  const [showOption, setShowOption] = useState({ add: false, edit: false });
  const [option, setOption] = useState({});

  useEffect(() => {
    if (showAddOn?.edit?.name) {
      setNewAddOn(showAddOn.edit);
    }
  }, [showAddOn?.edit?.name]);

  const updateNewAddOn = (key, value) => {
    setNewAddOn({ ...newAddOn, [key]: value });
  };

  const updateOption = (key, value) => {
    setOption({ ...option, [key]: value });
  };

  const validate = () => {
    const valid =
      !!newAddOn.name &&
      !!newAddOn.minimum &&
      !!newAddOn.maximum &&
      newAddOn.minimum <= newAddOn?.options?.length;

    return valid;
  };

  const reset = () => {
    setNewAddOn({});
    setShowAddOn(false);
  };

  const addNewAddOn = () => {
    if (validate()) {
      setAddOns([...addOns, newAddOn]);
      reset();
    }
  };

  const updateAddOn = (idx) => {
    if (validate()) {
      const updatedAddOn = [...addOns];
      updatedAddOn[showAddOn?.edit?.idx] = newAddOn;
      setAddOns(updatedAddOn);
      reset();
    }
  };

  const addOption = () => {
    const id = Math.random();
    setShowOption(false);
    updateNewAddOn("options", [
      ...(newAddOn?.options || []),
      { ...option, id },
    ]);
    setOption({});
  };

  const editOption = (id) => {
    setShowOption(false);
    updateNewAddOn(
      "options",
      newAddOn?.options.map((prevOption) =>
        prevOption.id === id ? option : prevOption
      )
    );
    setOption({});
  };

  const deleteOption = (id) => {
    updateNewAddOn(
      "options",
      newAddOn?.options.filter(
        (prevOption) => prevOption.id !== id && prevOption
      )
    );
    setOption({});
  };

  return (
    <Modals
      size="sm"
      curved
      show={showAddOn.create || showAddOn?.edit?.name}
      onHide={() => {
        setShowAddOn(false);
        setOption({});
        setShowOption(false);
      }}
    >
      <S.ModalHeader>
        {showAddOn.create ? "New Add On" : "Edit Add On"}
      </S.ModalHeader>
      <S.StartSubText>Name</S.StartSubText>
      <S.Input
        defaultValue={showAddOn?.edit?.name ?? ""}
        placeholder="Eg: Ice, Lemon, Ketchup*"
        onChange={(e) => updateNewAddOn("name", e.target.value)}
      />
      <br />
      <S.StartSubText>Quantity</S.StartSubText>
      <S.FlexHolder>
        <S.Input
          type="number"
          placeholder="Minimum "
          defaultValue={showAddOn?.edit?.minimum ?? null}
          onChange={(e) => updateNewAddOn("minimum", e.target.value)}
        />
        <S.Input
          type="number"
          placeholder="Maximum"
          defaultValue={showAddOn?.edit?.maximum ?? null}
          onChange={(e) => updateNewAddOn("maximum", e.target.value)}
        />
      </S.FlexHolder>
      <hr />
      <S.AddOption>
        <S.StartSubText>Options</S.StartSubText>
        <IoMdAdd onClick={() => setShowOption({ add: true })} />
      </S.AddOption>
      <Collapse in={showOption.add || showOption.edit}>
        <S.CollapseHolder>
          <S.FlexHolder>
            <S.Input
              className="category_name"
              placeholder="Name "
              value={option?.name ?? ""}
              onChange={(e) => updateOption("name", e.target.value)}
            />
            <S.Input
              type="number"
              placeholder="Preco"
              value={option?.price ?? ""}
              onChange={(e) => updateOption("price", e.target.value)}
            />
          </S.FlexHolder>
          <S.ButtonHolder>
            <S.GreyButton
              className="small-btn"
              onClick={() => {
                setOption({});
                setShowOption({});
              }}
            >
              {"Cancel"}
            </S.GreyButton>

            <S.BlackButton
              className="small-btn"
              onClick={() => {
                showOption.edit ? editOption(option.id) : addOption();
              }}
            >
              {showOption.edit ? "Save" : "Add"}
            </S.BlackButton>
          </S.ButtonHolder>
        </S.CollapseHolder>
      </Collapse>

      <S.OptionsHolder>
        {newAddOn?.options?.map((option, i) => (
          <S.AddOn key={i} small>
            <p className="option_name">{option.name}</p>
            <p>R$ {option.price}</p>

            <div className="actions-holder">
              <ImPencil
                onClick={() => {
                  setOption(option);
                  setShowOption({ edit: true });
                }}
              />
              <TbTrash
                className="delete-icon"
                onClick={() => deleteOption(option.id)}
              />
            </div>
          </S.AddOn>
        ))}
      </S.OptionsHolder>

      {/* <S.StartSubText>Price</S.StartSubText>
      <S.Input
        type="number"
        defaultValue={showAddOn?.edit?.price ?? null}
        placeholder="The price of one portion"
        onChange={(e) => updateNewAddOn("price", e.target.value)}
      />
      <br /> */}
      <S.ButtonHolder end>
        <S.BlackButton
          onClick={() => (showAddOn.create ? addNewAddOn() : updateAddOn())}
        >
          {showAddOn.create ? "Add" : "Save"}
        </S.BlackButton>
      </S.ButtonHolder>
    </Modals>
  );
};
