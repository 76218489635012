import { useState } from "react";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import * as S from "./styles";

export const CreateEditCategoryModal = ({
  showModal,
  setShowModal,
  updateCategory,
  createCatergory,
  isLoading,
  selectedCategory,
}) => {
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    if (showModal.editCategory) {
      await updateCategory({ menu_id: selectedCategory.id, name: name });
    } else {
      await createCatergory(name);
    }

    setShowModal({ createCategory: false });
  };
  return (
    <>
      <Modals
        size="sm"
        curved
        show={showModal.createCategory || showModal.editCategory}
        onHide={() => {
          setShowModal({
            ...showModal,
            createCategory: false,
            editCategory: false,
          });
        }}
        // dark
      >
        {isLoading && (
          <S.ModalLoadHolder>
            <LoadDots />
          </S.ModalLoadHolder>
        )}
        <>
          <S.ModalHeader>
            {showModal.editCategory ? "Edit Category" : "Create new category"}
          </S.ModalHeader>
          <S.StartSubText>Name</S.StartSubText>
          <S.Input
            defaultValue={showModal.editCategory && selectedCategory?.name}
            placeholder="Eg: Entrée, Main course *"
            onChange={(e) => setName(e.target.value)}
          ></S.Input>
          <br />
          <br />
          <S.StartSubText>
            Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </S.StartSubText>
          <br />
          <S.BlackButton onClick={handleSubmit}>
            {showModal.editCategory ? "Save" : "Create"}
          </S.BlackButton>
          <br />{" "}
        </>
      </Modals>
    </>
  );
};
