import * as S from "./styles";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import useIsMobile from "../../Hooks/isMobile";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

export const NavSlider = ({ options, selected, setSelected, isImage }) => {
  const optionsRef = useRef();
  const [showButton, setShowButton] = useState({ right: false, left: false });

  const isOverFlow = useCallback(() => {
    setShowButton({
      right:
        Math.ceil(
          optionsRef.current.scrollLeft + optionsRef?.current?.offsetWidth
        ) < optionsRef?.current?.scrollWidth,
    });
  }, []);

  useEffect(() => {
    isOverFlow();
  }, []);

  const scroll = (scrollOffset) => {
    optionsRef.current.scrollLeft += scrollOffset;
    setShowButton({
      right:
        Math.ceil(
          optionsRef.current.scrollLeft + optionsRef?.current?.offsetWidth
        ) <
          optionsRef?.current?.scrollWidth - 200 || scrollOffset < 0,
      left:
        Math.ceil(
          optionsRef.current.scrollLeft + optionsRef?.current?.offsetWidth
        ) >
          optionsRef?.current?.offsetWidth + 200 || scrollOffset > 0,
    });
  };

  return (
    <S.Holder>
      {!useIsMobile() && (
        <S.NavButton
          show={showButton.left}
          // show
          onClick={() => showButton.left && scroll(-200)}
        >
          <HiOutlineChevronLeft />
        </S.NavButton>
      )}

      <S.OptionsHolder ref={optionsRef}>
        {options.map(
          (option) =>
            !isImage && (
              <S.Option
                key={option.id}
                onClick={() => setSelected(option)}
                selected={selected?.id === option?.id}
                isImage={isImage}
              >
                {!isImage && option.name}
                {/* {isImage && <S.ImageOption src={option.image}></S.ImageOption>} */}
              </S.Option>
            )
        )}
      </S.OptionsHolder>

      {!useIsMobile() && (
        <S.NavButton
          show={showButton.right}
          onClick={() => showButton.right && scroll(200)}
        >
          <HiOutlineChevronRight />
        </S.NavButton>
      )}
    </S.Holder>
  );
};
