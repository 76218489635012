import { LoadDots } from "../../../Components/Loader";
import Modals from "../../../Components/Modals";
import { TbEditCircle } from "react-icons/tb";
import { HiOutlineTrash } from "react-icons/hi2";
import { MenuItemModal } from "waitherepackages";
import * as S from "./styles";
import { ConfirmDelete } from "./ConfirmDelete";
import { BsFillPrinterFill } from "react-icons/bs";
import { MoveOptions, PrinterOptions } from "..";
import { useRef, useState } from "react";
import useOutsideClick from "../../../Hooks/UseOutsideClick";

export const OrderModal = ({
  show,
  onHide,
  current,
  loading,
  orderModals,
  setCurrent,
  setOrderModals,
  statuses,
  printerRooms,
  handlePrintOrder,
  editOrderItem,
  handleDeleteItem,
  handleEditStatus,
  handleEditPrinterRooms,
}) => {
  const ref = useRef();

  const { order } = current;
  const order_status = statuses.find((status) => status.key === order?.status);

  const timestamp = new Date(order?.created_at);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = () => timestamp.toLocaleDateString("pt-br", options);

  const [expandOpts, setExpandOpts] = useState({ status: false });

  useOutsideClick(ref, () => {
    setExpandOpts({});
  });

  return (
    <Modals
      size="md"
      backdrop="normal"
      curved
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      {loading.showOrder && (
        <S.ModalContainer>
          <LoadDots />
        </S.ModalContainer>
      )}
      {!loading.showOrder && (
        <>
          <S.ModalHeader>Order #{order?.display_id}</S.ModalHeader>

          <S.AutoModalContainer>
            <div className="order_details_holder">
              <div className="top_sec">
                <div className="date">
                  Data de Pedido: <span>{formattedDate()}</span>
                </div>

                <div className="flex_printer_holder" ref={ref}>
                  <div>
                    <BsFillPrinterFill
                      className="printer_svg"
                      onClick={() =>
                        setExpandOpts({ print: !expandOpts.print })
                      }
                    />
                    <PrinterOptions
                      order={order}
                      printerRooms={printerRooms}
                      expandOpts={expandOpts}
                      handlePrintOrder={handlePrintOrder}
                      handleEditPrinterRooms={handleEditPrinterRooms}
                    />
                  </div>
                  <div
                    className="status"
                    onClick={() =>
                      setExpandOpts({ status: !expandOpts.status })
                    }
                    style={{ background: order_status?.color }}
                  >
                    Status: <span>{order_status?.name}</span>{" "}
                    <TbEditCircle className="edit_svg" />
                    <MoveOptions
                      order={order}
                      statuses={statuses}
                      expandOpts={expandOpts}
                      handleEditStatus={handleEditStatus}
                    />
                  </div>
                </div>
              </div>

              <div className="top_sec">
                <div className="date">
                  Para: <span>{order.ordered_for}</span>
                </div>

                <div className="date">
                  Mesa: <span>{order?.table_no}</span>
                </div>
              </div>
            </div>

            <br />

            <div className="items_holder">
              {order?.order_items?.map((item) => (
                <OrderItemCard
                  key={item.id}
                  item={item}
                  current={current}
                  setCurrent={setCurrent}
                  loading={loading}
                  orderModals={orderModals}
                  setOrderModals={setOrderModals}
                  handleDeleteItem={handleDeleteItem}
                  editOrderItem={(order) =>
                    editOrderItem({ ...order, id: item.id })
                  }
                />
              ))}
            </div>

            <div className="order_total">
              <p>Total</p>
              <p>R${order.total}</p>
            </div>
          </S.AutoModalContainer>
        </>
      )}
    </Modals>
  );
};

const OrderItemCard = ({
  item,
  current,
  loading,
  orderModals,
  setCurrent,
  setOrderModals,
  editOrderItem,
  handleDeleteItem,
}) => {
  const addonTypes = item?.add_ons ? Object.keys(item?.add_ons) : [];

  // Ensure Additinal Info appears last in the list
  addonTypes?.sort((a, b) =>
    a === "Informação Adicional" ? 1 : b === "Informação Adicional" ? -1 : 0
  );

  const handleMenuItem = (item) => {
    setOrderModals({ ...orderModals, item: !orderModals.item, delete: false });
    setCurrent({ ...current, order_item: item });
  };

  const handleMenuItemDelete = (item) => {
    setOrderModals({ ...orderModals, delete: !orderModals.item });
    setCurrent({ ...current, order_item: item });
  };

  const disableActions = current.order.status !== "pending";

  return (
    <S.OrderItemCard>
      <div className="item_top_sec">
        <img className="item_image" src={item?.image} alt={item?.name} />

        <div className="item_top_details">
          <div className="sub_sec">
            <p className="item_name">{item?.name}</p>
            <p>R${item?.price}</p>
          </div>

          <div className="sub_sec">
            <p>Quantidade: {item?.quantity} </p>
            <div
              className="actions_holder"
              style={{ opacity: disableActions ? 0.5 : 1 }}
            >
              <TbEditCircle
                className="edit_svg"
                onClick={() => !disableActions && handleMenuItem(item)}
              />
              <HiOutlineTrash
                className="delete_svg"
                onClick={() => !disableActions && handleMenuItemDelete(item)}
              />
            </div>
          </div>
        </div>
      </div>

      {addonTypes.length > 0 && (
        <div className="add_ons">
          {addonTypes?.map(
            (addonType) =>
              addonType && (
                <div className="add_on" key={addonType}>
                  <p className="add_on_name">{addonType}</p>
                  {item?.add_ons?.[addonType]?.map((addOn) => (
                    <div className="add_on_holder" key={addOn.id}>
                      <p>• {addOn.name}</p>
                      <p>R${addOn.price}</p>
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
      )}

      <div className="item_total_holder">
        <p>Item Total</p>
        <p>R${item?.total}</p>
      </div>

      {orderModals.item && current?.order_item?.id === item.id && (
        <MenuItemModal
          font={"arial"}
          loading={loading}
          show={orderModals.item}
          onHide={handleMenuItem}
          handleOrder={editOrderItem}
          selectedMenuItem={item.menu_item}
          currentOrder={item}
        />
      )}

      {orderModals.delete && current?.order_item?.id === item.id && (
        <ConfirmDelete
          show={orderModals.delete}
          onHide={handleMenuItemDelete}
          handleDeleteItem={() => handleDeleteItem(current?.order_item?.id)}
        />
      )}
    </S.OrderItemCard>
  );
};
