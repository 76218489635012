import { bigpoweredby, waithere } from "../../Assets/images/images";
import * as S from "./styles";
import { TbPaperBag } from "react-icons/tb";
import { Collapse } from "react-bootstrap";
import { IoIosMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useRef } from "react";
import { useState } from "react";
import useIsMobile from "../../Hooks/isMobile";
import { AcountRoutes, MenuRoutes } from "./NavRoutes";
import useProfile from "../../Hooks/useProfile";
import { getUserOrg } from "../../Utils/localStorage";

const Logo = () => {
  const { organization } = getUserOrg();

  return (
    <S.Logo>
      <img
        className="org-logo"
        src={organization?.logo?.url}
        alt={organization?.name}
      />
      <div className="waithere-logo-holder">
        <img className="powered-by" src={bigpoweredby} alt="logo" />
        <img className="waithere-logo" src={waithere} alt="logo" />
      </div>
    </S.Logo>
  );
};

export const TopNav = () => {
  const holderRef = useRef();
  const { avatar } = useProfile();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.Holder ref={holderRef}>
      {!useIsMobile() ? (
        <>
          <S.Sections>
            <Logo />

            <MenuRoutes setIsExpanded={setIsExpanded} />
          </S.Sections>
          <S.Sections>
            {/* <S.SearchBarHolder>
              <IconInput
                placeHolder={"Search By Restaurant or meal"}
                leftIcon={<FiSearch />}
              />
            </S.SearchBarHolder> */}
            {/* <S.CartButton>
              <TbPaperBag />
            </S.CartButton> */}
            <S.UserImage
              onMouseEnter={() => setIsExpanded(true)}
              onMouseLeave={() => setIsExpanded(false)}
              image={`${
                avatar?.new ? URL.createObjectURL(avatar?.new) : avatar?.current
              }`}
            >
              <Collapse in={isExpanded}>
                <S.WebCollapser>
                  <S.UserSection>
                    <AcountRoutes avatar={avatar} />
                  </S.UserSection>
                </S.WebCollapser>
              </Collapse>
            </S.UserImage>
          </S.Sections>
        </>
      ) : (
        <>
          <S.Sections>
            <S.CartButton>
              <div onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <IoClose /> : <IoIosMenu />}
              </div>

              <Collapse in={isExpanded}>
                <S.Collapser>
                  {/* <S.SearchBarHolder>
                    <IconInput
                      placeHolder={"Search By Restaurant or meal"}
                      leftIcon={<FiSearch />}
                    />
                  </S.SearchBarHolder> */}

                  <MenuRoutes setIsExpanded={setIsExpanded} />
                  <hr />
                  <S.UserSection>
                    <AcountRoutes avatar={avatar} />
                  </S.UserSection>
                </S.Collapser>
              </Collapse>
            </S.CartButton>
          </S.Sections>

          <S.Sections>
            <Logo />
          </S.Sections>

          <S.Sections>
            <S.CartButton>
              <TbPaperBag />
            </S.CartButton>
          </S.Sections>
        </>
      )}
    </S.Holder>
  );
};
