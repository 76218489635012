import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AcceptInvitation } from "../Features/AcceptInvitation";
import { Builder } from "../Features/Builder";
import { Category } from "../Features/Categories";
import { Home } from "../Features/Home";
import { Menu } from "../Features/Menu";
import { Team } from "../Features/Team";
// import { CreateOrganization } from "../Users/CreateOrganization";
import { Login } from "../Users/Login";
import { SignUp } from "../Users/SignUp/signUp";
import { getUserOrg } from "../Utils/localStorage";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import { Profile } from "../Features/Profile";
import { CreateEditOrganization } from "../Users/CreateOrganization/CreateEditOrganization";
import { hasPermission } from "../Utils/helpers";
import { EDIT_ORG } from "../Utils/GlobalVariables";
import { MyPages } from "../Features/MyPages/pages";
import { Tables } from "../Features/Tables";
import { Orders } from "../Features/Orders";

export const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/builder"
          element={
            <PrivateRoute>
              <Builder />
            </PrivateRoute>
          }
        />
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route
          path="/log-in"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/team"
          element={
            <PrivateRoute>
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path="/category/:category"
          element={
            <PrivateRoute>
              <Category />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-organization"
          element={
            // change to private or show login pop up
            <PublicRoute>
              <CreateEditOrganization />
            </PublicRoute>
          }
        />
        <Route
          path="/edit-organization"
          element={
            <PrivateRoute
              allowIf={() =>
                hasPermission(getUserOrg()?.role?.user_type, EDIT_ORG)
              }
            >
              <CreateEditOrganization edit />
            </PrivateRoute>
          }
        />
        <Route
          path="/accept_invitation/:id"
          element={
            <PublicRoute>
              <AcceptInvitation />
            </PublicRoute>
          }
        />
        <Route
          path="/menu"
          element={
            <PrivateRoute>
              <Menu />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/pages/:name"
          element={
            <PrivateRoute>
              <MyPages />
            </PrivateRoute>
          }
        />
        <Route
          path="/tables"
          element={
            <PrivateRoute>
              <Tables />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};
