import {
  restaurant1,
  restaurant2,
  restaurant3,
  restaurant4,
  waithere,
} from "../../Assets/images/images";
import { FilledButton, UnFilledButton } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs";
import * as S from "./styles";
import { FcGoogle } from "react-icons/fc";
import { Slider } from "../../Components/ImageSlider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userSigUp } from "../../Api/UserApi";
import { userContext } from "../../Context/userContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short."),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const SignUp = () => {
  const [userState, setUserState] = useContext(userContext);
  const [pageState, setPageState] = useState({});

  const navigate = useNavigate();

  const sliderData = [
    { image: restaurant1, text: "Testing " },
    { image: restaurant2, text: "Testing " },
    { image: restaurant3 },
    { image: restaurant4 },
  ];

  const signUp = async (values) => {
    setPageState({ ...pageState, isLoading: { signUp: true } });
    const { data } = await userSigUp(values);
    try {
      if (data.status?.code === 200) {
        setUserState({ ...userState, user: data.status.data });
        navigate("/log-in");
        setPageState({ ...pageState, isLoading: { signUp: false } });
      }
    } catch {
      setPageState({ ...pageState, isLoading: { signUp: false } });
    }
  };

  const formik = useFormik({
    initialValues: { name: "", email: "", password: "", confirm_password: "" },
    validationSchema: SignUpSchema,
    onSubmit: (values, formikBag) => {
      signUp(values);
    },
    enableReinitialize: true,
  });

  return (
    <S.Container>
      <S.MidContainer>
        <S.leftSection>
          <S.Company>
            <img src={waithere} alt="logo" />
          </S.Company>

          <S.BoldText>Create an account</S.BoldText>
          <S.Text>Let's get started with your 30 day free trail.</S.Text>
          <br />
          <S.InputHolder>
            <Input
              placeHolder={"Name"}
              errors={formik.errors.name}
              onChange={(value) => formik.setFieldValue("name", value)}
            />
            <Input
              placeHolder={"Email"}
              errors={formik.errors.email}
              onChange={(value) => formik.setFieldValue("email", value)}
            />
            <Input
              type={"password"}
              placeHolder={"Password"}
              errors={formik.errors.password}
              onChange={(value) => formik.setFieldValue("password", value)}
            />
            <Input
              type={"password"}
              placeHolder={"Confirm Password"}
              errors={formik.errors.confirm_password}
              onChange={(value) =>
                formik.setFieldValue("confirm_password", value)
              }
            />
          </S.InputHolder>
          <br />
          <FilledButton
            text={"Create account "}
            variant={"signup"}
            onClick={() => formik.handleSubmit()}
            isLoading={pageState?.isLoading?.signUp}
          />
          <br />
          <UnFilledButton
            text={"Sign up with Google "}
            leftIcon={<FcGoogle />}
            variant={"signup"}
          />
          <S.LoginText>
            Already have an account?{" "}
            <span onClick={() => navigate("/log-in")}>Log in</span>
          </S.LoginText>
        </S.leftSection>

        <S.rightSection image={restaurant2}>
          <Slider data={sliderData} />
        </S.rightSection>
      </S.MidContainer>
    </S.Container>
  );
};
