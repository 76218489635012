import { Toaster } from "react-hot-toast";
import { UniversalModals } from "./Components/UniversalModals";
import BuilderStore from "./Context/builderCtx";
import ControllerStore from "./Context/controllerCtx";
import UserStore from "./Context/userContext";
import { Pages } from "./Routes/Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import TableStore from "./Context/tableContext";
import PrinterStore from "./Context/printerContext";
import OrderStore from "./Context/orderContext";

const queryClient = new QueryClient();

export const App = () => {
  const showToaster = !window.location.pathname.includes("/pages");
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserStore>
          <BuilderStore>
            <ControllerStore>
              <PrinterStore>
                <OrderStore>
                  <TableStore>
                    <Pages />
                    <UniversalModals />
                    {showToaster && <Toaster />}
                  </TableStore>
                </OrderStore>
              </PrinterStore>
            </ControllerStore>
          </BuilderStore>
        </UserStore>
      </QueryClientProvider>
    </>
  );
};

export default App;
