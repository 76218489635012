import styled from "styled-components";
import styles from "../../Assets/styles";


export const Holder = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */

  img{
    margin: 0;
    padding: 0;
    width: 80%;
    max-width: 350px;
    margin-left: 50px;
  }

  p{
    margin: 0;
    padding: 0;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  div{
    cursor: pointer;
    margin-top: 10px;
    border: 2px solid ${styles.OffBlack};
    padding: 5px 20px;
    border-radius: 10px;
    font-weight: 600;
    svg{
        width: 25px;
        height: 25px;
    }
  }
`;