import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: 1px solid ${styles.MidGrey};

  input {
    width: 100%;
    border: 0;
    color: ${styles.Black};
    background: transparent !important;

    :focus {
      border: 0;
      outline: 0;
    }

    ::placeholder {
      color: ${styles.Black};
    }
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
`;

export const Error = styled.div`
  font-weight: 500;
  color: ${styles.Error};
`;

export const IconInputHolder = styled(Holder)`
  border-radius: 4px;
  border: 0;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  gap: 10px;
  svg {
    color: ${styles.OffBlack};
    height: 25px;
    width: 25px;
  }
  input {
    ::placeholder {
      color: ${styles.MidGrey} !important;
    }
  }
`;

export const BorderHolder = styled(Holder)`
  border-radius: 4px;
  border: 0;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  gap: 10px;
  border: 1px solid grey;
  svg {
    color: ${styles.OffBlack};
    height: 25px;
    width: 25px;
  }
  input {
    color: inherit;
    ::placeholder {
      color: ${styles.MidGrey} !important;
    }
  }
`;

export const CheckBox = styled.div`
  position: relative;
  max-height: 18px;
  height: 18px;
  width: 18px;
  transition: 0.3s;

  label {
    transition: 0.3s;

    background-color: ${styles.NeutralsWhite};
    border: 1px solid ${styles.DarkGrey};
    border-radius: 5px;
    cursor: pointer;
    height: 18px;
    max-height: 18px;
    min-height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
    max-width: 18px;
    min-width: 18px;
  }

  label:after {
    transition: 0.3s;
    border: 2px solid ${styles.NeutralsWhite};
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 10.5px;
  }

  input {
    visibility: hidden;
  }

  input[type="checkbox"]:checked + label {
    background-color: ${(props) =>
      props.customStyles?.color ? props.customStyles?.color : styles.OffBlack};

    border-color: ${(props) =>
      props.customStyles?.color ? props.customStyles?.color : styles.OffBlack};
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;
