/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { SiCodechef } from "react-icons/si";
import { ColoredButton } from "../../Components/Buttons";
import { LoadDots } from "../../Components/Loader";
import { TopNav } from "../../Components/TopNav";
import * as S from "./styles";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { Waiter } from "../../Assets/icons/icons";
import { FiChevronDown } from "react-icons/fi";
import { Collapse } from "react-bootstrap";
import useOrganization from "../../Hooks/useOrganization";
import { AddMemberModal } from "./AddMemberModal";
import { hasPermission } from "../../Utils/helpers";
import * as V from "../../Utils/GlobalVariables";
import { getUserOrg } from "../../Utils/localStorage";
import { toast } from "react-hot-toast";
import { EditMember } from "./EditMember";
import { DeleteMember } from "./DeleteMemberModal";

export const Team = () => {
  const {
    fetchOragnizationTeam,
    sendTeamOrganization,
    fetchAllRoles,
    organizationTeam,
    roles = [],
    deleteMember,
    updateOragnizationsTeam,
  } = useOrganization();

  const [pageState, setPageState] = useState({
    isLoading: true,
  });
  const [dropDowns, setDropDowns] = useState({});
  const [showModal, setShowModal] = useState({});

  const getTeams = async () => {
    setPageState({ ...pageState, isLoading: true });
    try {
      await fetchOragnizationTeam();
      await fetchAllRoles();
      setPageState({ ...pageState, isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  const permisionIcon = (role) => {
    if (role === V.WAITER) return <Waiter />;
    if (role === V.ADMIN) return <MdOutlineAdminPanelSettings />;
    if (role === V.CHEF) return <SiCodechef />;
  };

  const hadleInviteMember = () => {
    const {
      role: { user_type },
    } = getUserOrg();

    if (hasPermission(user_type, V.INVITE_MEMBER)) {
      setShowModal({ ...showModal, addMember: true });
    } else {
      handleNoPermission();
    }
  };

  const handleEditMember = (teamMate) => {
    const {
      role: { user_type },
    } = getUserOrg();

    setDropDowns({});
    if (hasPermission(user_type, V.EDIT_MEMBER)) {
      setShowModal({ editMember: true });
      setPageState({
        ...pageState,
        memberToEdit: teamMate,
      });
    } else {
      handleNoPermission();
    }
  };

  const handleRemoveMember = (teamMate) => {
    const {
      role: { user_type },
    } = getUserOrg();

    setDropDowns({});
    if (hasPermission(user_type, V.DELETE_MEMBER)) {
      setShowModal({ deleteMember: true });
      setPageState({
        ...pageState,
        memberToDelete: teamMate,
      });
    } else handleNoPermission();
  };

  const handleNoPermission = () => {
    toast.dismiss();
    toast.error(`You don't have the permission to do this `, {
      duration: 4000,
      position: "top-center",
      style: {
        marginTop: "60px",
      },
    });
  };

  return (
    <>
      <TopNav />
      {pageState.isLoading && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!pageState.isLoading && (
        <>
          <S.Holder>
            <S.TeamOverView>
              <S.BoldWhiteText>Members</S.BoldWhiteText>
              <div>
                <S.SubText>
                  Total members from this billing period Jan 20 to Feb 20.
                </S.SubText>
                <S.BoldWhiteText>15</S.BoldWhiteText>
              </div>

              <div>
                <S.SubText>Team members.</S.SubText>
                <S.BoldWhiteText> {organizationTeam?.length}</S.BoldWhiteText>
              </div>
            </S.TeamOverView>

            <S.AllMembers>
              <S.FlexHolder>
                <S.BoldWhiteText>Team Members</S.BoldWhiteText>
                <ColoredButton
                  variant={"addMember"}
                  text={"Add Members"}
                  onClick={hadleInviteMember}
                />
              </S.FlexHolder>

              <S.SubText>
                Team members have accounts on Waithere and can change their
                settings, depending on their permissions. You can have up to 15
                seats on your plan.
              </S.SubText>

              <hr />

              <S.AllMembersHolder>
                {organizationTeam?.map((teamMate, i) => (
                  <S.Rows key={i}>
                    <S.NameHolder xs="12" lg="5">
                      <S.UserImage />
                      <div>
                        <p>{teamMate.user?.name}</p>
                        <S.SubText>{teamMate.user?.email}</S.SubText>
                      </div>
                    </S.NameHolder>
                    <S.Role xs="6" lg="5">
                      {teamMate?.role}
                      {permisionIcon(teamMate?.role)}
                    </S.Role>
                    <S.OptionHolder xs="6" lg="2">
                      <S.OptionButton
                        onClick={() => setDropDowns({ [i]: !dropDowns[i] })}
                      >
                        Options <FiChevronDown />
                      </S.OptionButton>

                      <Collapse in={dropDowns[i]}>
                        <S.Collapser hasDelete>
                          <div
                            onClick={() => {
                              handleEditMember(teamMate);
                            }}
                          >
                            Edit member
                          </div>
                          <div onClick={() => handleRemoveMember(teamMate)}>
                            Remove from team
                          </div>
                        </S.Collapser>
                      </Collapse>
                    </S.OptionHolder>
                  </S.Rows>
                ))}
              </S.AllMembersHolder>
            </S.AllMembers>
          </S.Holder>

          <AddMemberModal
            showModal={showModal}
            setShowModal={setShowModal}
            permisionIcon={permisionIcon}
            sendTeamOrganization={sendTeamOrganization}
            roles={roles}
          />

          {showModal.editMember && (
            <EditMember
              memberToEdit={pageState.memberToEdit}
              showModal={showModal}
              setShowModal={setShowModal}
              permisionIcon={permisionIcon}
              roles={roles}
              updateOragnizationsTeam={updateOragnizationsTeam}
            />
          )}

          {showModal.deleteMember && (
            <DeleteMember
              memberToDelete={pageState.memberToDelete}
              showModal={showModal}
              setShowModal={setShowModal}
              permisionIcon={permisionIcon}
              roles={roles}
              deleteMember={deleteMember}
            />
          )}
        </>
      )}
    </>
  );
};
