import { useFormik } from "formik";
import Modals from "../../../Components/Modals";
import * as S from "./styles";
import * as Yup from "yup";
import { LoadDots } from "../../../Components/Loader";

const validationSchema = Yup.object({
  quantity: Yup.number().required("Quantity is required"),
  number_of_seats: Yup.number().required("number_of_seats is required"),
});

export const CreateTableModal = ({
  show,
  onhide,
  loading,
  handleBulkCreate,
}) => {
  const formik = useFormik({
    initialValues: {
      quantity: "",
      number_of_seats: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleBulkCreate(values);
    },
  });

  return (
    <>
      <Modals
        size="sm"
        curved
        show={show}
        onHide={onhide}
        // dark
      >
        <S.ModalHeader>Mesas</S.ModalHeader>
        <S.StartSubText>Quantidade De Mesas</S.StartSubText>
        <S.Input
          type="number"
          name="quantity"
          value={formik.values.quantity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Eg: 10, 50 *"
        />
        {formik.touched.quantity && formik.errors.quantity && (
          <S.ErrorText>{formik.errors.quantity}</S.ErrorText>
        )}
        <br />
        <S.StartSubText>Capicidade de mesas</S.StartSubText>
        <S.Input
          type="number"
          name="number_of_seats"
          value={formik.values.number_of_seats}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Eg: 10, 50 *"
        />
        {formik.touched.number_of_seats && formik.errors.number_of_seats && (
          <S.ErrorText>{formik.errors.number_of_seats}</S.ErrorText>
        )}
        <br />
        <S.StartSubText>
          Criaremos a quantidade de mesas inseridas acima, e elas terão uma
          capacidade de assentos padrão, isso pode ser editado depois para cada
          mesa
        </S.StartSubText>
        <br />
        <S.BlackButton onClick={formik.handleSubmit}>
          {loading.bulkCreate ? <LoadDots isWhite /> : "Criar Tabelas"}
        </S.BlackButton>
        <br />
      </Modals>
    </>
  );
};
