import Modals from "../../../Components/Modals";

import * as S from "./styles";

export const ConfirmDelete = ({ show, onHide, handleDeleteItem }) => {
  return (
    <Modals
      size="sm"
      backdrop="normal"
      curved
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <S.ModalHeader>Confirmar exclusão</S.ModalHeader>

      <S.DeleteModal>
        <div className="delete_holder">
          <div className="cancel" onClick={onHide}>
            Cancelar
          </div>
          <div className="delete" onClick={handleDeleteItem}>
            Deletar
          </div>
        </div>
      </S.DeleteModal>
    </Modals>
  );
};
