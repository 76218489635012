import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  margin-top: 78px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;

  @media (max-width: ${1000}px) {
    padding: 50px 10px;
  }
`;

export const ProfileImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
`;

export const imageHolder = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: ${styles.LightGrey};

  .empty-image {
    width: 150px;
    height: 150px;
  }
`;

export const EditBtn = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  bottom: 25px;
  /* z-index: 999; */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${styles.NeutralsWhite};
  box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);

  svg {
    cursor: pointer;
    color: ${styles.OffBlack};
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const UserName = styled.p`
  font-weight: 700;
  font-size: 20px;
`;

export const SaveBtn = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  width: ${(props) => props.fill && "100%"};
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    padding: 12px 17px;
    font-size: 16px;
    color: ${styles.MidGrey};
  }
`;
