import styled from "styled-components";
import styles from "../../Assets/styles";

export const ErrorText = styled.p`
  margin: 0;
  color: ${styles.Error};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
