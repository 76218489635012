import styled, { css } from "styled-components";
import styles from "../../Assets/styles";

export const Timer = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  transition: 0.3s;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 50%;
  border-color: #d5f0e7;
  border-color: ${({ minutes, hours }) =>
    minutes > 59 || hours > 0
      ? styles.Error
      : minutes > 30
      ? "orange"
      : styles.acceptGreen};

  color: ${({ minutes, hours }) =>
    minutes > 59 || hours > 0
      ? styles.Error
      : minutes > 30
      ? "orange"
      : styles.acceptGreen};

  p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
`;
