import { api } from ".";
import { getUser } from "../Utils/localStorage";
const user = getUser();

export const getCategories = async () =>
  await api.get(`/api/v1/category/`, {
    headers: {
      Authorization: user?.token,
    },
  });

export const getOrgsUnderCategory = async (category) =>
  await api.get(`/api/v1/category/${category}`, {
    headers: {
      Authorization: user?.token,
    },
  });
