import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const OptionsHolder = styled.div`
  max-width: calc(100% - 100px);
  min-width: calc(100% - 100px);
  display: flex;
  gap: 10px;
  overflow: hidden;
  overflow: scroll;
  border-radius: 15px;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${1000}px) {
    max-width: 100%;
  }
`;

export const Option = styled.div`
  transition: 0.3s;
  padding: ${(props) => !props.isImage && " 0px 12px"};
  border-radius: 25px;
  min-width: max-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => !props.isImage && "35px"};
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? styles.LightGrey : styles.OffBlack};
  color: ${(props) =>
    props.selected ? styles.OffBlack : styles.NeutralsWhite};
  :hover {
    background-color: ${styles.LightGrey};
    color: ${styles.OffBlack};
  }
`;

export const ImageOption = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 15px;

  object-fit: cover;
`;

export const NavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  transition: 0.2s;
  border-radius: 50%;
  min-width: max-content;
  opacity: ${(props) => (props.show ? 1 : 0)};
  cursor: pointer;
  background-color: ${styles.OffBlack};
  color: ${styles.NeutralsWhite};
  :hover {
    background-color: ${styles.LightGrey};
    color: ${styles.OffBlack};
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;
