/* eslint-disable react-hooks/exhaustive-deps */
import {
  restaurant1,
  restaurant2,
  restaurant3,
  restaurant4,
  waithere,
} from "../../Assets/images/images";
import { FilledButton, UnFilledButton } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs";
import * as S from "./styles";
import { FcGoogle } from "react-icons/fc";
import { Slider } from "../../Components/ImageSlider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userLogIn, userLogout } from "../../Api/UserApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, isLogin } from "../../Utils/localStorage";
import { DropDownSelect } from "../../Components/DropdownSelect";
import { ErrorText } from "../../Components/Error";
import useOrganization from "../../Hooks/useOrganization";
import { LoadDots } from "../../Components/Loader";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short."),
});

export const Login = () => {
  const [pageState, setPageState] = useState({});

  const { allUserOrganizations, updateOrganization, fetchAllUserOrgs } =
    useOrganization();

  const navigate = useNavigate();

  const fetchOrgs = async () => {
    setPageState({ ...pageState, isLoading: { fetchOrgs: true } });
    await fetchAllUserOrgs();
    setPageState({ ...pageState, isLoading: { fetchOrgs: false } });
  };

  const handleLogOut = () => {
    userLogout();
    navigate("/log-in");
  };

  const sliderData = [
    { image: restaurant1, text: "Testing " },
    { image: restaurant2, text: "Testing " },
    { image: restaurant3 },
    { image: restaurant4 },
  ];

  // let loggedIn = isLogin();
  useEffect(() => {
    if (getUser()?.token) {
      fetchOrgs();
    }
  }, [pageState?.isLoading?.logIn]);

  const logIn = async (values) => {
    setPageState({ ...pageState, isLoading: { logIn: true } });
    try {
      await userLogIn(values);
      setPageState({ ...pageState, isLoading: { logIn: false } });
    } catch (err) {
      formik.setErrors({ ...formik.errors, loginError: err?.response?.data });
      setPageState({ ...pageState, isLoading: { logIn: false } });
    }
  };

  const accessOrganization = () => {
    if (formik.values.org) {
      updateOrganization(formik.values.org);
      setPageState({ ...pageState, isLoading: { accessOrg: true } });
      setTimeout(() => {
        window.location.href = "/home";
        setPageState({ ...pageState, isLoading: { accessOrg: false } });
      }, 500);
    }
  };

  const formik = useFormik({
    initialValues: { email: getUser()?.email, password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, formikBag) => {
      logIn(values);
    },
    enableReinitialize: true,
  });

  return (
    <S.Container>
      {pageState?.isLoading?.fetchOrgs && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!pageState?.isLoading?.fetchOrgs && (
        <S.MidContainer>
          <S.leftSection>
            <S.Company>
              <img src={waithere} alt="logo" />
            </S.Company>

            {!isLogin() && (
              <>
                <S.BoldText>Log into your Organization</S.BoldText>
                <S.Text>Login into your organization to continue.</S.Text>
              </>
            )}

            {isLogin() && (
              <>
                <S.BoldText>Welcome {getUser()?.name}</S.BoldText>
                <S.Text>Please select an organization .</S.Text>
              </>
            )}

            <br />
            <S.InputHolder>
              {!isLogin() && (
                <>
                  <Input
                    value={formik.values.email}
                    placeHolder={"Email"}
                    errors={formik.errors.email}
                    onChange={(value) => formik.setFieldValue("email", value)}
                  />
                  <Input
                    type={"password"}
                    placeHolder={"Password"}
                    errors={formik.errors.password}
                    onChange={(value) =>
                      formik.setFieldValue("password", value)
                    }
                  />
                </>
              )}
              {isLogin() && (
                <>
                  <DropDownSelect
                    placeholder={"Search by organization name "}
                    values={allUserOrganizations?.map((org) => ({
                      id: org.id,
                      label: org.organization.name,
                      image: org.organization?.logo?.url,
                    }))}
                    onChange={(org) => {
                      const valOrg = allUserOrganizations?.find(
                        (val) => val.id === org.id
                      );
                      formik.setFieldValue("org", valOrg);
                    }}
                  />
                </>
              )}

              {formik.errors.loginError && (
                <ErrorText error={formik.errors?.loginError} />
              )}
            </S.InputHolder>
            <br />
            {!isLogin() && (
              <FilledButton
                isLoading={pageState?.isLoading?.logIn}
                text={"Login"}
                variant={"signup"}
                onClick={() => formik.handleSubmit()}
              />
            )}
            {isLogin() && (
              <FilledButton
                isLoading={pageState?.isLoading?.accessOrg}
                text={"Access Organization"}
                variant={"signup"}
                onClick={() => accessOrganization()}
              />
            )}

            <br />
            {!isLogin() && (
              <UnFilledButton
                text={"Log in with Google "}
                leftIcon={<FcGoogle />}
                variant={"signup"}
              />
            )}
            {!isLogin() && (
              <>
                <br />
                <S.LoginText>
                  Don't have an account?{" "}
                  <span onClick={() => navigate("/sign-up")}>Sign Up</span>
                </S.LoginText>
              </>
            )}
            {isLogin() && (
              <S.LoginText>
                Click{" "}
                <span onClick={() => navigate("/create-organization")}>
                  here
                </span>{" "}
                to create a new Organization
              </S.LoginText>
            )}

            {isLogin() && (
              <>
                <b>OR</b>
                <span onClick={() => handleLogOut()}>Log Out</span>
              </>
            )}
          </S.leftSection>

          <S.rightSection image={restaurant2}>
            <Slider data={sliderData} />
          </S.rightSection>
        </S.MidContainer>
      )}
    </S.Container>
  );
};
