import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const indexStatuses = async (organization) => {
  const user = getUser();

  return await api.get(`api/v1/order/statuses?organization=${organization}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getOrders = async (params) => {
  const user = getUser();
  const queryParams = convertToUri(params);

  return await api.get(`api/v1/orders/org?${queryParams}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const searchOrders = async (params) => {
  const user = getUser();
  const queryParams = convertToUri(params);

  return await api.get(`api/v1/orders/search?${queryParams}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const placeOrder = async (params) => {
  const user = getUser();

  return await api.post(`api/v1/order/new`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const editOrderStatus = async ({ id, params }) => {
  const user = getUser();

  return await api.put(`api/v1/order/edit_status/${id}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getOrder = async ({ id, slug }) => {
  const user = getUser();

  return await api.get(`api/v1/order/${id}?organization=${slug}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateOrderItem = async (params) => {
  const user = getUser();

  return await api.put(`api/v1/order/item/${params.id}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const deleteOrderItem = async ({ id, params }) => {
  const user = getUser();
  const queryParams = convertToUri(params);

  return await api.delete(`api/v1/order/delete_item/${id}?${queryParams}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

const convertToUri = (params) => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};
