import { RiErrorWarningLine } from "react-icons/ri";
import styled from "styled-components";
import styles from "../../Assets/styles";
import { defaultmenuimage3 } from "../../Assets/images/images";

export const Container = styled.div`
  margin-top: 150px;
  margin-bottom: 100px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Holder = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1210px;
  @media (max-width: ${1000}px) {
    width: 100%;
  }
`;

export const TopSec = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  @media (max-width: ${1000}px) {
    justify-content: space-between;
    padding: 0px 10px;
  }
`;

export const NavHolder = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;

  @media (max-width: ${1000}px) {
    padding: 0px 00px;
    max-width: calc(100% - 70px);
    min-width: calc(100% - 70px);
  }
`;

export const NavHolder2 = styled.div`
  display: flex;
  justify-items: center;
  max-width: 90%;
`;

export const FlexHolder = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .category_name {
    width: 250px;
  }
`;

export const CenterHolder = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImagesText = styled.p`
  margin: 0;
  padding: 0;
`;

export const AddOnHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

export const OptionsHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-height: 210px;
  overflow-x: scroll;
`;

export const AddOn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: ${({ small }) => (small ? "40px" : "50px")};
  border-radius: 10px;
  border: 1px solid ${styles.DarkGrey};
  .actions-holder {
    display: flex;
    gap: 25px;
    svg {
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    padding: 0;
    width: 40%;
    max-width: 40%;
    overflow: hidden;
  }

  .option_name {
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AddNewBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  position: relative;
  transition: 0.2s;
  border-radius: 50%;
  min-width: max-content;
  cursor: pointer;
  background-color: ${styles.OffBlack};
  color: ${styles.NeutralsWhite};
  :hover {
    background-color: ${styles.LightGrey};
    color: ${styles.OffBlack};
  }
  svg {
    width: 18px;
    height: 18px;
  }
  .uploader {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
  }
`;

export const AddNewBtnSmll = styled(AddNewBtn)`
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const SmallAddNewBtn = styled(AddNewBtn)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;

  svg {
    width: 15px !important;
    height: 15px !important;
  }
`;

export const MenuLogo = styled(AddNewBtn)`
  :hover {
    background-color: ${styles.LightGrey};
    color: ${styles.OffBlack};
  }
  @media (max-width: ${1000}px) {
    display: none;
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AbsoluteHolder = styled.div`
  height: fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export const BannerImage = styled.img`
  width: 60vw;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;

  @media (max-width: ${1000}px) {
    width: 95%;
    height: 250px;
  }
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 650;
  text-align: center;
  color: ${styles.OffBlack};
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${styles.DarkGrey};
  max-width: 300px;
  text-align: center;
  font-weight: 500;
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  width: ${(props) => props.fill && "100%"};
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const GreyButton = styled(BlackButton)`
  background-color: ${styles.LightGrey};
  color: ${styles.OffBlack};

  :hover {
    color: ${styles.MidGrey};
  }
`;

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  margin-top: -35px;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${styles.DarkGrey};
  border-radius: 10px;
  padding: 0px 10px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextInput = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid ${styles.DarkGrey};
  border-radius: 10px;
  padding: 6px 10px;
`;

export const StartSubText = styled(SubText)`
  text-align: start;
  margin-bottom: 5px;
  margin-top: 10px;
  max-width: 100%;
`;

export const DaysHolder = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  .check-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      font-size: 13px;
    }
  }
`;

export const ModalLoadHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 40px);
  border-radius: 10px;
  margin-top: -40px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const MenusHolder = styled.div`
  margin-top: 50px;
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${1000}px) {
    width: 95%;
    padding: 0px;
  }
`;

export const MenuTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
  svg {
    transition: 0.4s;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .svg_holder {
    display: flex;
    gap: 20px;
  }
`;

export const MenusName = styled.p`
  font-weight: 600;
  color: ${styles.OffBlack};
  font-size: 20px;
  padding: 0;
  margin: 0;
`;

export const CenteredTextContent = styled.p`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${styles.OffBlack};
`;

export const WarningIcon = styled(RiErrorWarningLine)`
  color: ${styles.Error};
  align-self: center;
  width: 50px;
  height: 50px;
`;

export const ButtonHolder = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: ${(props) =>
    props.center ? "center" : props.end ? "end" : "space-between"};
  align-items: center;
  .small-btn {
    padding: 2px 15px;
  }
`;

export const TextContent = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${styles.OffBlack};
  text-align: ${(props) => props.center && "center"};
  span {
    text-transform: uppercase;
  }
`;

export const AllMenuHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const Fader = styled.div`
  position: relative;
  height: 70vh;
  max-height: 550px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FadeTest = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const StepsHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const ImagesHolder = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  border-radius: 15px;
  background-color: ${styles.LightGrey};
  background-image: ${`url(${defaultmenuimage3})`};
  background-size: cover;
  background-position: center;
  position: relative;
  h3 {
    font-size: 18px;
    color: ${styles.OffBlack};
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-align: center;
  }
  p {
    cursor: pointer;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
`;

export const AddOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;

  svg {
    width: 18px;
    height: 18px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
    color: ${styles.NeutralsWhite};
    background-color: ${styles.OffBlack};
  }
`;

export const CollapseHolder = styled.div`
  border: 1px solid ${styles.MidGrey};
  border-top: 0;
  padding: 5px 5px;
  border-radius: 0 0 10px 10px;
  margin-bottom: 10px;
`;

export const OptionHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  P {
    margin: 0;
    padding: 0;
  }
`;
