import { useEffect } from "react";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FiChevronDown } from "react-icons/fi";
import { ColoredButton } from "../../Components/Buttons";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import * as S from "./styles";

export const EditMember = ({
  roles,
  memberToEdit,
  showModal,
  setShowModal,
  permisionIcon,
  updateOragnizationsTeam,
}) => {
  const [modalState, setModalState] = useState({});
  const [dropDowns, setDropDowns] = useState(false);
  const [member, setMember] = useState({});

  useEffect(() => {
    setMember(memberToEdit);
  }, [memberToEdit]);

  const reset = () => {
    setShowModal({ editMember: false });
    setMember({});
  };

  const handleRoleChange = (role) => {
    setMember({ ...member, role: role.label, role_id: role.value });
    setDropDowns(false);
  };

  const handleSubmit = async () => {
    setModalState({ isLoading: true });
    await updateOragnizationsTeam(member);
    setModalState({ isLoading: false });
    successUpdatedToast();
    reset();
  };

  const successUpdatedToast = () => {
    toast.success(`User has been updated successfully !!`, {
      duration: 4000,
      position: "top-center",
      style: {
        marginTop: "60px",
      },
    });
  };

  return (
    <>
      <Modals
        size="md"
        curved
        show={showModal.editMember}
        onHide={() => {
          reset();
        }}
        dark
      >
        <br />
        {modalState.isLoading && (
          <S.ModalLoadHolder>
            <LoadDots isWhite />
          </S.ModalLoadHolder>
        )}
        <S.ModalHeader>Edit Team Member</S.ModalHeader>

        <S.Rows>
          <S.NameHolder xs="12" lg="5">
            <div>
              <p>{member?.user?.email}</p>
            </div>
          </S.NameHolder>
          <S.Role xs="6" lg="5">
            {member?.role}
            {permisionIcon(member?.role)}
          </S.Role>
          <S.OptionHolder xs="6" lg="2">
            <S.OptionButton onClick={() => setDropDowns(!dropDowns)}>
              Roles <FiChevronDown />
            </S.OptionButton>

            <Collapse in={dropDowns}>
              <S.Collapser>
                {roles.map((role) => (
                  <div key={role.value} onClick={() => handleRoleChange(role)}>
                    {role.label}
                  </div>
                ))}
              </S.Collapser>
            </Collapse>
          </S.OptionHolder>
        </S.Rows>

        <br />
        <S.ButtonHolder center>
          <ColoredButton
            text={"Finish"}
            onClick={() => {
              handleSubmit();
            }}
          />
        </S.ButtonHolder>

        <br />
      </Modals>
    </>
  );
};
