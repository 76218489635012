// /api/v1/menu_category/

import { api } from ".";
import { getUser, getUserOrg } from "../Utils/localStorage";

export const getMenuCategories = async () => {
  const user = getUser();
  const {
    organization: { id },
  } = getUserOrg();

  return await api.get(`api/v1/menu_category/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const createNewCategory = async (params) => {
  const user = getUser();

  return await api.post(`api/v1/menu_category/`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateCategories = async (params) => {
  const user = getUser();

  return await api.patch(`api/v1/menu_category/${params.menu_id}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const fetchMenuUnderCategory = async (id) => {
  const user = getUser();
  return await api.get(`/api/v1/menu/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const deleteMenuCategory = async (id) => {
  const user = getUser();

  return await api.delete(`api/v1/menu_category/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const deleteMenu = async (id) => {
  const user = getUser();

  return await api.delete(`api/v1/menu/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const createMenus = async (params) => {
  const user = getUser();

  return await api.post(`api/v1/menu/`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateMenus = async (params) => {
  const user = getUser();

  return await api.patch(`api/v1/menu/${params.menu_id}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const createMenuItem = async (params) => {
  const {
    name,
    description,
    menu_id,
    menu_category_id,
    price,
    images,
    addOns,
  } = params;

  const user = getUser();
  const formData = new FormData();

  formData.append("menu_id", menu_id);
  formData.append("menu_category_id", menu_category_id);
  formData.append("name", name);
  formData.append("description", description);
  formData.append("price", price);
  formData.append("addOns", addOns);

  if (images.length) {
    images.map((image) => formData.append("images[]", image.file));
  }

  return await api.post(`/api/v1/menu_item/`, formData, {
    headers: {
      Authorization: user.token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateMenuItem = async (params) => {
  const {
    id,
    name,
    description,
    price,
    addOns,
    menu_id,
    newImages,
    removedImages,
  } = params;

  const user = getUser();
  const formData = new FormData();

  formData.append("menu_item_id", id);
  formData.append("name", name);
  formData.append("description", description);
  formData.append("price", price);
  formData.append("addOns", addOns);
  formData.append("menu_id", menu_id);

  if (newImages.length) {
    newImages.map((image) => formData.append("images[]", image.file));
  }

  if (removedImages.length) {
    removedImages.map(
      (image) => image.id && formData.append("removed_images[]", image.id)
    );
  }

  return await api.patch(`/api/v1/menu_item/${id}`, formData, {
    headers: {
      Authorization: user.token,
      "Content-Type": "multipart/form-data",
    },
  });
};

// public

export const getPublicMenuCategories = async () => {
  const {
    organization: { slug },
  } = getUserOrg();

  return await api.get(`/api/v1/public/menu/categories/${slug}`);
};

export const getPublicMenuItems = async (id) => {
  return await api.get(`/api/v1/public/menu/items/${id}`);
};

export const getMenuItems = async (menu_id) => {
  const user = getUser();

  return await api.get(`api/v1/menu_item/${menu_id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const searchMenuItems = async (query) => {
  const user = getUser();

  return await api.post(`/api/v1/public/menu/items/search`, query, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getMenuItemsPreview = async (params) => {
  const user = getUser();
  const {
    organization: { slug },
  } = getUserOrg();

  return await api.post(`/api/v1/public/menu/items-preview/${slug}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const addToCart = async (params) => {
  const user = getUser();

  return await api.post(`/api/v1/cart/add_item`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateCart = async (params) => {
  const { token } = getUser();
  return await api.post(`/api/v1/cart/edit_item/${params.id}`, params, {
    headers: {
      Authorization: token,
    },
  });
};
