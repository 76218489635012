import { Helmet } from "react-helmet";
import { ErrorRedirect } from "../Components/Error";
import { getUserOrg, isLogin } from "../Utils/localStorage";

const PrivateRoute = ({ children, allowIf }) => {
  const handleRedirect = () => {
    if (isLogin()) {
      if (getUserOrg()?.id) {
        return "/home";
      } else {
        // change this to select org page
        return "/log-in";
      }
    } else return "/log-in";
  };
  const allowed = allowIf ? allowIf() : true;
  if (!allowed || !isLogin() || !getUserOrg()?.id) {
    return <ErrorRedirect route={handleRedirect()} />;
  }

  return (
    <>
      <Helmet>
        <title>{`Waithere DashBoard | ${
          getUserOrg()?.organization?.name
        }`}</title>
      </Helmet>
      {children}
    </>
  );
};

export default PrivateRoute;
