/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

export const ErrorText = ({ error }) => {
  return (
    <>
      <S.ErrorText>{error}</S.ErrorText>
    </>
  );
};

export const ErrorRedirect = ({ route }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(route);
  }, []);

  return (
    <>
      <S.ErrorText>{"404 Not Found"}</S.ErrorText>
    </>
  );
};
