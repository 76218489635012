import * as GV from "./GlobalVariables";
import { getUser, isLogin } from "./localStorage";

export const validateInvitation = (invitation) => {
  if (invitation?.id) {
    if (isLogin()) {
      return invitation.email === getUser().email;
    } else return true;
  } else return false;
};

export const hasPermission = (role, action) => {
  switch (action) {
    case GV.INVITE_MEMBER:
      return [GV.ADMIN].includes(role);
    case GV.EDIT_MEMBER:
      return [GV.ADMIN].includes(role);
    case GV.DELETE_MEMBER:
      return [GV.ADMIN].includes(role);
    case GV.CREATE_CATEGORY:
      return [GV.ADMIN, GV.CHEF].includes(role);
    case GV.EDIT_CATEGORY:
      return [GV.ADMIN, GV.CHEF].includes(role);
    case GV.EDIT_ORG:
      return [GV.ADMIN].includes(role);

    default:
      return false;
  }
  // work on category
};
