import { useNavigate } from "react-router-dom";
import * as S from "./styles";

export const Table = ({ table, handleShowTable }) => {
  const navigate = useNavigate();
  return (
    <S.Table table={table} onClick={() => handleShowTable(table, navigate)}>
      <p>
        Mesa No: <span>{table.table_number}</span>
      </p>
      <span>
        Capacidade: <span>{table.number_of_seats}</span>
      </span>
    </S.Table>
  );
};
