/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { getCategories } from "../Api/CategoryApi";

const useCategory = () => {
  const [category, setCategory] = useState();

  const updateCategory = useCallback((key, value) => {
    setCategory((prevCategory) => ({ ...prevCategory, [key]: value }));
  }, []);

  const fetchCategories = useCallback(async () => {
    try {
      const { data } = await getCategories();
      setCategory(data.categories);
    } catch (e) {
      console.log("error : ", e);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  return {
    category,
    updateCategory,
  };
};

export default useCategory;
