import { emptymenusimg } from "../../Assets/images/images";
import * as S from "./styles";

export const MenuItemCard = ({ menuItem, onClick, key }) => {
  // console.log(menuItem.addOns ? JSON.parse(menuItem.addOns) : menuItem);
  return (
    <S.MenuItemHolder key={key} onClick={onClick && onClick}>
      <img
        className="menu-item-img"
        src={menuItem?.images[0]?.url ?? emptymenusimg}
        alt="menu_img"
      />
      <div className="details">
        <p className="menu-item-name">{menuItem.name}</p>
        <div className="desc-holder">
          <p className="menu-item-desc">{menuItem.description}</p>
        </div>
        <p className="menu-item-prc">R${menuItem.price}</p>
      </div>
    </S.MenuItemHolder>
  );
};
