/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { getBranding, saveBranding } from "../Api/BrandingApi";

const useBranding = () => {
  const [branding, setBranding] = useState();
  const [loading, setLoading] = useState({ branding: true });

  const editBranding = (val, page, key, subKey) => {
    console.log(val, key, subKey);
    setBranding({
      ...branding,
      [page]: {
        ...branding[page],
        [key]: { ...branding[page][key], [subKey]: val },
      },
    });
  };

  const updateBranding = useCallback(async (type, values) => {
    setLoading({ branding: true });
    const { data } = await saveBranding(type, values);
    setBranding(data);
    setLoading({ branding: false });
  }, []);

  const fetchBranding = useCallback(async () => {
    try {
      setLoading({ branding: true });
      const { data } = await getBranding();
      setBranding(data);
      setLoading({ branding: false });
    } catch (e) {
      console.log("error : ", e);
    }
  }, []);

  useEffect(() => {
    fetchBranding();
  }, []);

  return {
    loading,
    branding,
    editBranding,
    fetchBranding,
    updateBranding,
  };
};

export default useBranding;
