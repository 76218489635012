import styled from "styled-components";
import BsModal from "react-bootstrap/Modal";
import styles from "../../Assets/styles";
import { closesvg, guteslogo } from "../../Assets/images/images";
import { GrFormClose } from "react-icons/gr";
export const Modal = styled(BsModal)`
  @media (min-width: 576px) {
    & .modal-sm {
      max-width: 400px;
    }
  }
  @media (min-width: 992px) {
    & .modal-md {
      max-width: 570px;
    }
    & .modal-xl {
      max-width: 850px;
    }
  }

  & .modal-content {
    border: unset;
    border-radius: 2px;
    border-radius: ${(props) => props.curved && "10px"};
    opacity: ${(props) => props.blur === "true" && 0.8};
    background-color: ${(props) => (props.dark ? "#151A1E" : "")};
    box-shadow: 0px 5px 15px rgba(79, 79, 79, 0.25);
  }

  & .modal-header {
    /* padding: 18px 18px 0 0; */
    padding: 18px 20px 6px;
    /* padding-right: 30px; */
    border-bottom: unset;
  }

  & .modal-body {
    padding: 6px 30px;
  }

  & .btn-close {
    width: 16px;
    height: 16px;
    font-weight: 700;
    /* margin-top: 10px; */
    background-size: ${(props) => (props.dark ? "30px" : "15px")};
    z-index: 99;
    color: white !important;
    opacity: 1 !important;
    background-image: ${(props) => props.dark && `url(${closesvg})`};
  }
`;

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  /* text-align: center; */
  margin-top: -35px;
`;

export const SubModalHeader = styled.p`
  font-size: 15px;
  color: ${styles.FocusedGrey};
  margin-top: -10px;
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
`;

export const ButtonsHolder = styled.div`
  margin-top: 20px;
  margin-left: -30px;
  margin-right: -30px;
  border-top: ${`1px solid  ${styles.IconGrey}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
`;

export const Input = styled.input`
  width: 99%;
  height: 40px;
  border-radius: 10px;
  border: ${`1px solid ${styles.IconGrey}`};
  padding-left: 10px;
  margin-bottom: 15px;

  ::placeholder {
    color: ${styles.FocusedGrey};
  }
  :focus {
    outline: none;
  }
`;

export const Error = styled.div`
  font-weight: 500;
  color: ${styles.Error};
`;
