import { useQuickMenu } from "../../../Hooks/useQuickMenu";
import Modals from "../../Modals";
import * as S from "../styles";
import * as MS from "./styles";
import { CartsItemCard } from "waitherepackages";
export const CartModal = ({
  controllerState = { modals: {} },
  setControllerState,
}) => {
  const {
    modals: { showCart },
  } = controllerState;

  const {
    handleQuantity,
    handlePlaceOrder,
    currentCart: { cart },
  } = useQuickMenu();

  const actions = { handleQuantity };
  return (
    <Modals
      size="sm"
      curved
      show={showCart}
      backdrop="static"
      onHide={() => {
        setControllerState({
          ...controllerState,
          modals: { ...controllerState.modals, showCart: false },
        });
      }}
    >
      <S.ModalHeader>Cart</S.ModalHeader>

      <MS.Holder>
        <MS.CartItemHolder>
          {cart?.cart_items?.map((cart_item) => (
            <CartsItemCard item={cart_item} actions={actions} />
          ))}
        </MS.CartItemHolder>
        <MS.BlackButton
          onClick={() => {
            handlePlaceOrder();
          }}
        >
          Finalizar Pedido <p>R${cart?.total ?? 0.0}</p>
        </MS.BlackButton>
      </MS.Holder>
    </Modals>
  );
};
