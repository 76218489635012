import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { ColoredButton } from "../../Components/Buttons";
import { BorderedInput } from "../../Components/Inputs";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import { CgCheckO } from "react-icons/cg";
import { getUser, getUserOrg } from "../../Utils/localStorage";
import * as S from "./styles";
import { MdOutlineCancel } from "react-icons/md";
import { ADMIN } from "../../Utils/GlobalVariables";

const StepOne = ({
  step,
  roles,
  setEmails,
  emails,
  setStep,
  setInvitesArray,
}) => {
  const handleNext = () => {
    if (emails) {
      setStep(2);
      const invitesArray = emails
        ?.replace(/\s/g, "")
        ?.split(",")
        .map(
          (val) =>
            val !== "" && {
              user_email: val,
              role: ADMIN,
              role_id: roles?.find((role) => role?.label === ADMIN)?.value,
            }
        )
        .filter((n) => n);
      setInvitesArray(invitesArray);
    }
  };

  return (
    <Collapse in={step === 1}>
      <div>
        <br />
        <S.BoldWhiteText>Emails of the new members</S.BoldWhiteText>
        <S.SubText>
          New team members will get an email with a link to accept the
          invitation. You can enter several email addresses.
        </S.SubText>

        <br />
        <S.SubText>Send invites to</S.SubText>
        <S.InputHolder>
          <BorderedInput
            placeHolder={"Enter email address"}
            onChange={(val) => setEmails(val)}
          />
        </S.InputHolder>
        <S.SubText>
          {15 - 2} of 15 team member seats are remaining on your plan.
        </S.SubText>
        <br />

        <ColoredButton
          variant={"continue"}
          text={"Continue"}
          onClick={() => {
            handleNext();
          }}
        />

        <br />
      </div>
    </Collapse>
  );
};

const StepTwo = ({
  step,
  invitesArray,
  setInvitesArray,
  permisionIcon,
  roles,
  sendTeamOrganization,
  setStep,
  setModalState,
}) => {
  const [dropDowns, setDropDowns] = useState({});

  const setUserRole = (idx, role) => {
    let invites = invitesArray;
    invites[idx] = { ...invites[idx], role: role.label, role_id: role.value };
    setInvitesArray(invites);
    setDropDowns({});
  };

  const sendInvitations = async () => {
    setModalState({ isLoading: true });
    const params = {
      new_members: invitesArray,
      inviter: getUser().id,
      organization_id: getUserOrg().organization.id,
    };
    const data = await sendTeamOrganization(params);
    setModalState({ isLoading: false, sentInvites: data });
    setStep(3);
  };

  return (
    <Collapse in={step === 2}>
      <div>
        <br />
        <S.BoldWhiteText>Permissions of the new members</S.BoldWhiteText>
        <S.SubText>
          New team members will get certain access depending on their positions.
        </S.SubText>

        <br />
        <S.SubText>Send invites to</S.SubText>
        <S.AllMembersHolderScroll>
          {invitesArray?.map((teamMate, i) => (
            <S.Rows key={i}>
              <S.NameHolder xs="12" lg="5">
                <div>
                  <p>{teamMate.user_email}</p>
                </div>
              </S.NameHolder>
              <S.Role xs="6" lg="5">
                {teamMate?.role}
                {permisionIcon(teamMate?.role)}
              </S.Role>
              <S.OptionHolder xs="6" lg="2">
                <S.OptionButton
                  onClick={() => setDropDowns({ [i]: !dropDowns[i] })}
                >
                  Roles <FiChevronDown />
                </S.OptionButton>

                <Collapse in={dropDowns[i]}>
                  <S.Collapser>
                    {roles.map((role) => (
                      <div
                        key={role.value}
                        onClick={() => setUserRole(i, role)}
                      >
                        {role.label}
                      </div>
                    ))}
                  </S.Collapser>
                </Collapse>
              </S.OptionHolder>
            </S.Rows>
          ))}
        </S.AllMembersHolderScroll>
        <S.SubText>
          {15 - 2} of 15 team member seats are remaining on your plan.
        </S.SubText>
        <br />

        <S.ButtonHolder>
          <ColoredButton
            variant={"continue"}
            text={"Back"}
            onClick={() => setStep(1)}
          />

          <ColoredButton
            variant={"continue"}
            text={"Send"}
            onClick={() => sendInvitations()}
          />
        </S.ButtonHolder>

        <br />
      </div>
    </Collapse>
  );
};

const StepThree = ({ step, reset, roles, modalState, permisionIcon }) => {
  return (
    <Collapse in={step === 3}>
      <div>
        <br />
        <S.BoldWhiteText>Sent invites to</S.BoldWhiteText>
        <S.SubText>
          Invitations will be sent to new team member's email, they will be
          added to the team when the invitation is accepted .
        </S.SubText>
        <br />

        {/* <S.SubText>Sent invites to</S.SubText> */}
        <S.AllMembersHolderScroll>
          {modalState?.sentInvites?.map((teamMate, i) => {
            const user_role = roles.find(
              (role) => role.value === teamMate?.userToOrg?.role_id
            )?.label;
            const success = teamMate.status === "SUCCESS";
            return (
              <S.Rows key={i}>
                <S.NameHolder xs="12" lg="5">
                  <div>
                    <p>{teamMate?.userToOrg?.email}</p>
                  </div>
                </S.NameHolder>
                <S.Role xs="6" lg="5">
                  {user_role}
                  {permisionIcon(user_role)}
                </S.Role>
                <S.OptionHolder xs="6" lg="2">
                  <S.StatusIcon success={success}>
                    {success ? <CgCheckO /> : <MdOutlineCancel />}
                  </S.StatusIcon>
                </S.OptionHolder>
              </S.Rows>
            );
          })}
        </S.AllMembersHolderScroll>
        <br />
        <S.ButtonHolder center>
          <ColoredButton
            // variant={"continue"}
            text={"Finish"}
            onClick={() => {
              reset();
            }}
          />
        </S.ButtonHolder>
      </div>
    </Collapse>
  );
};
export const AddMemberModal = ({
  showModal,
  setShowModal,
  permisionIcon,
  roles,
  sendTeamOrganization,
}) => {
  const [step, setStep] = useState(1);
  const [emails, setEmails] = useState("");
  const [invitesArray, setInvitesArray] = useState([]);
  const [modalState, setModalState] = useState({});

  const reset = () => {
    setShowModal({ ...showModal, addMember: false });
    setStep(1);
    setEmails("");
    setInvitesArray([]);
  };

  return (
    <>
      <Modals
        size="xl"
        curved
        show={showModal.addMember}
        onHide={() => {
          reset();
        }}
        dark
      >
        <br />
        {modalState.isLoading && (
          <S.ModalLoadHolder>
            <LoadDots isWhite />
          </S.ModalLoadHolder>
        )}
        <S.ModalHeader>Add Team Member</S.ModalHeader>
        <S.SubText>
          We automatically update your billing as you add and remove team
          members. You can have up to 15 team member seats on your plan.
        </S.SubText>

        <S.StepsHolder>
          <StepOne
            step={step}
            roles={roles}
            setEmails={setEmails}
            emails={emails}
            setStep={setStep}
            setInvitesArray={setInvitesArray}
          />
          <StepTwo
            step={step}
            invitesArray={invitesArray}
            setInvitesArray={setInvitesArray}
            permisionIcon={permisionIcon}
            roles={roles}
            sendTeamOrganization={sendTeamOrganization}
            setStep={setStep}
            setModalState={setModalState}
          />
          <StepThree
            step={step}
            roles={roles}
            reset={reset}
            modalState={modalState}
            setShowModal={setShowModal}
            permisionIcon={permisionIcon}
          />
        </S.StepsHolder>
      </Modals>
    </>
  );
};
