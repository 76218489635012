/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { verifyLogIn } from "../Utils/localStorage";
// import { useNavigate } from "react-router-dom";
// import { getOrg } from "../Api";
// import { isUserLogin } from "../Api/UserApi";
// import { logout } from "../Utils/localStorage";

export const userContext = createContext();

const initialState = { org: {}, user: {} };

const UserStore = ({ children }) => {
  const [userState, setUserState] = useState(initialState);

  useEffect(() => {
    verifyLogIn();
  }, []);

  return (
    <userContext.Provider value={[userState, setUserState]}>
      {children}
    </userContext.Provider>
  );
};

export default UserStore;
