import { api } from ".";
import { getUser, logout, setLogin } from "../Utils/localStorage";

export const userSigUp = async (param) => {
  const formData = new FormData();

  // formData.append("user[avatar]", null);

  // append the other form inputs to the FormData object
  formData.append("user[name]", param.name);
  formData.append("user[email]", param.email);
  formData.append("user[password]", param.password);

  return await api.post(`/api/v1/users/`, formData);
};

export const userLogIn = async (param) => {
  const response = await api.post(`/api/v1/users/login`, {
    user: param,
  });

  setLogin({
    ...response.data.user,
    token: response.headers.authorization,
  });
  return response;
};

export const userLogout = async (param) => {
  const user = getUser();
  logout();
  await api.delete("/api/v1/users/logout", {
    headers: {
      Authorization: user.token, //the token is a variable which holds the token
    },
  });
};

export const updateUserAvatar = async (avatar) => {
  const user = getUser();

  const formData = new FormData();
  formData.append("user[avatar]", avatar);

  console.log("updatedAvatar", formData);

  const response = await api.patch(`/api/v1/user_avatar/${user.id}`, formData, {
    headers: {
      Authorization: user.token,
      "Content-Type": "multipart/form-data",
    },
  });

  const updatedUser = {
    ...user,
    avatar: response.data.avatar_url,
  };

  localStorage.setItem("user", JSON.stringify(updatedUser));

  return response.data.avatar_url;
};

export const isUserLogin = async (param) => {
  const user = getUser();
  if (user?.token)
    return await api.get("/api/v1/is_login", {
      headers: {
        Authorization: user?.token, //the token is a variable which holds the token
      },
    });
  return false;
};
