import { TopNav } from "../../Components/TopNav";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import * as S from "./styles";
import useOutsideClick from "../../Hooks/UseOutsideClick";
import { useRef } from "react";
import { useState } from "react";
import { TbSettingsSearch } from "react-icons/tb";
import { Collapse } from "react-bootstrap";
import Timer from "../../Components/Timer";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoChevronDownOutline } from "react-icons/io5";
import useOrder from "../../Hooks/useOrder";
import { HiOutlineDownload } from "react-icons/hi";
import { LoadDots, LoadSpiner } from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import { OrderModal } from "./Modals/OrderModal";
import { MdModeEdit } from "react-icons/md";
import { RiSettings3Fill } from "react-icons/ri";
import { PendingSettingModal } from "./Modals/PendingSettingModal";

export const Orders = () => {
  const orderContainerRef = useRef(null);
  const navigate = useNavigate();

  const orderState = useOrder({
    navigate,
    index: true,
  });

  const {
    filters,
    statuses,
    selected,
    loading,
    setFilters,
    orderModals,
    handleSearchChange,
  } = orderState;

  return (
    <S.Container>
      <TopNav />

      <S.Holder>
        {!loading.indexOrders && (
          <>
            <OrderTopSection
              loading={loading}
              filters={filters}
              statuses={statuses}
              selected={selected}
              setFilters={setFilters}
              orderContainerRef={orderContainerRef}
              handleSearchChange={handleSearchChange}
            />

            <OrdersList {...orderState} orderContainerRef={orderContainerRef} />
          </>
        )}
        {loading.indexOrders && <LoadDots />}

        <OrderModal
          {...orderState}
          show={orderModals.order}
          onHide={() => navigate("/orders")}
        />
      </S.Holder>
    </S.Container>
  );
};

const OrderTopSection = ({
  loading,
  filters,
  statuses,
  selected,
  setFilters,
  orderContainerRef,
  handleSearchChange,
}) => {
  const ref = useRef();
  const [expandFilter, setExpandFilter] = useState(false);

  useOutsideClick(ref, () => setExpandFilter(false));

  const handleSelect = (selected) => {
    const selectedElement = orderContainerRef.current.querySelector(
      `[data-id="${selected.id}"]`
    );
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLengthChange = (event) => {
    setFilters({ ...filters, per_page: event.target.value });
  };

  return (
    <S.OrderTopSection>
      <div className="first_section">
        <div className="search_holder">
          <FiSearch />
          <input
            value={filters.search}
            placeholder="Codigo de Pedido, Nome de Cliente .."
            onChange={handleSearchChange}
          />
          {loading?.searchOrders ? (
            <LoadSpiner />
          ) : (
            <IoClose
              className="search_icon"
              onClick={() => setFilters({ ...filters, search: "" })}
            />
          )}
        </div>

        <div className="filters" ref={ref}>
          <div
            className="filter_holder"
            onClick={() => setExpandFilter(!expandFilter)}
          >
            <TbSettingsSearch />
            <p>Filtro</p>
          </div>

          <S.FiltersHolder>
            <Collapse in={expandFilter}>
              <div className="size_section">
                <p>Tamanho de Pedidos: </p>
                <div className="radios_holder">
                  <label className="black-radio">
                    <input
                      type="radio"
                      name="length"
                      value="10"
                      checked={filters.per_page === "10"}
                      onChange={handleLengthChange}
                    />{" "}
                    10
                  </label>

                  <label className="black-radio">
                    <input
                      type="radio"
                      name="length"
                      value="20"
                      checked={filters.per_page === "20"}
                      onChange={handleLengthChange}
                    />{" "}
                    20
                  </label>

                  <label className="black-radio">
                    <input
                      type="radio"
                      name="length"
                      value="50"
                      checked={filters.per_page === "50"}
                      onChange={handleLengthChange}
                    />{" "}
                    50
                  </label>
                </div>
              </div>
            </Collapse>
          </S.FiltersHolder>
        </div>
      </div>

      {statuses.length > 0 && (
        <div className="status_holder">
          {statuses.map((status, id) => (
            <StatusCard
              key={id}
              {...status}
              selected={selected.id === status.id}
              onClick={() => handleSelect(status)}
            />
          ))}
        </div>
      )}
    </S.OrderTopSection>
  );
};

const StatusCard = ({ quantity, name, color, selected, onClick }) => {
  return (
    <S.StatusCard color={color} selected={selected} onClick={onClick}>
      <div className="quantity">{quantity}</div>
      <div className="status_name">{name}</div>
    </S.StatusCard>
  );
};

const OrdersList = ({
  orders,
  loading,
  statuses,
  setSelected,
  printerRooms,
  handleShowOrder,
  handlePrintOrder,
  handleEditStatus,
  handlePagination,
  orderContainerRef,
  handleEditPrinterRooms,
}) => {
  const handleScroll = () => {
    const container = orderContainerRef.current;
    const elements = container.querySelectorAll("[data-id]");

    for (let element of elements) {
      const rect = element.getBoundingClientRect();
      if (rect.top <= 300 && rect.bottom >= 300) {
        // The element is in the view
        const id = parseInt(element.getAttribute("data-id"));
        setSelected(statuses.find((stat) => stat.id === id));
        break;
      }
    }
  };

  return (
    <S.OrderList onScroll={handleScroll} ref={orderContainerRef}>
      {statuses.map((current_status) => {
        const isLoadingPagination =
          loading.status.current === current_status.key &&
          loading.status.isLoading;
        return (
          <S.StatusSection
            color={current_status.color}
            key={current_status.id}
            className="status_section"
            data-id={current_status.id}
          >
            <div className="status_header_section">
              <h3>{current_status.name}</h3>
              <StatusSetting status={current_status} />
            </div>

            <div className="orders_holder">
              {orders?.[current_status.key]?.data.map((order) => (
                <OrderCard
                  order={order}
                  key={order.id}
                  loading={loading}
                  statuses={statuses}
                  status={current_status}
                  printerRooms={printerRooms}
                  handleShowOrder={handleShowOrder}
                  handlePrintOrder={handlePrintOrder}
                  handleEditStatus={handleEditStatus}
                  handleEditPrinterRooms={handleEditPrinterRooms}
                />
              ))}

              {current_status.quantity === 0 && (
                <p>Nenhum pedido com este status</p>
              )}

              {current_status.quantity !== 0 && (
                <S.PaginatorHolder
                  disabled={!orders?.[current_status.key]?.pagination?.next}
                >
                  <div className="quantity">
                    {orders?.[current_status.key]?.data.length} /{" "}
                    {current_status.quantity}
                  </div>
                  <div
                    className="pagination_button"
                    onClick={() =>
                      orders?.[current_status.key]?.pagination?.next &&
                      handlePagination(current_status.key)
                    }
                  >
                    <HiOutlineDownload />
                    <p>Mostra Mais</p>

                    {isLoadingPagination && (
                      <S.LoaderHolder>
                        <LoadDots />
                      </S.LoaderHolder>
                    )}
                  </div>
                </S.PaginatorHolder>
              )}
            </div>
          </S.StatusSection>
        );
      })}
    </S.OrderList>
  );
};

const OrderCard = ({
  order,
  status,
  loading,
  statuses,
  printerRooms,
  handleShowOrder,
  handleEditStatus,
  handlePrintOrder,
  handleEditPrinterRooms,
}) => {
  const [expandedOpts, setExpandedOpts] = useState(false);
  const startTime = new Date(order.created_at);
  const isLoading =
    loading.order.current?.id === order.id && loading.order.isLoading;
  return (
    <S.OrderCard {...status}>
      <div className="card_top">
        <div className="timer">
          <Timer startTime={startTime} />
        </div>
        <div className="order_by">
          <p className="name">{order.odered_for}</p>
          {order.table_no && (
            <a href={`/tables?table_no=${order.table_no}`} className="location">
              Mesa {order.table_no}
            </a>
          )}
        </div>
        <div className="order_number">
          <p onClick={() => handleShowOrder(order)} href={`?order=${order.id}`}>
            #{order.display_id}
          </p>
        </div>
      </div>

      <div className="items">
        {order.order_items.map(({ id, name, price, quantity }) => (
          <div className="item" key={id}>
            <div className="dot" />
            <div className="item_name">
              <p>
                {name} x {quantity}
              </p>
            </div>
            <div className="item_price">
              <p>R${price * quantity}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="total item">
        <div
          className="options_holder"
          onClick={() => {
            setExpandedOpts(!expandedOpts);
          }}
        >
          <BsThreeDotsVertical />
        </div>
        <div className="item_name">
          <p>Total</p>
        </div>
        <div className="item_price">
          <p>R${order.total}</p>
        </div>

        {!isLoading && (
          <OrderCardOptions
            order={order}
            statuses={statuses}
            expandedOpts={expandedOpts}
            printerRooms={printerRooms}
            setExpandedOpts={setExpandedOpts}
            handleEditStatus={handleEditStatus}
            handlePrintOrder={handlePrintOrder}
            handleEditPrinterRooms={handleEditPrinterRooms}
          />
        )}
      </div>

      {isLoading && (
        <S.LoaderHolder>
          <LoadDots />
        </S.LoaderHolder>
      )}
    </S.OrderCard>
  );
};

const OrderCardOptions = ({
  order,
  statuses,
  printerRooms,
  expandedOpts,
  setExpandedOpts,
  handleEditStatus,
  handlePrintOrder,
  handleEditPrinterRooms,
}) => {
  const ref = useRef();

  const [expandOpts, setExpandOpts] = useState({ status: false });
  useOutsideClick(ref, () => {
    setExpandedOpts(false);
    setExpandOpts({});
  });

  return (
    <S.OptionsHolder ref={ref}>
      <Collapse in={expandedOpts}>
        <div>
          <div className="holder">
            <div
              className="opt_item"
              onClick={() => setExpandOpts({ status: !expandOpts.status })}
            >
              <p className="opt_name">• Editar Status do Pedido</p>
              <IoChevronDownOutline />
              <MoveOptions
                order={order}
                statuses={statuses}
                expandOpts={expandOpts}
                handleEditStatus={handleEditStatus}
              />
            </div>

            <div
              className="opt_item"
              onClick={() => setExpandOpts({ print: !expandOpts.print })}
            >
              <p className="opt_name">• Imprimir Pedido No(a)</p>
              <IoChevronDownOutline />

              <PrinterOptions
                order={order}
                printerRooms={printerRooms}
                expandOpts={expandOpts}
                handlePrintOrder={handlePrintOrder}
                handleEditPrinterRooms={handleEditPrinterRooms}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </S.OptionsHolder>
  );
};

export const MoveOptions = ({
  order,
  statuses,
  expandOpts,
  handleEditStatus,
}) => {
  return (
    <div className="down_options_holder">
      <Collapse in={expandOpts.status}>
        <div>
          {statuses.map((status) => (
            <div
              key={status.id}
              className="down_opt"
              onClick={() => handleEditStatus(status, order)}
            >
              <S.Dot color={status.color} />
              <p className="down_opt_name">{status.name}</p>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export const PrinterOptions = ({
  order,
  printerRooms,
  expandOpts,
  handlePrintOrder,
  handleEditPrinterRooms,
}) => {
  return (
    <div className="down_options_holder">
      <Collapse in={expandOpts.print}>
        <div>
          {printerRooms.map((room) => (
            <div
              key={room.key}
              className="down_opt"
              onClick={() => handlePrintOrder(room, order)}
            >
              <p className="down_opt_name">• {room.label}</p>
            </div>
          ))}

          <div className="down_opt bordered" onClick={handleEditPrinterRooms}>
            <p className="down_opt_name">
              <MdModeEdit /> Editar Salas
            </p>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const StatusSetting = ({ status }) => {
  const [showModal, setShowModal] = useState({ pending: false });

  return (
    <>
      {status.key === "pending" && (
        <S.StatusSetting>
          <RiSettings3Fill
            className="setting_svg"
            onClick={() => setShowModal({ pending: !showModal.pending })}
          />
          {showModal.pending && (
            <PendingSettingModal
              show={showModal.pending}
              onHide={() => setShowModal({ pending: false })}
            />
          )}
        </S.StatusSetting>
      )}
    </>
  );
};
