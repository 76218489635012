/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
import { DropDownSelect } from "../../Components/DropdownSelect";
import { ProgressIndicator } from "../../Components/ProgressIndicator";
import { TopNav } from "../../Components/TopNav";
import useCategory from "../../Hooks/useCategory";
import * as S from "./styles";
import { IoClose, IoImageOutline } from "react-icons/io5";
import useLocationApi from "../../Hooks/useLocationApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { ImPencil } from "react-icons/im";
import { IoMdAdd } from "react-icons/io";
import useOrganization from "../../Hooks/useOrganization";
import { LoadDots } from "../../Components/Loader";

const StepOneShcema = Yup.object().shape({
  // name: Yup.string().required("Required"),
  // cep: Yup.string().required("Required").min(7, "Invalid CEP"),
  // state: Yup.string().required("Required"),
  // city: Yup.string().required("Required"),
  // email: Yup.string().required("Required"),
  // phone1: Yup.string().required("Required"),
  // country: Yup.string().required("Required"),
  // address: Yup.string().required("Required"),
  // slug: Yup.string().required("Required"),
  // categories: Yup.array().min(1, "Please select at least one"),
  // logo: Yup.string().required("Required"),
});

const StepTwoShcema = Yup.object().shape({
  // daysOpen: Yup.array().required("Required"),
});

export const CreateEditOrganization = ({ edit }) => {
  const [step, setStep] = useState(1);
  const {
    loading,
    organization,
    handleEditOrganization,
    handleCreateOrganization,
    fetchOrganization,
  } = useOrganization();

  useEffect(() => {
    if (edit) {
      fetchOrganization();
    }
  }, []);

  const useSchema = () => {
    switch (step) {
      case 1:
        return StepOneShcema;

      case 2:
        return StepTwoShcema;

      default:
        break;
    }
  };

  const initialValues = () => {
    const defaultValues = {
      name: "",
      cep: "",
      state: "",
      city: "",
      email: "",
      phone: "",
      country: "Brasil",
      address: "",
      slug: "",
      categories: [],
      logo: "",
    };

    switch (edit) {
      case true:
        const currentOrg = {
          ...defaultValues,
          ...organization,
        };
        return currentOrg;

      default:
        return defaultValues;
    }
  };

  const editCreate = () => {
    if (edit) {
      handleEditOrganization(formik.values);
    } else {
      handleCreateOrganization(formik.values);
    }
  };

  const handleSubmit = async (values) => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        editCreate();
        break;
      default:
        break;
    }
  };
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: useSchema(),
    onSubmit: (values, formikBag) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  // console.log(formik.values);

  return (
    <>
      <TopNav />

      <S.Container>
        <S.ContHolder>
          {loading.organization && (
            <S.LoadHolder>
              <LoadDots />
            </S.LoadHolder>
          )}
          {!loading.organization && (
            <>
              <S.Header>{edit ? "Edit" : "Create"} Organization</S.Header>

              {step === 1 && <StepOne formik={formik} />}
              {step === 2 && <StepTwo formik={formik} />}

              <S.BottomSec>
                <S.GreyButton
                  onClick={() => setStep(step - 1)}
                  hide={step === 1}
                >
                  {"Back"}
                </S.GreyButton>
                <ProgressIndicator quantity={2} current={step} />
                <S.BlackButton onClick={() => formik.handleSubmit()}>
                  {1 === 2 ? "Save" : "Next"}
                </S.BlackButton>
              </S.BottomSec>
            </>
          )}
        </S.ContHolder>
      </S.Container>
    </>
  );
};

const StepOne = ({ formik }) => {
  const handleZipChange = (vals) => {
    const { city, state, street } = vals;
    formik.setFieldValue("city", city);
    formik.setFieldValue("state", state);
    formik.setFieldValue("address", street);
  };

  const { category } = useCategory();
  const { handleSearchTermChange } = useLocationApi(handleZipChange);

  return (
    <>
      <S.RowHolder>
        <S.ColHolder sm={12} lg={6}>
          <S.SectionName>Information</S.SectionName>
          <div>
            <S.Input
              name="name"
              placeholder="Name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <S.Error>{formik.errors.name}</S.Error>
          </div>

          <div>
            <S.Input
              name="website"
              placeholder="Website"
              onChange={formik.handleChange}
              value={formik.values.website}
            />
            <S.Error>{formik.errors.website}</S.Error>
          </div>

          <div>
            <S.InputHolder2>
              <p>https://waithere.com/</p>
              <input
                name="slug"
                placeholder="Your desired Waithere URL"
                onChange={formik.handleChange}
                value={formik.values.slug}
              />
            </S.InputHolder2>
            <S.Error>{formik.errors.slug}</S.Error>
          </div>

          <br />

          <S.SectionName>Categories</S.SectionName>
          <div>
            <DropDownSelect
              placeholder="Search by category name"
              values={category?.map((cat) => ({
                id: cat.id,
                label: cat.category,
              }))}
              onChange={(cat) => {
                const valCat = category.find((val) => val.id === cat.id);
                !formik.values.categories.includes(valCat) &&
                  formik.setFieldValue("categories", [
                    ...formik.values.categories,
                    valCat,
                  ]);
              }}
            />
            <S.Error>{formik.errors.categories}</S.Error>
          </div>

          <S.SectionName>You have selected :</S.SectionName>
          <S.BadgeButtonHolder>
            {formik.values.categories.map((category) => (
              <S.Badge key={category.id}>
                <p>{category.category}</p>
                <IoClose
                  onClick={() =>
                    formik.setFieldValue(
                      "categories",
                      formik.values.categories.filter(
                        (ccat) => ccat.id !== category.id
                      )
                    )
                  }
                />
              </S.Badge>
            ))}
          </S.BadgeButtonHolder>
        </S.ColHolder>
        <S.ColHolder sm={12} lg={6}>
          <S.SectionName>Address</S.SectionName>

          <div>
            <S.Input
              name="address"
              placeholder="Address"
              onChange={formik.handleChange}
              value={formik.values.address}
            />
            <S.Error>{formik.errors.address}</S.Error>
          </div>

          <S.FlexHolder>
            <div>
              <S.Input
                name="country"
                placeholder="Country"
                value={"Brasil"}
                onChange={formik.handleChange}
              />
              <S.Error>{formik.errors.country}</S.Error>
            </div>

            <div>
              <S.Input
                name="cep"
                placeholder="Cep"
                type="number"
                value={formik.values.cep}
                onChange={(e) => {
                  handleSearchTermChange(e);
                  formik.setFieldValue("cep", e.target.value);
                }}
              />
              <S.Error>{formik.errors.cep}</S.Error>
            </div>
          </S.FlexHolder>

          <S.FlexHolder>
            <div>
              <S.Input
                name="state"
                placeholder="State"
                value={formik.values.state}
                onChange={formik.handleChange}
              />
              <S.Error>{formik.errors.state}</S.Error>
            </div>

            <div>
              <S.Input
                name="city"
                placeholder="City"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
              <S.Error>{formik.errors.city}</S.Error>
            </div>
          </S.FlexHolder>

          <br />
          <S.SectionName>Contact</S.SectionName>
          <div>
            <S.Input
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <S.Error>{formik.errors.email}</S.Error>
          </div>

          <div>
            <S.Input
              name="phone"
              placeholder="Phone No"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            <S.Error>{formik.errors.phone}</S.Error>
          </div>

          <S.Input
            name="phone2"
            placeholder="Phone 2"
            value={formik.values.phone2}
            onChange={formik.handleChange}
          />
        </S.ColHolder>
      </S.RowHolder>
    </>
  );
};

const StepTwo = ({ formik }) => {
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    formik.setFieldValue("newLogo", newFile);
  };
  return (
    <>
      <S.RowHolder>
        <S.ColHolder sm={12} lg={12}>
          <S.CenterHolder>
            <S.SectionName>Logo</S.SectionName>
            <S.imageHolder>
              {formik.values.newLogo || formik.values.logo ? (
                <S.ProfileImg
                  src={
                    formik.values.newLogo
                      ? URL.createObjectURL(formik.values.newLogo)
                      : formik.values.logo
                  }
                />
              ) : (
                <IoImageOutline className="empty-image" />
              )}
              <S.EditBtn>
                <input
                  name="newLogo"
                  type="file"
                  value=""
                  onChange={onFileDrop}
                />
                {formik.values.newLogo || formik.values.logo ? (
                  <ImPencil />
                ) : (
                  <IoMdAdd />
                )}
              </S.EditBtn>
            </S.imageHolder>
          </S.CenterHolder>
        </S.ColHolder>
      </S.RowHolder>
    </>
  );
};
