import { createContext, useState } from "react";
import { getUserOrg } from "../Utils/localStorage";
import useActionCable from "../Hooks/useActionCable";
import { useMutation } from "react-query";
import {
  getOrgPrinterRooms,
  updateOrgPrinterRooms,
} from "../Api/OrganizationApi";

export const PrinterContext = createContext();

const PrinterStore = ({ children }) => {
  const [printerRooms, setPrinterRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState([]);
  const [showOption, setShowOption] = useState({ add: false, edit: false });

  const slug = () => {
    const {
      organization: { slug },
    } = getUserOrg();
    return slug;
  };

  const getPrinterRoomsMutation = useMutation({
    mutationFn: getOrgPrinterRooms,
    onSuccess: (res) => {
      const { data } = res;
      setPrinterRooms(data);
    },
    onError: (error) => console.log(error),
  });

  const updateOrgPrinterRoomsMutation = useMutation({
    mutationFn: updateOrgPrinterRooms,
    onSuccess: (res) => {
      const { data } = res;
      setPrinterRooms(data);
    },
    onError: (error) => console.log(error),
  });

  const handleGetPrinterRooms = () => {
    getPrinterRoomsMutation.mutate(slug());
  };

  const handleReceivePrint = (message) => {
    // console.log("received", message);
  };

  const actionCableParams = {
    channel: "PrinterChannel",
    slug: slug(),
  };

  const { sendMessageToSocket } = useActionCable(
    actionCableParams,
    handleReceivePrint
  );

  const updateRooms = () => {
    const values = {
      slug: slug(),
      params: { printer_rooms: printerRooms },
    };
    updateOrgPrinterRoomsMutation.mutate(values);
  };

  const saveRoom = () => {
    currentRoom.label &&
      setPrinterRooms([
        ...printerRooms.filter((room) => room.key !== currentRoom.key),
        currentRoom,
      ]);
    setCurrentRoom({});
    setShowOption({});
  };

  const addRoom = () => {
    currentRoom.label &&
      setPrinterRooms([
        ...printerRooms,
        { label: currentRoom.label, key: `${Math.random()}` },
      ]);
    setCurrentRoom({});
    setShowOption({});
  };

  const deleteRoom = (room) => {
    setPrinterRooms(printerRooms.filter((rm) => rm.key !== room.key));
  };

  const sendPrint = sendMessageToSocket;

  const loading = {
    getRooms:
      getPrinterRoomsMutation.isLoading ||
      updateOrgPrinterRoomsMutation.isLoading,
  };

  return (
    <PrinterContext.Provider
      value={{
        loading,
        showOption,
        currentRoom,
        printerRooms,
        addRoom,
        saveRoom,
        sendPrint,
        deleteRoom,
        updateRooms,
        setShowOption,
        setCurrentRoom,
        handleGetPrinterRooms,
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
};

export default PrinterStore;
