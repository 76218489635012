import { useFormik } from "formik";
import * as Yup from "yup";

import { LoadDots } from "../../../Components/Loader";
import Modals from "../../../Components/Modals";
import * as S from "./styles";

const validationSchema = Yup.object({
  created_for: Yup.string().required("Por favor adicione o nome do cliente"),
});

export const StartSessionModal = ({
  show,
  onhide,
  loading,
  handleStartSession,
}) => {
  const formik = useFormik({
    initialValues: {
      created_for: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleStartSession(values);
      formik.resetForm();
    },
  });

  return (
    <Modals
      size="sm"
      backdrop="normal"
      curved
      show={show}
      onHide={() => {
        onhide();
        formik.resetForm();
      }}
    >
      <S.ModalHeader>Iniciar uma sessão</S.ModalHeader>

      <S.AutoModalContainer>
        <div className="form_holder">
          <S.StartSubText>Nome De Cliente</S.StartSubText>
          <S.Input
            name="created_for"
            value={formik.values.created_for}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Eg: Maria Eduarda , Lunar"
          />
          {formik.touched.created_for && formik.errors.created_for && (
            <S.ErrorText>{formik.errors.created_for}</S.ErrorText>
          )}
          <br />
          <br />
          <S.BlackButton onClick={formik.handleSubmit}>
            {loading.startSession ? <LoadDots isWhite /> : " Iniciar sessão"}
          </S.BlackButton>
          <br />
        </div>
      </S.AutoModalContainer>
    </Modals>
  );
};
