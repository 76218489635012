import { createContext } from "react";
import { getUserOrg } from "../Utils/localStorage";
import useActionCable from "../Hooks/useActionCable";
import { useMutation, useQuery } from "react-query";

import {
  deleteOrderItem,
  editOrderStatus,
  getOrder,
  getOrders,
  indexStatuses,
  searchOrders,
  updateOrderItem,
} from "../Api/OrderApi";
import { useState } from "react";
import useDebounce from "../Hooks/useDebounce";
import usePrinter from "../Hooks/usePrinter";
import { useContext } from "react";
import { controllerContext } from "./controllerCtx";
import { getOrgStatusRules, saveStatusRules } from "../Api/OrganizationApi";

export const OrderContext = createContext();

const OrderStore = ({ children }) => {
  const [enabledQueries, setEnabledQueries] = useState({
    statuses: false,
    indexOrders: false,
  });

  const slug = () => {
    const {
      organization: { slug },
    } = getUserOrg();

    return slug;
  };

  const defaultVals = {
    search: "",
    per_page: "10",
    status: "all",
    organization: slug(),
  };

  const [navigate, setNavigate] = useState();
  const [statuses, setStatuses] = useState([]);
  const [selected, setSelected] = useState();
  const [orderModals, setOrderModals] = useState({});
  const [statusRules, setStatusRules] = useState({});
  const [filters, setFilters] = useState(defaultVals);

  const [current, setCurrent] = useState({
    order: {},
    status: {},
    order_item: {},
  });

  const [orders, setOrders] = useState({
    pending: {
      data: [],
      pagination: {},
    },
    preparing: {
      data: [],
      pagination: {},
    },
    ready: {
      data: [],
      pagination: {},
    },
    delivered: {
      data: [],
      pagination: {},
    },
    completed: {
      data: [],
      pagination: {},
    },
  });

  const { sendPrint, printerRooms } = usePrinter({
    getRooms: slug() !== undefined,
  });

  const [controllerState, setControllerState] = useContext(controllerContext);

  const indexStatusesQuery = useQuery({
    enabled: enabledQueries.statuses && slug() !== undefined,
    refetchOnWindowFocus: true,
    queryKey: [orders],
    queryFn: () => indexStatuses(slug()),
    onSuccess: (res) => {
      const { data } = res;
      !selected && setSelected(data[0]);
      setStatuses(data);
    },
  });

  const indexOrdersQuery = useQuery({
    enabled: enabledQueries.indexOrders && filters.search === "",
    queryKey: [filters],
    refetchOnWindowFocus: false,
    queryFn: () => getOrders(filters),
    onSuccess: (res) => {
      const { data } = res;
      setOrders(data);
    },
  });

  const debouncedSearchTerm = useDebounce(filters.search, 1000);

  const handleSearch = () => {
    const params = {
      query: filters.search,
      per_page: filters.per_page,
      organization: slug(),
    };

    return searchOrders(params);
  };

  const handleSetOrder = (res) => {
    const {
      data: { data, status, pagination },
    } = res;

    setOrders({
      ...orders,
      [status]: {
        data: [...orders[status].data, ...data].filter(
          (item, index, self) =>
            index === self.findIndex((obj) => obj.id === item.id)
        ),
        pagination,
        status,
      },
    });
  };

  const searchOrdersQuery = useQuery({
    enabled: debouncedSearchTerm !== "",
    queryKey: ["search", debouncedSearchTerm, filters.per_page],
    queryFn: handleSearch,
    onSuccess: (res) => {
      const { data } = res;
      setOrders(data);
    },
  });

  const paginateOrdersMutation = useMutation({
    mutationFn: debouncedSearchTerm === "" ? getOrders : searchOrders,
    onSuccess: handleSetOrder,
  });

  const handlePagination = (status) => {
    setCurrent({ ...current, status: status });
    const page = Math.ceil(
      (orders?.[status]?.data.length + 1) / filters.per_page
    );
    const params = {
      page,
      status,
      organization: slug(),
      per_page: filters.per_page,
      query: debouncedSearchTerm,
    };
    paginateOrdersMutation.mutate(params);
  };

  const editOrderStatusMutation = useMutation({
    mutationFn: editOrderStatus,
    onSuccess: () => current?.order?.id && handleGetOrder(current?.order?.id),
  });

  const getOrderMutation = useMutation({
    mutationFn: getOrder,
    onSuccess: (res) => {
      const { data } = res;
      setCurrent({ order: data });
    },
  });

  const deleteItemMutation = useMutation({
    mutationFn: deleteOrderItem,
    onSuccess: () => {
      handleGetOrder(current?.order?.id);
    },
  });

  const updateOrderItemMutation = useMutation({
    mutationFn: updateOrderItem,
    onSuccess: (res) => {
      const { data } = res;
      setCurrent({ order: data });
      setOrderModals({ ...orderModals, item: false });
    },
  });

  const getStatusRulesMutation = useMutation({
    mutationFn: getOrgStatusRules,
    onSuccess: (res) => {
      const { data } = res;
      setStatusRules(data);
    },
    onError: (error) => console.log(error),
  });

  const saveRulesMutation = useMutation({
    mutationFn: saveStatusRules,
    onSuccess: (res) => {
      const { data } = res;
      setStatusRules(data);
    },
    onError: (error) => console.log(error),
  });

  const saveRules = () => {
    const values = {
      slug: slug(),
      params: { statuses_rules: statusRules },
    };
    saveRulesMutation.mutate(values);
  };

  const getStatusRules = () => {
    getStatusRulesMutation.mutate(slug());
  };

  const handleGetOrder = (id) => {
    getOrderMutation.mutate({ id, slug: slug() });
  };

  const handleEditStatus = (status, order) => {
    setCurrent({ order });
    editOrderStatusMutation.mutate({
      id: order.id,
      params: { organization: slug(), status: status.key },
    });
  };

  const handleModals = (key, value) => {
    setOrderModals({ ...orderModals, [key]: value });
  };

  const handleShowOrder = (order) => {
    const orderRoute = `?order_id=${order.id}`;
    navigate(orderRoute);
  };

  const editOrderItem = (order) => {
    updateOrderItemMutation.mutate({
      ...order,
      organization: slug(),
      add_ons: JSON.stringify(order.addOns),
    });
  };

  const actionCableParams = {
    channel: "OrdersChannel",
    slug: slug(),
  };

  const updateSocketOrders = (order, prev_status) => {
    setCurrent({ order: order });

    setOrders((prevOrderState) => {
      return {
        ...prevOrderState,

        // removing order from prev status
        [prev_status]: {
          ...prevOrderState[prev_status],
          data: prevOrderState[prev_status].data.filter(
            (cOrder) => cOrder.id !== order.id
          ),
        },

        // removing order from new status and readding updated
        [order.status]: {
          ...prevOrderState[order.status],
          data: [
            ...prevOrderState[order.status].data.filter(
              (cOrder) => cOrder.id !== order.id
            ),
            order,
          ],
        },
      };
    });
  };

  const deleteSocketOrders = (order, prev_status) => {
    setOrders((prevOrderState) => {
      return {
        ...prevOrderState,

        // removing order from status
        [prev_status]: {
          ...prevOrderState[prev_status],
          data: prevOrderState[prev_status].data.filter(
            (cOrder) => cOrder.id !== order.id
          ),
        },
      };
    });
  };

  const addSocketOrder = (order) => {
    setOrders((prevOrderState) => {
      return {
        ...prevOrderState,
        pending: {
          ...prevOrderState.pending,
          data: [
            ...prevOrderState.pending.data.filter(
              (cOrder) => cOrder.id !== order.id
            ),
            order,
          ],
        },
      };
    });
  };

  const handleReceivedUpdate = (data = {}) => {
    const {
      message: { type, order, prev_status },
    } = data;
    switch (type) {
      case "create":
        addSocketOrder(order);
        break;
      case "update":
        updateSocketOrders(order, prev_status);
        break;
      case "delete":
        deleteSocketOrders(order, prev_status);
        break;

      default:
        break;
    }
  };

  const handleEditPrinterRooms = () => {
    setControllerState({
      ...controllerState,
      modals: { showPrinterRooms: true },
    });
  };

  const handleSearchChange = (event) => {
    setFilters({ ...filters, search: event.target.value });
  };

  const handleDeleteItem = (id) => {
    const params = {
      id,
      params: {
        organization: slug(),
      },
    };
    deleteItemMutation.mutate(params);
  };

  const handlePrintOrder = (room, order) => {
    console.log(room, order);

    const sendParams = {
      message: { room, order },
      action: "send_print_order",
    };

    sendPrint(sendParams);
  };

  useActionCable(actionCableParams, handleReceivedUpdate);

  const loading = {
    statuses: indexStatusesQuery.isLoading,
    indexOrders: indexOrdersQuery.isLoading && !statuses.length,
    showOrder: getOrderMutation.isLoading || editOrderStatusMutation.isLoading,
    order: {
      current: current.order,
      isLoading: editOrderStatusMutation.isLoading,
    },
    status: {
      current: current.status,
      isLoading: paginateOrdersMutation.isLoading,
    },
    loadingAddToCart: updateOrderItemMutation.isLoading,
    searchOrders: indexOrdersQuery.isFetching || searchOrdersQuery.isFetching,
    getStatusRules: getStatusRulesMutation.isLoading,
    saveStatusRules: saveRulesMutation.isLoading,
  };

  return (
    <OrderContext.Provider
      value={{
        orders,
        filters,
        current,
        loading,
        selected,
        statuses,
        navigate,
        statusRules,
        orderModals,
        printerRooms,
        enabledQueries,
        saveRules,
        setFilters,
        setCurrent,
        setNavigate,
        setSelected,
        handleModals,
        editOrderItem,
        getStatusRules,
        setStatusRules,
        setOrderModals,
        handleGetOrder,
        handleShowOrder,
        handlePagination,
        handlePrintOrder,
        handleDeleteItem,
        handleEditStatus,
        setEnabledQueries,
        handleSearchChange,
        handleEditPrinterRooms,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderStore;
