import styled, { css, keyframes } from "styled-components";
import styles from "../../Assets/styles";

export const Container = styled.div`
  margin-top: 150px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Holder = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1040px;
  max-width: 1040px;
  height: 100vh;
  @media (max-width: ${1000}px) {
    width: 100vw;
    padding: 0px 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const BannerImage = styled.img`
  width: 60vw;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;

  @media (max-width: ${1000}px) {
    width: 95%;
    height: 250px;
  }
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 650;
  text-align: center;
  color: ${styles.OffBlack};
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  width: ${(props) => props.fill && "100%"};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const TablesHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 20px;
  width: 100%;
  .containers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
  }
`;

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
`;

export const Table = styled.div`
  margin: 5px;
  transition: 0.6s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  width: 120px;
  height: 100px;
  background-color: ${({ table }) =>
    table?.status === "AVAILABLE"
      ? "#c2e9dd"
      : table?.status === "UNAVAILABLE"
      ? "#fac1d9"
      : table?.status === "PENDING" && "#f7e9ef"};

  ${({ table }) =>
    table?.status === "PENDING" &&
    css`
      animation: ${blinkAnimation} 1.5s ease-in-out infinite;
    `};
  border-radius: 5px;
  cursor: pointer;

  span {
    font-size: 10px;
    font-weight: 600;
  }

  p {
    font-size: 15px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    span {
      font-size: 15px !important;
      font-weight: 700;
    }
  }

  :hover {
    opacity: 0.6;
  }

  @media (max-width: ${1000}px) {
    width: calc(100vw / 3 - 19px);
  }
`;

export const AddTable = styled(Table)`
  background-color: #e5dade;
  justify-content: center;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const TableTopSection = styled.div`
  gap: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  /* justify-content: flex-end; */
  .first_section {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    .search_holder {
      width: 400px;
      height: 40px;
      display: flex;
      border: 2px solid lightgrey;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      overflow: hidden;
      padding: 0px 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      svg {
        width: 20px;
        height: 20px;
      }

      .search_icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      input {
        border: 0;
        width: calc(100% - 20px);
        font-weight: 600;
        background-color: transparent;
        padding: 0px 7px;
        :focus {
          border: 0;
          outline: 0;
        }
      }

      @media (max-width: ${1000}px) {
        width: 67vw;
      }
    }

    .filters {
      position: relative;
      margin-left: auto;
    }

    .filter_holder {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      gap: 7px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      border: 2px solid lightgrey;
      border-radius: 5px;
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      @media (max-width: ${1000}px) {
        width: 30px;
        border: none;
        box-shadow: none;

        p {
          display: none;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .scanner_toggle {
    background-color: ${styles.IconGrey};
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: relative;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border: 2px solid lightgrey;
    .scanner_wrap {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 15;
      left: 15;
    }
    .scanner_icon {
      width: 15px;
      height: 15px;
    }
  }
`;

export const FiltersHolder = styled.div`
  width: 250px;
  position: absolute;
  right: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${styles.NeutralsWhite};
  z-index: 9;
  border-radius: 5px;
  gap: 50px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  .filters_header {
    display: flex;
    padding: 0px 10px;
    padding-top: 10px;
    align-items: end;
    justify-content: space-between;
    :nth-child(n + 2) {
      margin-top: 10px;
      border-top: 1px solid lightgray;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 15px;
      font-weight: 600;
    }
    span {
      font-size: 13px;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .check_holder {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    cursor: pointer;
    p {
      margin: 0;
      padding: 0;
    }
  }

  .table_capacity {
    border: 2px solid lightgray;
    width: 91%;
    margin: 10px;
    border-radius: 5px;
    padding: 0px 10px;
  }
`;

export const FilterIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: auto;
  background-color: ${({ name }) =>
    name === "Disponivel"
      ? "#c2e9dd"
      : name === "Occupado"
      ? "#fac1d9"
      : name === "Pendente"
      ? "#f7e9ef"
      : name === "Reservada" && "#5ad4f2"};
`;
