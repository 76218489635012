import { useNavigate } from "react-router-dom";
import { userLogout } from "../../Api/UserApi";
import * as S from "./styles";
import { getUser, getUserOrg, isLogin } from "../../Utils/localStorage";
import { hasPermission } from "../../Utils/helpers";
import { EDIT_ORG } from "../../Utils/GlobalVariables";

export const AcountRoutes = ({ avatar }) => {
  const navigate = useNavigate();
  const { role } = getUserOrg();

  const handleLogOut = () => {
    userLogout();
    navigate("/log-in");
  };

  const handleLogin = () => {
    navigate("/log-in");
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  const handleSwitchOrg = () => {
    navigate("/log-in");
  };

  const handleCreateOrg = () => {
    navigate("/create-organization");
  };

  const handleEditOrg = () => {
    navigate("/edit-organization");
  };

  return (
    <>
      {isLogin() ? (
        <>
          <S.UserNameHolder>
            <S.UserImage
              image={`${
                avatar.new ? URL.createObjectURL(avatar.new) : avatar.current
              }`}
            ></S.UserImage>
            <div>
              <p>{getUser()?.name}</p>
              <span>{getUserOrg()?.organization?.name}</span>
            </div>
          </S.UserNameHolder>

          <p onClick={() => navigate("/profile")}>Profile</p>
          <p onClick={() => handleLogOut()}>Log out</p>
          <p onClick={() => handleSwitchOrg()}>Switch Organization</p>
          {hasPermission(role.user_type, EDIT_ORG) && (
            <p onClick={() => handleEditOrg()}>Edit Organization</p>
          )}
          <p onClick={() => handleCreateOrg()}>Create New Organization</p>
        </>
      ) : (
        <>
          <S.UserNameHolder>
            <S.UserImage></S.UserImage>
            <p>Not signed in </p>
          </S.UserNameHolder>

          <p onClick={() => handleLogin()}>Log into your account</p>
          <p onClick={() => handleSignUp()}>Sign Up to Waithere</p>
        </>
      )}
    </>
  );
};

export const MenuRoutes = ({ setIsExpanded }) => {
  const navigate = useNavigate();

  return (
    <>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/home");
        }}
      >
        Home
      </p>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/builder");
        }}
      >
        Builder
      </p>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/orders");
        }}
      >
        Orders
      </p>
      <p>Reservations</p>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/team");
        }}
      >
        Team
      </p>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/menu");
        }}
      >
        Menu
      </p>
      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/pages/home");
        }}
      >
        My Pages
      </p>

      <p
        onClick={() => {
          setIsExpanded(false);
          navigate("/tables");
        }}
      >
        Tables
      </p>
    </>
  );
};
