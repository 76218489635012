/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from "react";
import { OrderContext } from "../Context/orderContext";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

const useOrder = ({ navigate, index, showStatusRules }) => {
  const context = useContext(OrderContext);
  const [searchParams] = useSearchParams();

  const {
    setNavigate,
    handleModals,
    enabledQueries,
    handleGetOrder,
    getStatusRules,
    setEnabledQueries,
  } = context;
  const order_id = useMemo(() => searchParams.get("order_id"), [searchParams]);

  const handleOrderIdChange = useCallback(() => {
    if (order_id) {
      handleGetOrder(order_id);
    }
    handleModals("order", order_id);
  }, [order_id]);

  useEffect(() => {
    handleOrderIdChange();
  }, [order_id]);

  useEffect(() => {
    setEnabledQueries({
      ...enabledQueries,
      indexOrders: index,
      statuses: true,
    });
  }, [index]);

  useEffect(() => {
    if (navigate) {
      const navigator = (route) => navigate(route);
      setNavigate(() => navigator);
    }
  }, []);

  useEffect(() => {
    showStatusRules && getStatusRules();
  }, [showStatusRules]);

  return context;
};

export default useOrder;
