import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  position: relative;
  width: 100%;
  min-height: 40px;
  outline: none;
  border-radius: 7px;
  border: 1px solid ${styles.MidGrey};
  /* padding: 0px 10px; */
  input {
    width: 100%;
    min-height: 40px;
    border: 0;
    outline: none;
    border-radius: 7px;
    padding: 0px 10px;
  }
`;

export const CollapseHolder = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  background: ${styles.NeutralsWhite};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 120px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Option = styled.div`
  gap: 20px;
  width: 100%;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* background-color: red; */
  border-bottom: 1px solid ${styles.MidGrey};
  p {
    margin: 0;
  }
  :hover {
    background-color: ${styles.LightGrey};
  }
`;

export const OrgImage = styled.img`
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 50%;
  background-color: ${styles.MidGrey};
  object-fit: cover;
`;
