import styled from "styled-components";
import styles from "../../../Assets/styles";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  max-height: 80vh;

  @media (max-width: ${1000}px) {
    max-height: 70vh;
  }
`;

export const AutoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  max-height: 80vh;

  @media (max-width: ${1000}px) {
    max-height: 70vh;
  }

  .flex_printer_holder {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;

    .printer_svg {
      cursor: pointer;
    }

    .down_options_holder {
      position: absolute;
      width: 200px;
      top: 35px;
      right: 2px;
      z-index: 999;
      background-color: white;
      border-radius: 0 0 10px 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media (max-width: ${1000}px) {
        left: 2px;
      }

      .down_opt {
        gap: 10px;
        height: 30px;
        margin: 10px 0;
        padding: 0px 30px;
        display: flex;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;

        :hover {
          opacity: 0.4;
        }
      }

      .down_opt_name {
        padding: 0;
        margin: 0;
        font-weight: 500 !important;
        display: flex;
        align-items: center;
        gap: 7px;
        svg {
          margin-bottom: 5px;
          width: 12px;
          height: 12px;
        }
      }

      .bordered {
        border-top: 1px solid lightgrey;
        svg {
          margin-top: 7px;
        }
      }
    }
  }

  .order_details_holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    border-bottom: 1px solid grey;
    padding-bottom: 15px;
    justify-content: space-between;

    .top_sec {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      justify-content: space-between;
    }

    .date {
      display: flex;
      align-items: end;
      font-size: 13px;
      margin: 0;
      gap: 5px;
      font-weight: 550;
      span {
        font-weight: 700;
        font-size: 13px;
      }
    }

    .status {
      height: 25px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${styles.Black};
      margin: 0;
      border-radius: 5px;
      padding: 0px 7px;
      gap: 5px;
      font-weight: 550;
      position: relative;
      cursor: pointer;
      span {
        font-weight: 700;
        font-size: 13px;
      }
    }
  }

  .items_holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;
    height: calc(100% - 130px);
    max-height: calc(100% - 130px);
    overflow: scroll;
  }

  .order_total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
    padding-top: 7px;
    p {
      margin: 0;
      font-size: 17px;
      font-weight: 700;
    }
  }

  .edit_svg {
    cursor: pointer;
  }

  .all_rules {
    width: 100%;
    height: 100%;
  }

  .rules_section {
    width: 100%;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    padding: 10px 0px;
    .rule_header {
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .rule {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
    p {
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }
`;

export const ModalHeader = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: ${styles.OffBlack};
  margin-top: -35px;
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${styles.DarkGrey};
  max-width: 300px;
  text-align: center;
  font-weight: 500;
`;

export const StartSubText = styled(SubText)`
  text-align: start;
  margin-bottom: 5px;
  margin-top: 10px;
  max-width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${styles.DarkGrey};
  border-radius: 10px;
  padding: 0px 10px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 0px 15px;
  width: ${(props) => props.fill && "100%"};
  height: 40px;
  box-sizing: content-box;
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const FullBlackButton = styled(BlackButton)`
  width: 100%;
`;

export const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 15px;
  object-fit: cover;
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 650;
  text-align: center;
  color: ${styles.OffBlack};
`;

export const OrderItemCard = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 7px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .item_top_sec {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    .item_image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 7px;
      background-color: ${styles.LightGrey};
    }

    .item_top_details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60px;
      width: calc(100% - 80px);

      .sub_sec {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
          font-weight: 700;
          max-width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item_name {
          max-width: 70%;
        }
        .actions_holder {
          gap: 20px;
          display: flex;
          cursor: pointer;
          .edit_svg {
            width: 20px;
            height: 20px;
          }
          .delete_svg {
            color: ${styles.Error};
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .add_ons {
    margin-top: 20px;
    border-bottom: 1px solid lightgray;

    .add_on {
      margin-bottom: 15px;
      .add_on_name {
        margin: 0;
        font-weight: 700;
        margin-left: 10px;
      }
      .add_on_holder {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 15px;
        gap: 20px;
        p {
          margin: 0;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }

  .item_total_holder {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 15px;
      font-weight: 700;
    }
  }
`;

export const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .delete_holder {
    display: flex;
    gap: 20px;
    width: 100%;
    padding-bottom: 10px;
    .delete {
      width: 100%;
      height: 40px;
      background-color: ${styles.Error};
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      font-weight: 700;
      cursor: pointer;
    }

    .cancel {
      width: 100%;
      height: 40px;
      background-color: ${styles.NeutralsWhite};
      color: black;
      border: 1px solid black;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
    }
  }
`;
