/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { TemplateUploader } from "../../Components/BgTemplateUploader";
import { Floors } from "../../Components/Floors";
import { MoveableItems } from "../../Components/MoveableItems";
import { ToolBar } from "../../Components/ToolBar";
import { builderContext } from "../../Context/builderCtx";
import { controllerContext } from "../../Context/controllerCtx";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as S from "./styles";
import { getBuilderState } from "../../Api/BuilderApi";
import { userContext } from "../../Context/userContext";
import { getUserOrg, isLogin, isOrg } from "../../Utils/localStorage";
import { useNavigate } from "react-router-dom";
import styles from "../../Assets/styles";

export const Builder = () => {
  const navigate = useNavigate();

  const [userState] = useContext(userContext);
  const [builderState, setBuilderState] = useContext(builderContext);
  let currentFloor = builderState.currentFloor;
  let floors = builderState.floors;
  const [controllerState] = useContext(controllerContext);

  useEffect(() => {
    // console.log(isOrg());
    !isOrg() && navigate("/log-in");
  }, []);

  // console.log(getUserOrg());
  // useEffect(() => {
  //   const toFetch = async () => {
  //     const { data } = await getBuilderState(getUserOrg().id);
  //     setBuilderState({
  //       ...JSON.parse(data.data.body),
  //       builderId: data.data.id,
  //     });
  //   };
  //   if (isOrg()) toFetch();
  // }, []);

  const addNewItem = (e) => {
    // setControllerState({ ...controllerState, focused: null });
    if (controllerState.isAdding && e.target.id === "room") {
      const id = Math.random();

      var rect = e.target.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element

      const newItem = {
        id: id,
        name: `${controllerState.isAdding.name}${id}`,
        type: controllerState.isAdding.name,
        position: {
          x: x - controllerState.isAdding.dimensions.width / 2,
          y: y - controllerState.isAdding.dimensions.height / 2,
        },
        dimensions: {
          height: controllerState.isAdding.dimensions.height,
          width: controllerState.isAdding.dimensions.width,
        },
        status: "available",
      };

      floors[currentFloor].items = floors[currentFloor].items
        ? [...floors[currentFloor]?.items, newItem]
        : [newItem];

      setBuilderState({
        ...builderState,
        floors: floors,
      });
    }
  };

  // const setMove = (e) => {
  //   setControllerState({
  //     ...controllerState,
  //     mousePosition: { x: e.pageX, y: e.pageX },
  //   });
  // };

  return (
    <>
      <S.Container>
        <ToolBar />

        <S.BGTemplateHolder>
          {!builderState.floors[currentFloor].bgImage && <TemplateUploader />}
          {builderState.floors[currentFloor].bgImage && (
            <>
              <S.FloorName>
                {builderState.floors[currentFloor].floorName}
              </S.FloorName>

              <TransformWrapper
                initialScale={10}
                initialPositionX={0}
                initialPositionY={0}
                disabled={!controllerState.canPan}
                panning={{ disabled: !controllerState.canPan }}
                centerZoomedOut={true}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                    <TransformComponent
                      wrapperStyle={{
                        width: "100%",
                        height: "100%",
                        background: styles.DarkGrey,
                      }}
                      contentStyle={{
                        width: "100000px",
                        height: "100000px",
                      }}
                    >
                      <S.BGTemplate
                        id="room"
                        image={builderState.floors[currentFloor].bgImage}
                        controls={controllerState}
                        onClick={(e) => addNewItem(e)}
                        // onMouseMove={(e) => setMove(e)}
                      >
                        <MoveableItems zoomScale={rest.state.scale} />
                      </S.BGTemplate>
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </>
          )}
        </S.BGTemplateHolder>

        <Floors />
      </S.Container>
    </>
  );
};
