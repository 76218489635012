/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { Home, MenuItemsPreview } from "waitherepackages";
import Modals from "../../Components/Modals";
import * as S from "./styles";
import { useQuickMenu } from "../../Hooks/useQuickMenu";
import { MenuItemCard } from "../../Components/Cards/MenuItemCard";
import { LoadDots } from "../../Components/Loader";
import { CheckBox } from "../../Components/Inputs";
import { ColoredButton } from "../../Components/Buttons";
import { useEffect } from "react";

export const HomePreview = ({
  currentBranding = {},
  updateBranding,
  editBranding,
}) => {
  const navigate = useNavigate();
  const menuProps = useQuickMenu();
  const {
    loading,
    showModals,
    menuItemsPreview,
    menuItemsPreviewIds,
    setShowModals,
    setMenuItemsPreview,
    setMenuItemsPreviewIds,
  } = menuProps;

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  useEffect(() => {
    setMenuItemsPreview({
      name: currentBranding?.itemsPreviewName || "Items",
      items: [],
    });
    setMenuItemsPreviewIds(currentBranding.menuItemsPreviewIds || []);
  }, [currentBranding?.menuItemsPreviewIds]);

  const handleUpdate = (values) => {
    const {
      about,
      bgColor,
      buttonColor,
      buttonTxtColor,
      font,
      textColor,
      headerImg,
      aboutImage,
      useImage,
      videoCode,
      aboutBgColor,
      aboutTxtColor,
    } = values;

    const editables = {
      headerImg,
      aboutImage,
      homepage: JSON.stringify({
        about,
        bgColor,
        buttonColor,
        buttonTxtColor,
        font,
        textColor,
        useImage,
        videoCode,
        aboutBgColor,
        aboutTxtColor,
        menuItemsPreviewIds,
        itemsPreviewName: menuItemsPreview.name,
      }),
    };

    updateBranding("homepage", editables);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
    handleEditItems: () => setShowModals({ itemsPreview: true }),
    handleEditAbout: () => setShowModals({ editAbout: true }),
  };

  return (
    <>
      <Home
        actions={actions}
        loading={loading}
        handleSave={handleUpdate}
        menuItems={menuItemsPreview}
        showDashBoard={true}
        isDefaultMoblie={false}
        currentValues={currentBranding}
      />
      <EditHomeMenuItems
        show={showModals.itemsPreview}
        hide={() => setShowModals({})}
        menuProps={menuProps}
      />

      <EditAboutModal
        show={showModals.editAbout}
        hide={() => setShowModals({})}
        editBranding={editBranding}
        aboutValues={currentBranding?.about}
      />
    </>
  );
};

const EditHomeMenuItems = ({ show, hide, menuProps }) => {
  const {
    searchedMenuItems,
    menuItemsPreview,
    menuItemsPreviewIds,
    loading: { loadingSearch, loadingItemsPreview },
    selectMenuItem,
    handleSearchChange,
    setMenuItemsPreview,
  } = menuProps;

  const setName = (e) => {
    const { value } = e.target;
    setMenuItemsPreview({
      ...menuItemsPreview,
      name: value,
    });
  };

  return (
    <Modals size="lg" curved show={show} onHide={hide}>
      <S.ModalHeader>Menu Items</S.ModalHeader>

      <S.FlexHolder>
        <div className="section">
          <S.Input
            placeholder="Display Name"
            onChange={setName}
            value={menuItemsPreview.name}
          />
        </div>
        <div className="section">
          <S.Input placeholder="Search" onChange={handleSearchChange} />
        </div>
      </S.FlexHolder>

      <hr />

      <MenuItemsPreview
        previewing={true}
        loading={loadingItemsPreview}
        menuItems={menuItemsPreview}
        handleItemClick={(id) => {
          selectMenuItem(id);
        }}
      />

      <hr />
      <S.AllMenuItemsHolder>
        {loadingSearch && (
          <S.MiniLoadHolder>
            <LoadDots />
          </S.MiniLoadHolder>
        )}
        {searchedMenuItems.length ? (
          searchedMenuItems?.map((menuItem) => (
            <S.CardHolder>
              <MenuItemCard key={menuItem.id} menuItem={menuItem} />
              <div
                className="check"
                onClick={() => {
                  selectMenuItem(menuItem.id);
                }}
              >
                <CheckBox checked={menuItemsPreviewIds.includes(menuItem.id)} />
              </div>
            </S.CardHolder>
          ))
        ) : (
          <S.MiniLoadHolder>
            <p>Nothing Found</p>
          </S.MiniLoadHolder>
        )}
      </S.AllMenuItemsHolder>
      <hr />

      <S.ButtonHolder>
        <ColoredButton
          variant={"white"}
          text={"Cancel"}
          onClick={() => {
            hide();
          }}
        />

        <ColoredButton
          variant={"continue"}
          text={"Save"}
          onClick={() => {
            hide();
          }}
        />
      </S.ButtonHolder>
    </Modals>
  );
};

const EditAboutModal = ({ show, hide, editBranding, aboutValues = {} }) => {
  const { name, bottomText } = aboutValues;
  return (
    <Modals size="sm" curved show={show} onHide={hide}>
      <S.ModalHeader>Edit About Section</S.ModalHeader>
      <S.Input
        placeholder="Header"
        value={name}
        onChange={(e) =>
          editBranding(e.target.value, "homepage", "about", "name")
        }
      />
      <br />
      <br />
      <S.TextInput
        placeholder="Sobre Nos"
        value={bottomText}
        onChange={(e) =>
          editBranding(e.target.value, "homepage", "about", "bottomText")
        }
      />

      <hr />

      <S.ButtonHolder>
        <ColoredButton
          variant={"white"}
          text={"Cancel"}
          onClick={() => {
            hide();
          }}
        />

        <ColoredButton
          variant={"continue"}
          text={"Save"}
          onClick={() => {
            hide();
          }}
        />
      </S.ButtonHolder>
    </Modals>
  );
};
