export const isLogin = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.token) return true;
  return false;
};

export const setLogin = (user) => {
  localStorage.clear();
  localStorage.setItem("user", JSON.stringify(user));
  setLoginTime();
};

export const logout = () => {
  localStorage.clear();
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setUserOrg = (org) => {
  if (isLogin) localStorage.setItem("userOrg", JSON.stringify(org));
};

export const isOrg = () => {
  const org = JSON.parse(localStorage.getItem("userOrg"));
  if (org?.id) return true;
  return false;
};

export const getUserOrg = () => {
  const defaultVals = {
    organization: {},
    role: {},
  };
  if (isLogin)
    return JSON.parse(localStorage.getItem("userOrg")) || defaultVals;
  return defaultVals;
};

export const setLoginTime = () => {
  var now = new Date().getTime();
  localStorage.setItem("setupTime", now);
};

export const verifyLogIn = () => {
  var hours = 24 * 1; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)

  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");

  if (setupTime == null) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
