/* eslint-disable default-case */
import { api } from ".";
import { getUser, getUserOrg } from "../Utils/localStorage";

export const getBranding = async () => {
  const user = getUser();
  const {
    organization: { id },
  } = getUserOrg();

  return await api.get(`/api/v1/branding/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const saveBranding = async (type, values) => {
  const {
    homepage,
    navbar,
    footer,
    menu,
    quickaccesser,
    hours,
    contact,
    headerImg,
    aboutImage,
  } = values;
  const formData = new FormData();

  switch (type) {
    case "homepage":
      formData.append("homepage", homepage);
      headerImg?.new && formData.append("headerImg", headerImg.new);
      aboutImage?.new && formData.append("aboutImage", aboutImage.new);
      break;

    case "navbar":
      formData.append("navbar", navbar);
      break;

    case "footer":
      formData.append("footer", footer);
      formData.append("hours", hours);
      formData.append("contact", contact);
      break;

    case "menu":
      formData.append("menu", menu);
      break;

    case "quickaccesser":
      formData.append("quickaccesser", quickaccesser);
      break;
  }

  const user = getUser();
  const {
    organization: { id },
  } = getUserOrg();

  return await api.patch(`/api/v1/branding/${id}`, formData, {
    headers: {
      Authorization: user?.token,
      "Content-Type": "multipart/form-data",
    },
  });
};
