import { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { menuimage } from "../../Assets/images/images";
import { LoadDots } from "../../Components/Loader";
import { NavSlider } from "../../Components/NavSlider";
import { TopNav } from "../../Components/TopNav";
import useMenu from "../../Hooks/useMenu";
import { CreateEditCategoryModal } from "./CreateEditCategoryModal";
import { ExpandedCategory } from "./ExpandedCategory";
import * as S from "./styles";
import { FiClipboard } from "react-icons/fi";
import { CreateEditMenuModal } from "./CreateEditMenuModal";
import { DeleteModal } from "./DeleteModal";
import { CreateEditMenuItemModal } from "./CreateEditMenuItemModal";

export const Menu = () => {
  const [showModal, setShowModal] = useState({});
  const {
    menus,
    menuItems,
    categories,
    selectedCategory,
    selectedMenu,
    isLoading,
    itemToDelete,
    selectedMenuItem,
    setSelectedMenuItem,
    fetchMenuItems,
    setItemDelete,
    setSelectedMenu,
    createMenu,
    updateMenu,
    handleDelete,
    updateCategory,
    setSelectedCategory,
    createCatergory,
    handleUpdateMenuItem,
    handleCreateMenuItem,
    fetchMenusUnderCategory,
  } = useMenu({ isPrivate: true });

  return (
    <S.Container>
      <TopNav />
      {isLoading.categories && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!isLoading.categories && (
        <>
          {!categories?.length && (
            <S.Holder>
              <S.BannerImage src={menuimage} />
              <br />
              <S.BoldText>Voce nao tem nenhuma categoria.</S.BoldText>
              <br />
              <S.SubText>
                Para criar seu cardapio, primeiro crie categorias.
              </S.SubText>
              <br />
              <S.BlackButton
                onClick={() => setShowModal({ createCategory: true })}
              >
                Create Category
              </S.BlackButton>
            </S.Holder>
          )}

          {categories?.length > 0 && (
            <S.Holder>
              <S.TopSec>
                <S.MenuLogo>
                  <FiClipboard />
                </S.MenuLogo>
                <S.NavHolder>
                  <NavSlider
                    selected={selectedCategory}
                    setSelected={setSelectedCategory}
                    options={categories}
                  />
                </S.NavHolder>
                <S.AddNewBtn
                  onClick={() => setShowModal({ createCategory: true })}
                >
                  <IoMdAdd />
                </S.AddNewBtn>
              </S.TopSec>

              <ExpandedCategory
                menus={menus}
                menuItems={menuItems}
                setSelectedMenu={setSelectedMenu}
                isLoading={isLoading}
                setShowModal={setShowModal}
                selectedSection={selectedCategory}
                setItemDelete={setItemDelete}
                fetchMenuItems={fetchMenuItems}
                fetchMenusUnderCategory={fetchMenusUnderCategory}
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
              />
            </S.Holder>
          )}

          <CreateEditCategoryModal
            showModal={showModal}
            setShowModal={setShowModal}
            updateCategory={updateCategory}
            createCatergory={createCatergory}
            isLoading={isLoading.modal}
            selectedCategory={selectedCategory}
          />

          <CreateEditMenuModal
            showModal={showModal}
            setShowModal={setShowModal}
            updateMenu={updateMenu}
            createMenu={createMenu}
            isLoading={isLoading.modal}
            selectedMenu={selectedMenu}
            selectedCategory={selectedCategory}
          />

          <CreateEditMenuItemModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCategory={selectedCategory}
            selectedMenu={selectedMenu}
            isLoading={isLoading.modal}
            selectedMenuItem={selectedMenuItem}
            handleCreateMenuItem={handleCreateMenuItem}
            handleUpdateMenuItem={handleUpdateMenuItem}
          />

          <DeleteModal
            showModal={showModal}
            isLoading={isLoading.modal}
            itemToDelete={itemToDelete}
            handleDelete={handleDelete}
            setShowModal={setShowModal}
            selectedCategory={selectedCategory}
          />
        </>
      )}
    </S.Container>
  );
};
