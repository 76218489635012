/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useState } from "react";
import { isLogin } from "../../Utils/localStorage";
import { FilledButton } from "../Buttons";
import Modals from "../Modals";
import * as S from "./styles";
import * as Yup from "yup";
import { userSigUp } from "../../Api/UserApi";

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short."),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const SignUpModal = ({
  controllerState,
  setControllerState,
  canEdit,
}) => {
  const [pageState, setPageState] = useState({});

  const signUp = async (values) => {
    setPageState({ ...pageState, isLoading: { signUp: true } });
    const { data } = await userSigUp(values);
    try {
      if (data.status?.code === 200) {
        setControllerState({
          ...controllerState,
          modals: { login: true, signup: false },
        });
      }
      setPageState({ ...pageState, isLoading: { signUp: false } });
    } catch {
      setPageState({ ...pageState, isLoading: { signUp: false } });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: controllerState?.user?.email,
      password: "",
      confirm_password: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: (values, formikBag) => {
      signUp(values);
    },
    enableReinitialize: true,
  });

  const goToLogIn = () => {
    setControllerState({
      ...controllerState,
      modals: { login: true, signup: false },
    });
  };
  return (
    <Modals
      size="sm"
      curved
      show={controllerState.modals?.signup}
      backdrop="static"
      onHide={() => {
        if (isLogin())
          setControllerState({ ...controllerState, modals: { signup: false } });
      }}
    >
      <S.ModalHeader>Sign up to Waithere</S.ModalHeader>

      <br />

      <S.InputHolder>
        <p>Name</p>
        <S.Input
          name="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <S.Error>{formik.errors.name}</S.Error>
      </S.InputHolder>

      <S.InputHolder>
        <p>Email</p>
        <S.Input
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={canEdit ? formik.handleChange : undefined}
        />
        <S.Error>{formik.errors.email}</S.Error>
      </S.InputHolder>

      <S.InputHolder>
        <p>Password</p>
        <S.Input
          name="password"
          placeholder="Password"
          type={"password"}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <S.Error>{formik.errors.password}</S.Error>
      </S.InputHolder>

      <S.InputHolder>
        <p>Confirm Password</p>
        <S.Input
          name="confirm_password"
          placeholder="confirm password"
          type={"password"}
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
        />
        <S.Error>{formik.errors.confirm_password}</S.Error>
      </S.InputHolder>

      <br />
      <FilledButton
        isLoading={pageState?.isLoading?.signUp}
        variant={"login"}
        text={"Sign Up"}
        onClick={() => formik.handleSubmit()}
      />
      <S.Text>
        Already have an account? <span onClick={goToLogIn}>Log In</span>
      </S.Text>
    </Modals>
  );
};
