import { useContext, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addToCart,
  getMenuItemsPreview,
  getPublicMenuCategories,
  getPublicMenuItems,
  searchMenuItems,
  updateCart,
} from "../Api/MenuApi";
import useDebounce from "./useDebounce";
import { getUserOrg } from "../Utils/localStorage";
import { TableContext } from "../Context/tableContext";
import { placeOrder } from "../Api/OrderApi";
import { controllerContext } from "../Context/controllerCtx";

export const useQuickMenu = () => {
  const { getTable, tableCart, setTableCart } = useContext(TableContext);
  const currentCart = useMemo(() => tableCart, [tableCart]);
  const [controllerState, setControllerState] = useContext(controllerContext);

  const [enabledMenuQuery, setEnabledMenuQuery] = useState(true);
  const [menuCategories, setMenuCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: 0,
  });

  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState({});
  const [showModals, setShowModals] = useState({
    menuItem: false,
    itemsPreview: false,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [startSearching, setStartSearching] = useState(false);

  const [menuItemsPreviewIds, setMenuItemsPreviewIds] = useState([]);
  const [menuItemsPreview, setMenuItemsPreview] = useState({
    items: [],
    name: "Menu Items",
  });

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const categoriesQuery = useQuery({
    enabled: enabledMenuQuery,
    refetchOnWindowFocus: false,
    queryKey: ["category"],
    queryFn: getPublicMenuCategories,
    onSuccess: (res) => onCategoriesSuccess(res),
  });

  const onCategoriesSuccess = (res) => {
    const { data } = res;
    setMenuCategories(data);
    if (data.length) {
      setSelectedCategory(data[0]);
      data[0]?.id && menuMutation.mutate(data[0]?.id);
    }
    setEnabledMenuQuery(false);
  };

  const handleCategoryChange = (cat) => {
    menuMutation.mutate(cat.id);
    setSelectedCategory(cat);
  };

  const menuMutation = useMutation({
    mutationFn: getPublicMenuItems,
    onSuccess: (res) => {
      const { data } = res;
      setMenuItems(data);
    },
    onError: (error) => console.log(error),
  });

  const handleSearch = () => {
    const {
      organization: { slug },
    } = getUserOrg();

    const query = {
      name: debouncedSearchTerm,
      organization: slug,
    };

    return searchMenuItems({ query });
  };

  const handlePreviews = () => {
    const params = {
      items_ids: menuItemsPreviewIds,
    };
    return getMenuItemsPreview(params);
  };

  const searchItems = useQuery({
    enabled: startSearching,
    queryKey: ["search", debouncedSearchTerm, startSearching],
    queryFn: handleSearch,
    onSuccess: (res) => {
      const { data } = res;
      setSearchedMenuItems(data);
    },
  });

  const handleShowSearch = () => {
    setSearchTerm("");
    setShowModals({ searchMenu: true });
  };

  const hideSearchMenuItem = () => {
    setSearchTerm("");
    setStartSearching(false);
    setShowModals({ searchMenu: false });
  };

  const handleSearchChange = (event) => {
    setStartSearching(true);
    setSearchTerm(event.target.value);
  };

  const itemsPreviewIdsQuery = useQuery({
    refetchOnMount: false,
    enabled: menuItemsPreviewIds.length > 0,
    queryKey: ["previews", menuItemsPreviewIds.length],
    queryFn: handlePreviews,
    onSuccess: (res) => {
      const { data } = res;
      setMenuItemsPreview({ ...menuItemsPreview, items: data });
    },
  });

  const addToCartSucces = (res) => {
    const { data } = res;
    setShowModals({});

    setTableCart({ ...tableCart, cart: data });

    getTable(data.table_no);
  };

  const addToCartMutation = useMutation({
    mutationFn: addToCart,
    onSuccess: addToCartSucces,
    onError: (error) => console.log(error),
  });

  const handleAddToCart = (values) => {
    const { price, menu_item_id, quantity, addOns } = values;
    const params = {
      price,
      quantity,
      menu_item_id,
      organization: "null",
      token: currentCart.sessionToken,
      add_ons: JSON.stringify(addOns),
    };

    addToCartMutation.mutate(params);
  };

  const updateCartMutation = useMutation({
    mutationFn: updateCart,
    onSuccess: (res) => {
      const { data } = res;
      setTableCart({ ...currentCart, cart: data });
      getTable(currentCart.cart.table_no);
    },
    onError: (error) => console.log(error),
  });

  const handleQuantity = (id, quantity) => {
    const {
      organization: { slug },
    } = getUserOrg();

    updateCartMutation.mutate({
      quantity,
      id,
      organization: slug,
    });
  };

  const placeOrderMutation = useMutation({
    mutationFn: placeOrder,
    onSuccess: (res) => {
      getTable(currentCart.cart.table_no);
      setControllerState({ ...controllerState, modals: { showMenu: false } });
    },
    onError: (error) => console.log(error),
  });

  const handlePlaceOrder = () => {
    const {
      organization: { slug },
    } = getUserOrg();
    const params = {
      organization: slug,
      token: currentCart.sessionToken,
    };
    placeOrderMutation.mutate(params);
  };

  const selectMenuItem = (id) => {
    setMenuItemsPreviewIds(
      menuItemsPreviewIds.includes(id)
        ? menuItemsPreviewIds.filter((item) => item !== id)
        : [...menuItemsPreviewIds, id]
    );
  };

  const loadingCategories = categoriesQuery.isLoading;
  const loadingMenuItems = menuMutation.isLoading;
  const loadingSearch = searchItems.isLoading;
  const loadingItemsPreview = itemsPreviewIdsQuery.isLoading;
  const loadingAddToCart = addToCartMutation.isLoading;

  const loading = {
    loadingCategories,
    loadingMenuItems,
    loadingSearch,
    loadingItemsPreview,
    loadingAddToCart,
  };

  return {
    loading,
    menuItems,
    showModals,
    currentCart,
    menuCategories,
    selectedCategory,
    selectedMenuItem,
    menuItemsPreviewIds,
    menuItemsPreview,
    searchedMenuItems,
    handlePlaceOrder,
    handleQuantity,
    setShowModals,
    selectMenuItem,
    handleAddToCart,
    hideSearchMenuItem,
    handleShowSearch,
    setSelectedMenuItem,
    setSelectedCategory,
    setMenuItemsPreview,
    handleCategoryChange,
    handleSearchChange,
    setMenuItemsPreviewIds,
  };
};
