import { useState } from "react";
import * as S from "./styles";
import { FiChevronDown } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { ImPencil } from "react-icons/im";
import { TbTrash } from "react-icons/tb";
import { Fade } from "react-bootstrap";
import { ALLDAYSOFWEEK } from "../../Utils/GlobalVariables";
import { LoadDots } from "../Loader";
import { MenuItemCard } from "./MenuItemCard";

export const ExpandableMenuCard = ({
  menu,
  menuItems,
  setShowModal,
  setSelectedMenu,
  setItemDelete,
  fetchMenuItems,
  isLoading,
  selectedMenuItem,
  setSelectedMenuItem,
}) => {
  const [expand, setExpand] = useState(false);
  const today = new Date();

  return (
    <S.Holder isActive={menu.days_of_week?.includes(today.getDay())}>
      <S.TopSectionHolder>
        <S.TopSection>
          <S.MenuName>{menu.name}</S.MenuName>
          <p>{menu.items_count} items</p>
        </S.TopSection>

        <Fade in={expand} unmountOnExit>
          <S.TopSection expanded={expand}>
            <p>Actions:</p>
            <div className="actions-holder">
              <ImPencil
                onClick={() => {
                  setSelectedMenu(menu);
                  setShowModal({ editMenu: true });
                }}
              />
              <TbTrash
                className="delete-icon"
                onClick={() => {
                  setItemDelete({ type: "menu", values: menu });
                  setShowModal({
                    deleteCategory: true,
                  });
                }}
              />
            </div>
          </S.TopSection>
        </Fade>

        <S.TopSection expanded={expand}>
          <p className="time-frame">
            Available:
            {menu.days_of_week.map(
              (day, i) => ` ${i ? ", " : ""}${ALLDAYSOFWEEK[day].fullLabel} `
            )}
            , from 1pm to 3pm
          </p>
          <FiChevronDown
            className="drop-down-icon"
            onClick={async () => {
              setExpand(!expand);
              !expand && (await fetchMenuItems(menu.id, menu.name));
            }}
          />
        </S.TopSection>
      </S.TopSectionHolder>

      <S.MenuItemsHolder in={expand}>
        <div>
          <S.MenuItemsCont>
            <S.TopSection>
              <S.MenuName>Items</S.MenuName>
              <S.AddNewBtn
                onClick={() => {
                  setSelectedMenu(menu);
                  setShowModal({ createMenuItem: true });
                }}
              >
                <IoMdAdd />
              </S.AddNewBtn>
            </S.TopSection>

            {isLoading === menu.id && (
              <S.LoadHolder>
                <LoadDots />
              </S.LoadHolder>
            )}
            <br />
            <S.AllMenuItemsHolder>
              {isLoading !== menu.id &&
                menuItems[`${menu.id}`]?.map((menuItem) => (
                  <MenuItemCard
                    key={menuItem.id}
                    menuItem={menuItem}
                    onClick={() => {
                      setSelectedMenuItem(menuItem);
                      setShowModal({ editMenuItem: true });
                    }}
                  />
                ))}
            </S.AllMenuItemsHolder>

            {isLoading !== menu.id && !menuItems[`${menu.id}`]?.length && (
              <S.NoMenuItems>No Menu Items Here</S.NoMenuItems>
            )}
          </S.MenuItemsCont>
        </div>
      </S.MenuItemsHolder>
    </S.Holder>
  );
};
