import QrReader from "react-qr-scanner";
import Modals from "../Modals";
import * as S from "./styles";
import { IoIosQrScanner } from "react-icons/io";

export const QrScannerModal = ({ show, onHide }) => {
  return (
    <Modals size="sm" curved show={show} backdrop="static" onHide={onHide}>
      <S.ModalHeader>Ler Codigo</S.ModalHeader>
      <p>aponte seu dispositivo na frente do código para escanear o código</p>
      <S.ModalBody>
        <S.ScannerBox>
          <S.ScannerHolder>
            <QrReader
              delay={300}
              style={{ width: "150%" }}
              onError={(e) => console.log(e)}
              onScan={(s) => !!s?.text && window.open(s?.text, "_blank")}
              constraints={{
                video: { facingMode: "environment" },
              }}
            />
          </S.ScannerHolder>

          <IoIosQrScanner className="scanner_icon" />
        </S.ScannerBox>
      </S.ModalBody>
    </Modals>
  );
};
