import * as S from "./styles";

export function ProgressIndicator({ dots = [], quantity = 0, current = 0 }) {
  for (var i = 1; i <= quantity; i++) {
    dots.push(
      <div
        key={i}
        className={i < current ? "dot" : i === current ? "current" : "after"}
      />
    );
  }
  return <S.Dots className="App">{dots}</S.Dots>;
}
