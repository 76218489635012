import { Fade } from "react-bootstrap";
import { LoadDots } from "../../Components/Loader";
import * as S from "./styles";
import { ImPencil } from "react-icons/im";
import { TbTrash } from "react-icons/tb";
import { emptymenusimg } from "../../Assets/images/images";
import { ExpandableMenuCard } from "../../Components/Cards/ExpandaleMenuCard";
import { BiAddToQueue } from "react-icons/bi";
export const ExpandedCategory = ({
  isLoading,
  selectedSection,
  setSelectedMenu,
  setShowModal,
  setItemDelete,
  fetchMenuItems,
  menus,
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
}) => {
  return (
    <>
      <Fade in={isLoading.menus} unmountOnExit>
        <S.AbsoluteHolder>
          <LoadDots />
        </S.AbsoluteHolder>
      </Fade>

      <Fade in={!isLoading.menus && selectedSection?.id} unmountOnExit>
        <S.MenusHolder>
          <S.MenuTopSection>
            <S.MenusName>{selectedSection?.name}</S.MenusName>
            <div className="svg_holder">
              <BiAddToQueue
                onClick={() => {
                  setShowModal({ createMenu: true });
                  setSelectedMenu({});
                }}
              />
              <ImPencil onClick={() => setShowModal({ editCategory: true })} />
              <TbTrash
                onClick={() => {
                  setItemDelete({ type: "category", values: selectedSection });
                  setShowModal({ deleteCategory: true });
                }}
              />
            </div>
          </S.MenuTopSection>

          <br />
          {menus?.length !== 0 && (
            <S.AllMenuHolder>
              {menus?.map((menu) => (
                <ExpandableMenuCard
                  key={menu.id}
                  menu={menu}
                  menuItems={menuItems}
                  setShowModal={setShowModal}
                  setSelectedMenu={setSelectedMenu}
                  setItemDelete={setItemDelete}
                  isLoading={isLoading.menuItems}
                  fetchMenuItems={fetchMenuItems}
                  selectedMenuItem={selectedMenuItem}
                  setSelectedMenuItem={setSelectedMenuItem}
                />
              ))}
            </S.AllMenuHolder>
          )}

          {!menus?.length && (
            <>
              <S.BannerImage src={emptymenusimg} />
              <br />
              <S.BoldText>
                You dont have any menus under the {selectedSection?.name}{" "}
                category .
              </S.BoldText>
              <br />
              <S.BlackButton onClick={() => setShowModal({ createMenu: true })}>
                Create New Menu
              </S.BlackButton>
            </>
          )}
        </S.MenusHolder>
      </Fade>
    </>
  );
};
