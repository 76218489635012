import { GrFormNextLink } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { notFoundImage } from "../../Assets/images/images";
import * as S from "./styles";

export const NotFound = ({ error, route, routeText }) => {
  const navigate = useNavigate();

  return (
    <S.Holder>
      <img src={notFoundImage} alt="404 not found" />
      <p>{error} </p>
      <div onClick={() => navigate(route)}>
        {routeText} <GrFormNextLink />
      </div>
    </S.Holder>
  );
};
