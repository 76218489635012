const colors = {
  Blue: "#3451D3",
  Peach: "#EE736B",
  NavyBlue: "#010235",
  LightBlue: "#4063FF",
  LightPeach: "#FF8F89",
  DarkGrey: "#575757",
  MidGrey: "#C4C4C4",
  LightGrey: "#EAEAEA",
  SuccessGreen: "#27A543",
  Error: "#D33E34",
  NeutralsWhite: "#FFFFFF",
  Black: "#000000",
  OffBlack: "#19191C",
  OffGrey: "#F9F9F9",
  BgBlue: "#394251",
  DarkBlue: "#111825",
  FocusedGrey: "#282F3B",
  IconGrey: "#b8bbc0",
  modalBg: "#1C1C1E",
  lightPurple: "#EFEBFD",
  borderPurple: "#6239EB",
  acceptGreen: "#4DB862",
  lightGreen: "#f0f7f1",
};

const modesColor = {
  background: "#F3F3F4",
};

const styles = {
  ...colors,
  ...modesColor,
};

export default styles;

// store for tables GiRoundTable
