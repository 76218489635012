import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const getRoles = async (organization_id) => {
  const user = getUser();
  return await api.get(`/api/v1/roles/`, {
    headers: {
      Authorization: user?.token,
    },
  });
};
