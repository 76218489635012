import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  align-self: center;
  box-sizing: border-box;
  width: 80px;
  min-width: 80px;
  max-height: 90vh;
  min-height: 80px;
  max-width: 80px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${styles.DarkBlue};
  padding: 10px 0px;
  gap: 10px;
  border-right: ${(props) => props.expanded && `1px solid ${styles.DarkGrey}`};
`;

export const FloorHolder = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 10px;
    font-weight: 600;
    color: ${styles.NeutralsWhite};
  }
`;

export const Floor = styled.div`
  transition: 0.2s;
  width: 70px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${styles.FocusedGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: ${(props) => props.focused && `1px solid ${styles.IconGrey}`};
  svg {
    color: ${styles.IconGrey};
    width: 25px;
    height: 25px;
  }
`;
