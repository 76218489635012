import styled from "styled-components";

export const Holder = styled.div`
  max-height: 80vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .menu_container {
    max-width: 100% !important;
    min-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;

    .menu_controller {
      padding: 0 !important;
    }

    .menu-holder {
      max-width: 100% !important;
      min-width: 100% !important;
    }

    .items_holder {
      justify-content: center;
    }
  }
  position: relative;
  .access_container {
    max-height: 0px;
    max-width: 70px !important;
    overflow: hidden;
    .quick_acess_holder {
      visibility: hidden;
      position: fixed;
      left: calc(50% - 92px);

      .modal_content {
        background-color: black !important;
      }
    }
  }
`;

export const CartButtonHolder = styled.div`
  cursor: pointer;
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .quantity {
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
    font-size: 11px;
    color: white;
    z-index: 1;
  }

  svg {
    position: absolute;
    width: 25px;
    height: 25px;
  }
`;
