import { api } from ".";
import { getUser } from "../Utils/localStorage";

const user = getUser();

export const getOrg = async (organization_id) =>
  await api.get(`/api/v1/organization/${organization_id}`, {
    headers: {
      Authorization: user?.token,
    },
  });

export const createOrganization = async (param) => {
  const {
    name,
    slug,
    address,
    country,
    cep,
    state,
    phone,
    email,
    city,
    newLogo,
    categories,
  } = param;

  const formData = new FormData();

  formData.append("name", name);
  formData.append("slug", slug);
  formData.append("address", address);
  formData.append("country", country);
  formData.append("cep", cep);
  formData.append("state", state);
  formData.append("city", city);
  formData.append("phone", phone);
  formData.append("email", email);
  newLogo && formData.append("logo", newLogo);

  categories.map((category) => formData.append("categories[]", category.id));

  return await api.post(`/api/v1/organization/`, formData, {
    headers: {
      Authorization: user?.token,
      "content-type": "multipart/form-data",
    },
  });
};

export const editOrganization = async (param) => {
  const {
    id,
    name,
    slug,
    address,
    country,
    cep,
    state,
    phone,
    email,
    city,
    newLogo,
    categories,
  } = param;
  const formData = new FormData();

  formData.append("id", id);
  formData.append("name", name);
  formData.append("slug", slug);
  formData.append("address", address);
  formData.append("country", country);
  formData.append("cep", cep);
  formData.append("state", state);
  formData.append("city", city);
  formData.append("phone", phone);
  formData.append("email", email);
  newLogo && formData.append("logo", newLogo);

  categories.map((category) => formData.append("categories[]", category.id));

  const response = await api.patch(`/api/v1/organization/${id}`, formData, {
    headers: {
      Authorization: user?.token,
      "content-type": "multipart/form-data",
    },
  });
  return response;
};

export const getUserOrgs = async (id) => {
  const user = getUser();
  const response = await api.get(
    `/api/v1/organization/user_organizations/${id}`,
    {
      headers: {
        Authorization: user?.token,
        "content-type": "multipart/form-data",
      },
    }
  );
  return response;
};

export const getOrgPrinterRooms = async (slug) => {
  const user = getUser();
  const response = await api.get(`/api/v1/organization/printer_rooms/${slug}`, {
    headers: {
      Authorization: user?.token,
    },
  });
  return response;
};

export const updateOrgPrinterRooms = async ({ slug, params }) => {
  const user = getUser();
  const response = await api.put(
    `/api/v1/organization/update_printer_rooms/${slug}`,
    params,
    {
      headers: {
        Authorization: user?.token,
      },
    }
  );
  return response;
};

export const getOrgStatusRules = async (slug) => {
  const user = getUser();
  const response = await api.get(`/api/v1/organization/status_rules/${slug}`, {
    headers: {
      Authorization: user?.token,
    },
  });
  return response;
};

export const saveStatusRules = async ({ slug, params }) => {
  const user = getUser();
  const response = await api.put(
    `/api/v1/organization/update_status_rules/${slug}`,
    params,
    {
      headers: {
        Authorization: user?.token,
      },
    }
  );
  return response;
};
