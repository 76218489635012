/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { ColoredButton } from "../../Components/Buttons";
import { LoadDots } from "../../Components/Loader";
import Modals from "../../Components/Modals";
import { getUserOrg } from "../../Utils/localStorage";
import * as S from "./styles";

export const DeleteMember = ({
  roles,
  memberToDelete,
  showModal,
  setShowModal,
  permisionIcon,
  deleteMember,
}) => {
  const [modalState, setModalState] = useState({});

  const reset = () => {
    setShowModal({ ...showModal, deleteMember: false });
  };

  const handleDelete = async () => {
    const params = {
      teammate_id: memberToDelete.teammate_id,
    };
    setModalState({ isLoading: true });
    await deleteMember(params);
    setModalState({ isLoading: false });
    reset();
  };

  useEffect(() => {
    setModalState({ ...modalState, member: memberToDelete });
  }, [memberToDelete]);

  // console.log(modalState);

  return (
    <>
      <Modals
        size="sm"
        curved
        show={showModal.deleteMember}
        onHide={() => {
          reset();
        }}
        dark
      >
        <br />
        {modalState.isLoading && (
          <S.ModalLoadHolder>
            <LoadDots isWhite />
          </S.ModalLoadHolder>
        )}
        {/* {!modalState.isLoading && ( */}
        <div>
          <S.ModalHeader>Delete Team Member</S.ModalHeader>
          <S.ButtonHolder center>
            <S.WarningIcon />
          </S.ButtonHolder>

          <S.CenteredTextContent>Are you sure ?</S.CenteredTextContent>
          <S.TextContent center>
            Do you want to remove <span>{modalState.member?.user?.name}</span>{" "}
            from the team ?
          </S.TextContent>

          <S.TextContent center>
            They will be removed from the organization and will not have any
            access to it !
          </S.TextContent>

          <S.ButtonHolder>
            <ColoredButton
              variant={"white"}
              text={"Cancel"}
              onClick={() => {
                reset();
              }}
            />

            <ColoredButton
              variant={"reject"}
              text={"Finish"}
              onClick={() => {
                handleDelete();
              }}
            />
          </S.ButtonHolder>
          <br />
        </div>
        {/* )} */}
      </Modals>
    </>
  );
};
