import { Oval, ThreeDots } from "react-loader-spinner";
import styles from "../../Assets/styles";
import * as S from "./styles";

export const LoadSpiner = ({ isWhite }) => {
  return (
    <S.Holder>
      <Oval
        height={40}
        width={40}
        color={isWhite ? styles.NeutralsWhite : styles.OffBlack}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={styles.NeutralsWhite}
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </S.Holder>
  );
};

export const LoadDots = ({ isWhite }) => {
  return (
    <S.Holder>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color={isWhite ? styles.NeutralsWhite : styles.OffBlack}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </S.Holder>
  );
};
