import * as S from "./styles";
import React, { useState, useEffect } from "react";

const Timer = ({ startTime }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference(startTime));
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [startTime]);

  const calculateTimeDifference = (startTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const difference = now - start;

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  };

  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference(startTime)
  );

  const { hours, minutes } = timeDifference;
  return (
    <S.Timer minutes={minutes} hours={hours}>
      {hours > 20 ? <p>{`20h+`}</p> : <p>{`${hours}h ${minutes}m`}</p>}
    </S.Timer>
  );
};

export default Timer;
