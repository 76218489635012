import { useNavigate } from "react-router-dom";
import { TopNavBar } from "waitherepackages";

export const NavBarPreview = ({ currentBranding = {}, updateBranding }) => {
  const navigate = useNavigate();

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const handleUpdate = (values) => {
    const { bgColor, buttonColor, buttonTxtColor, font, textColor } = values;
    const editables = {
      navbar: JSON.stringify({
        bgColor,
        buttonColor,
        buttonTxtColor,
        font,
        textColor,
      }),
    };
    updateBranding("navbar", editables);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
  };

  return (
    <div>
      <TopNavBar
        handleSave={handleUpdate}
        isDefaultMoblie={false}
        showDashBoard={true}
        currentValues={currentBranding}
        actions={actions}
      />
    </div>
  );
};
