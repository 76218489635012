import styled from "styled-components";
import styles from "../../Assets/styles";
import { Col, Row } from "react-bootstrap";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e5e6ed;
  margin-top: 70px;
`;

export const ContHolder = styled.div`
  padding-top: 10px;
  width: 1000px;
  height: 550px;
  max-height: 80vh;
  max-width: 80vw;
  border-radius: 15px;
  background-color: ${styles.NeutralsWhite};
  box-shadow: 0px 5px 15px rgba(79, 79, 79, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${1000}px) {
    width: 90%;
    height: 90%;
    max-height: 90vh;
    max-width: 90vw;
  }
`;

export const RowHolder = styled(Row)`
  /* height: 100%; */
  width: 100%;
  height: fill-available;
  overflow-y: scroll;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 10px;
  @media (max-width: ${1000}px) {
    gap: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ColHolder = styled(Col)`
  /* height: 100%; */
  gap: 10px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
`;

export const MidContainer = styled.div`
  height: 80vh;
  max-height: 600px;
  min-width: 1000px;
  max-width: 1000px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background-color: ${styles.NeutralsWhite};
  border-bottom-right-radius: 50px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${1000}px) {
    min-width: 80%;
    max-height: 100vh;
    overflow: hidden;
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
`;

export const leftSection = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;

  @media (max-width: ${1000}px) {
    width: 100%;
    height: 100%;
    justify-self: center;
    align-items: center;
    div {
      width: 100%;
    }

    p {
      align-self: start;
    }
  }
`;

export const Company = styled.div`
  align-self: center;
  font-weight: 700;
  img {
    width: 200px;
  }
  @media (max-width: ${1000}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
  margin-bottom: 50px;
`;

export const BoldText = styled.p`
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 0;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 13px;
  margin: 0;
  padding: 0;
`;

export const LoadHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.p`
  font-weight: 700;
  font-size: 20px;
  align-self: flex-start;
  margin-left: 40px;
  /* margin: 0;
  padding: 0; */
  @media (max-width: ${1000}px) {
    margin-left: 10px;
  }
`;

export const SectionName = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  padding: 0;
`;

export const BadgeButtonHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const LoginText = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  margin-left: 30px;
  margin-top: 10px;
  padding: 0;
  span {
    text-decoration: underline;
    text-decoration-color: ${styles.Black};
    cursor: pointer;
    font-weight: 700;
  }

  @media (max-width: ${1000}px) {
    align-self: center !important;
  }
`;

export const rightSection = styled.div`
  width: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  overflow: hidden;
  @media (max-width: ${1000}px) {
    display: none;
  }
`;

export const InputHolder = styled.div`
  gap: 2rem;
  width: 250px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${1000}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

export const Input = styled.input`
  width: 100%;
  min-height: 40px;
  outline: none;
  border-radius: 7px;
  padding: 0px 10px;
  border: 1px solid ${styles.MidGrey};
  background-color: transparent;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const Error = styled.p`
  color: ${styles.Error};
  font-size: 12px;
  margin: 0;
  padding: 0;
`;

export const InputHolder2 = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  outline: none;
  border-radius: 7px;
  padding: 0px 10px;
  border: 1px solid ${styles.MidGrey};
  background-color: transparent;
  display: flex;
  align-items: center;
  overflow: hidden;
  p {
    margin: 0;
    padding: 0;
  }
  input {
    outline: none;
    border: 0;
    background-color: transparent;
    width: fill-available;
    /* padding: 0; */
    /* padding-bottom: 5px; */
  }
`;

export const BottomSec = styled.div`
  width: 100%;
  height: 80px;
  min-height: 80px;
  margin-top: auto;
  display: flex;
  border-top: 1px solid ${styles.MidGrey};
  justify-content: ${(props) =>
    props.center ? "center" : props.end ? "end" : "space-between"};
  align-items: center;
  padding: 0 40px;
  margin-top: auto;
  @media (max-width: ${1000}px) {
    padding: 0 10px;
  }
  /* background-color: green; */
`;

export const FlexHolder = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  div {
    width: 100%;
  }
`;

export const BlackButton = styled.div`
  transition: 0.2s;
  background-color: ${styles.OffBlack};
  border-radius: 25px;
  color: ${styles.NeutralsWhite};
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  width: ${(props) => props.fill && "100%"};
  opacity: ${(props) => (props.hide ? 0 : 1)};

  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${styles.MidGrey};
  }
`;

export const GreyButton = styled(BlackButton)`
  background-color: ${styles.LightGrey};
  color: ${styles.OffBlack};

  :hover {
    color: ${styles.MidGrey};
  }
`;

export const Badge = styled.div`
  transition: 0.3s;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${styles.NeutralsWhite};
  max-width: 100%;
  width: max-content;
  height: 30px;
  padding: 0 12px;
  border-radius: 25px;
  background-color: ${styles.OffBlack};
  cursor: pointer;
  p {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  :hover {
    background-color: ${styles.MidGrey};
    color: ${styles.OffBlack};
  }
`;

export const CenterHolder = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const imageHolder = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: ${styles.LightGrey};

  .empty-image {
    width: 150px;
    height: 150px;
  }
`;

export const EditBtn = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  bottom: 25px;
  /* z-index: 999; */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${styles.NeutralsWhite};
  box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);

  svg {
    cursor: pointer;
    color: ${styles.OffBlack};
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export const ProfileImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
`;
