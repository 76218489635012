/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { controllerContext } from "../../Context/controllerCtx";
import { LoginModal } from "./LoginModal";
import { SignUpModal } from "./SignupModal";
import { MenuModal } from "./MenuModal";
import { CartModal } from "./CartModal";
import { PrinterRoomModal } from "./PrinterRoomsModal";

export const UniversalModals = () => {
  const [controllerState, setControllerState] = useContext(controllerContext);
  // add success or failed validation

  const {
    modals: { showMenu, showCart, showPrinterRooms },
  } = controllerState;

  return (
    <>
      <LoginModal
        canEdit={false}
        controllerState={controllerState}
        setControllerState={setControllerState}
      />
      <SignUpModal
        canEdit={false}
        controllerState={controllerState}
        setControllerState={setControllerState}
      />
      {showMenu && (
        <MenuModal
          controllerState={controllerState}
          setControllerState={setControllerState}
        />
      )}
      {showCart && (
        <CartModal
          controllerState={controllerState}
          setControllerState={setControllerState}
        />
      )}
      {showPrinterRooms && (
        <PrinterRoomModal
          controllerState={controllerState}
          setControllerState={setControllerState}
        />
      )}
    </>
  );
};
