import { notables } from "../../Assets/images/images";
import { LoadDots } from "../../Components/Loader";
import { TopNav } from "../../Components/TopNav";
import useTable from "../../Hooks/useTable";
import { CreateTableModal } from "./Modals/CreateTablesModal";
import * as S from "./styles";
import { IoAddOutline, IoClose } from "react-icons/io5";
import { Table } from "./table";
import { TableDetailsModal } from "./Modals/TableDetails";
import { useNavigate } from "react-router-dom";
import { StartSessionModal } from "./Modals/StartSessionModal";
import { IoMdQrScanner } from "react-icons/io";
import { TbQrcode, TbSettingsSearch } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
import { QrScannerModal } from "../../Components/QrScanner";
import { CheckBox } from "../../Components/Inputs";
import { Collapse } from "react-bootstrap";
import { useState } from "react";
import useOutsideClick from "../../Hooks/UseOutsideClick";
import { useRef } from "react";

export const Tables = () => {
  const navigate = useNavigate();

  const tableProps = useTable({
    navigate,
    indexTables: true,
  });

  const {
    table,
    tables,
    filters,
    loading,
    tableModals,
    handleModals,
    handleFilters,
    handleHideTable,
    handleShowTable,
    handleBulkCreate,
    handleShowScanner,
    handleStartSession,
    handleInputFilters,
  } = tableProps;

  return (
    <S.Container>
      <TopNav />

      <S.Holder>
        {loading.getTables && <LoadDots />}
        {!loading.getTables && (
          <>
            {!tables.length && <NoTables handleModals={handleModals} />}
            {tables.length > 0 && (
              <>
                <TableTopSection
                  filters={filters}
                  handleFilters={handleFilters}
                  handleShowScanner={handleShowScanner}
                  handleInputFilters={handleInputFilters}
                />
                <TablesList {...tableProps} />
              </>
            )}
          </>
        )}
      </S.Holder>

      <CreateTableModal
        loading={loading}
        show={tableModals.createTables}
        handleBulkCreate={handleBulkCreate}
        onhide={() => handleModals("createTables", false)}
      />

      <TableDetailsModal
        {...tableProps}
        show={tableModals.table}
        onhide={() => handleHideTable()}
      />

      <StartSessionModal
        table={table}
        loading={loading}
        handleStartSession={handleStartSession}
        show={tableModals.startSession}
        onhide={() => {
          handleModals("startSession", false);
          handleShowTable(table);
        }}
      />

      <QrScannerModal
        show={tableModals.showScanner}
        onHide={() => handleModals("showScanner", false)}
      />
    </S.Container>
  );
};

const NoTables = ({ handleModals }) => {
  return (
    <>
      <S.BannerImage src={notables} />
      <br />
      <S.BoldText>Você não tem nenhuma mesa.</S.BoldText>
      <br />
      <S.BlackButton onClick={() => handleModals("createTables", true)}>
        Clique para criar
      </S.BlackButton>
    </>
  );
};

const TablesList = ({ filteredTables, handleModals, ...tableProps }) => {
  console.log(filteredTables);
  return (
    <S.TablesHolder>
      <div className="containers">
        {filteredTables.map((table) => (
          <div key={table.id}>
            <Table {...tableProps} table={table} />
          </div>
        ))}

        <S.AddTable onClick={() => handleModals("createTables", true)}>
          <IoAddOutline />
        </S.AddTable>
      </div>
    </S.TablesHolder>
  );
};

const TableTopSection = ({
  handleShowScanner,
  handleInputFilters,
  handleFilters,
  filters,
}) => {
  const ref = useRef();
  const [expandFilter, setExpandFilter] = useState(false);
  useOutsideClick(ref, () => setExpandFilter(false));
  const allFilter = [
    { name: "Disponivel", key: "AVAILABLE" },
    { name: "Occupado", key: "UNAVAILABLE" },
    { name: "Pendente", key: "PENDING" },
    { name: "Reservada", key: "RESERVED" },
  ];
  return (
    <S.TableTopSection>
      <div className="first_section">
        <div className="scanner_toggle" onClick={handleShowScanner}>
          <IoMdQrScanner className="scanner_wrap" />
          <TbQrcode className="scanner_icon" />
        </div>

        <div className="search_holder">
          <FiSearch />
          <input
            value={filters.search}
            placeholder="Numero de mesa .."
            onChange={({ target: { value } }) =>
              handleInputFilters("search", value)
            }
            type="number"
          />
          <IoClose
            className="search_icon"
            onClick={() => handleInputFilters("search", "")}
          />
        </div>

        <div className="filters" ref={ref}>
          <div
            className="filter_holder"
            onClick={() => setExpandFilter(!expandFilter)}
          >
            <TbSettingsSearch />
            <p>Filtro</p>
          </div>
          <S.FiltersHolder>
            <Collapse in={expandFilter}>
              <div>
                <div className="filters_header">
                  <p>Status</p>
                  <span onClick={() => handleFilters("clear")}>Limpar</span>
                </div>
                {allFilter.map(({ name, key }) => (
                  <div
                    key={name}
                    className="check_holder"
                    onClick={() => handleFilters(key)}
                  >
                    <CheckBox checked={filters?.selected?.includes(key)} />
                    <p>{name}</p>
                    <S.FilterIndicator name={name} />
                  </div>
                ))}
                <div className="filters_header">
                  <p>Capacidade</p>
                  <span onClick={() => handleInputFilters("capacity", "")}>
                    Limpar
                  </span>
                </div>
                <input
                  type="number"
                  placeholder="Capacidade de mesa .."
                  className="table_capacity"
                  value={filters.capacity}
                  onChange={({ target: { value } }) =>
                    handleInputFilters("capacity", value)
                  }
                />
              </div>
            </Collapse>
          </S.FiltersHolder>
        </div>
      </div>
    </S.TableTopSection>
  );
};
