/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { getOrgsUnderCategory } from "../../Api/CategoryApi";
import { guteslogo, saintlogo } from "../../Assets/images/images";
import { IconInput } from "../../Components/Inputs";
import { LoadDots } from "../../Components/Loader";
import { TopNav } from "../../Components/TopNav";
import { getImage } from "../../Helpers/getImage";
import { promos } from "./mockData";
import * as S from "./styles";

export const Category = () => {
  const { category } = useParams();
  const [categoryState, setCategoryState] = useState({
    orgs: [],
    category: {},
  });
  const [pageState, setPageState] = useState({});

  const getOrgs = async () => {
    setPageState({ ...pageState, isLoading: true });
    const orgs = await getOrgsUnderCategory(category);
    setCategoryState({
      ...categoryState,
      orgs: orgs.data.categoryOrgs.orgs,
      category: orgs.data.categoryOrgs.category,
    });
    setPageState({ ...pageState, isLoading: false });
  };

  useEffect(() => {
    getOrgs();
  }, []);
  return (
    <>
      <TopNav />
      {pageState.isLoading && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!pageState.isLoading && (
        <>
          <S.Holder>
            <S.PromosHolder>
              {promos.map((promo, i) => (
                <S.ImageHolder key={i} image={getImage(8).src}>
                  <span>Get special discount</span>
                  <p>Up to 85%</p>
                  <S.ClaimVoucher>Claim Voucher</S.ClaimVoucher>
                </S.ImageHolder>
              ))}
            </S.PromosHolder>

            <S.SearchBarHolder>
              <IconInput
                placeHolder={"Search By Restaurant or meal"}
                leftIcon={<FiSearch />}
              />
            </S.SearchBarHolder>

            <S.CategoryName>
              Top {categoryState.category.category} Places
            </S.CategoryName>

            <S.OrgHolder>
              {categoryState.orgs?.map((org) => (
                <S.Organizations key={org.id}>
                  <img
                    src={org.name.includes("utes") ? guteslogo : saintlogo}
                    alt={"logo"}
                  />
                  <div>
                    <p>{org.name}</p>
                    <span>{org.location}</span>
                  </div>
                </S.Organizations>
              ))}
            </S.OrgHolder>
          </S.Holder>
        </>
      )}
    </>
  );
};
